import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Link,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  FindSitesByCompanyId,
  GetAddressByLocation,
  GetStampsByUserIdAndTargetDate,
} from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import './stampHistory.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ja';
import {
  ExtractTimeFromDatetimeString,
  FormatAddressFromGeocordingAPI,
  TimeToUTC,
} from '../../../Util/function';
import { STAMP_TYPE } from '../../../Util/define';

function StampHistory() {
  // 月指定
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [dates, setDates] = useState([]);
  const [stamps, setStamps] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [address, setAddress] = useState('');

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const getAddress = (latitude, longitude) => {
    GetAddressByLocation(
      latitude,
      longitude,
      (res) => {
        setAddress(FormatAddressFromGeocordingAPI(res));
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 日報用紙の取得
  useEffect(() => {
    if (_loginInfo.loginUserName !== '') {
      GetStampsByUserIdAndTargetDate(
        _loginInfo.loginUserName,
        TimeToUTC(new Date(selectedMonth.year(), selectedMonth.month(), 1)),
        TimeToUTC(new Date(selectedMonth.year(), selectedMonth.month() + 1, 1)),
        (res) => {
          setStamps(res.data);
        },
        (e) => {
          console.warn(e);
        }
      );
    }
  }, [selectedMonth]);

  // datesの初期化
  useEffect(() => {
    if (selectedMonth) {
      const year = selectedMonth.year();
      const month = selectedMonth.month();
      const lastDay = selectedMonth.daysInMonth();
      const newDates = [];

      for (let day = 1; day <= lastDay; day++) {
        newDates.push(new Date(year, month, day));
      }

      setDates(newDates);
    } else {
      setDates([]);
    }
  }, [selectedMonth]);

  // 指定した日付の日報が存在すればそれを返す
  const searchStampsByDate = (date) => {
    const getDayFromISOString = (isoString) => {
      if (typeof isoString !== 'string') {
        throw new TypeError('Input should be a string');
      }

      // 年月日の部分だけを抽出
      const datePart = isoString.split('T')[0];
      // 日の部分だけを抽出
      const day = datePart.split('-')[2];

      return Number(day);
    };
    if (stamps.length > 0) {
      return stamps.filter((s) => getDayFromISOString(s.stampDate) === date);
    } else {
      return [];
    }
  };

  // tableRowを返す関数
  const row = (date, i) => {
    const handleClick = (event, latitude, longitude) => {
      setAnchorEl(event.currentTarget);
      if (!latitude || !longitude) {
        setAddress('位置情報が登録されていません');
      } else {
        getAddress(latitude, longitude);
      }
    };

    const handleClose = () => {
      setAddress('');
      setAnchorEl(null);
    };
    const s = searchStampsByDate(date.getDate());
    if (s.length > 0) {
      const clockInStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.CLOCK_IN
      );
      const clockOutStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.CLOCK_OUT
      );
      const breakStartStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.BREAK_START
      );
      const breakEndStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.BREAK_END
      );
      const workStartStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.WORK_START
      );
      const workEndStamp = s.filter(
        (ss) => ss.stampType === STAMP_TYPE.WORK_END
      );
      return (
        <TableRow
          sx={{
            backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
          }}
        >
          <Popover
            id="popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            slotProps={{
              paper: {
                sx: {
                  overflowY: 'hidden',
                },
              },
            }}
          >
            {address !== '' ? (
              <Typography sx={{ p: 1 }}>{address}</Typography>
            ) : (
              <CircularProgress size={20} sx={{ p: 1 }} />
            )}
          </Popover>
          <TableCell>{date.getMonth() + 1 + '/' + date.getDate()}</TableCell>
          <TableCell align="center">
            {clockInStamp.length > 0
              ? clockInStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="center">
            {workStartStamp.length > 0
              ? workStartStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="center">
            {breakStartStamp.length > 0
              ? breakStartStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="center">
            {breakEndStamp.length > 0
              ? breakEndStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="center">
            {workEndStamp.length > 0
              ? workEndStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="center">
            {clockOutStamp.length > 0
              ? clockOutStamp.map((cs) => (
                  <Link
                    onClick={(e) => handleClick(e, cs.latitude, cs.longitude)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {ExtractTimeFromDatetimeString(cs.stampDate)}
                  </Link>
                ))
              : '--:--'}
          </TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow
          sx={{
            backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
          }}
        >
          <TableCell>{date.getMonth() + 1 + '/' + date.getDate()}</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="center">--:--</TableCell>
          <TableCell align="left"></TableCell>
        </TableRow>
      );
    }
  };

  return (
    <div className="pcStampHistory">
      <Typography>打刻履歴</Typography>
      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'flex-end',
          width: '700px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker
            slotProps={{
              calendarHeader: {
                format: 'YYYY年MM月', // カレンダーの年月の部分
              },
            }}
            format="YYYY/MM" // 入力欄
            label="表示月"
            views={['year', 'month']}
            openTo="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(dayjs(e))}
          />
        </LocalizationProvider>
      </div>
      <TableContainer
        sx={{
          maxWidth: '1000px',
          minWidth: '1000px',
          marginTop: '10px',
          marginBottom: '30px',
        }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '100px', color: '#eceff1' }}
              >
                日付
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                出勤
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                休憩
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                戻り
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                退勤
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                作業開始
              </TableCell>
              <TableCell align="left" sx={{ width: '60px', color: '#eceff1' }}>
                作業終了
              </TableCell>
              <TableCell align="left" sx={{ color: '#eceff1' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.length > 0 ? dates.map((date, i) => row(date, i)) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default StampHistory;

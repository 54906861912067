import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function ComboBox(props) {
  if (props.isEdit) {
    return (
      <FormControl
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <InputLabel id="demo-simple-select-label">
          {props.component.isShowLabel ? props.component.text : ''}
        </InputLabel>
        <Select
          value={props.value}
          onChange={(e) =>
            props.setValue(props.component.componentId, e.target.value)
          }
          labelId="demo-simple-select-label"
          label={props.component.isShowLabel ? props.component.text : ''}
          style={{
            width: '100%',
            height: '100%',
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
            backgroundColor: 'white',
          }}
        >
          {props.component.choices.map((choice) => (
            <MenuItem value={choice.choiceId}>{choice.choiceValue}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <InputLabel id="demo-simple-select-label">
          {props.component.isShowLabel ? props.component.text : ''}
        </InputLabel>
        <Select
          defaultValue={props.component.defaultValue}
          labelId="demo-simple-select-label"
          label={props.component.isShowLabel ? props.component.text : ''}
          style={{
            width: '100%',
            height: '100%',
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
            backgroundColor: 'white',
          }}
        >
          {props.component.choices.map((choice) => (
            <MenuItem value={choice.choiceId}>{choice.choiceValue}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export { ComboBox };

import './dailyReportDesign.css';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { STEP } from '../../../Util/define';
import { loginInfo, toastState } from '../../../Util/recoil/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  DeleteDailyReportByDailyReportId,
  DeleteDailyReportsByDailyReportIds,
  GetAllDailyReportByCompanyId,
} from '../../../Util/web_api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormatDateFromStr } from '../../../Util/function';
import ControlDialog from '../../../Util/commonComponent/controlDialog';

function SelectOption(props) {
  const [dailyReports, setDailyReports] = useState([]);

  const [deleteDailyReports, setDeleteDailyReports] = useState([]);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);
  const _setToastState = useSetRecoilState(toastState);

  useEffect(() => {
    getAllDailyReport();
  }, []);

  // 日報を取得
  const getAllDailyReport = () => {
    GetAllDailyReportByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setDailyReports(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 日報を削除
  const deleteDailyReport = () => {
    setIsOpenDeleteDialog(false);
    if (dailyReports.length === 0) {
      return;
    }
    if (deleteDailyReports.length === 1) {
      DeleteDailyReportByDailyReportId(
        deleteDailyReports[0].dailyReportId,
        (res) => {
          setDeleteDailyReports([]);
          _setToastState({ isOpen: true, context: res.data });
          getAllDailyReport();
        },
        (e) => {
          setDeleteDailyReports([]);
          _setToastState({ isOpen: true, context: e });
        }
      );
    } else {
      let dailyReportIds = deleteDailyReports.map((item) => item.dailyReportId);
      const dailyReportIdsObject = {
        dailyReportIds: dailyReportIds,
      };
      const qs = require('qs');
      const queryString = qs.stringify(dailyReportIdsObject, {
        arrayFormat: 'repeat',
      });
      DeleteDailyReportsByDailyReportIds(
        queryString,
        (res) => {
          setDeleteDailyReports([]);
          _setToastState({ isOpen: true, context: res.data });
          getAllDailyReport();
        },
        (e) => {
          setDeleteDailyReports([]);
          _setToastState({ isOpen: true, context: e });
        }
      );
    }
  };

  const create = () => {
    props.setIsEdit(false);
    props.setDailyReportId('');
    props.setEditDailyReport([]);
    props.setDesignStep(STEP.TWO);
  };

  const edit = (dailyReport) => {
    props.setIsEdit(true);
    props.setDailyReportId(dailyReport.dailyReportId);
    props.setEditDailyReport(dailyReport);
    props.setDesignStep(STEP.TWO);
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      setDeleteDailyReports(dailyReports);
    } else {
      setDeleteDailyReports([]);
    }
  };

  const handleCheck = (checked, dailyReport) => {
    if (checked) {
      setDeleteDailyReports((prev) => [...prev, dailyReport]);
    } else {
      const items = deleteDailyReports.filter(
        (dri) => dri.dailyReportId !== dailyReport.dailyReportId
      );
      setDeleteDailyReports(items);
    }
  };

  const dailyReportIsChecked = (dailyReportId) => {
    return deleteDailyReports.some(
      (dr) => dr['dailyReportId'] === dailyReportId
    );
  };

  return (
    <div>
      {dailyReports.length === 0 && (
        <div>作成した日報がありません。新規作成を行ってください。</div>
      )}
      <Button disabled={deleteDailyReports.length !== 0} onClick={create}>
        新規作成する
      </Button>
      <Button
        variant="contained"
        disabled={deleteDailyReports.length === 0 || isOpenDeleteDialog}
        color="error"
        onClick={deleteDailyReport}
      >
        一括削除
      </Button>
      {dailyReports.length !== 0 && (
        <TableContainer
          sx={{ maxWidth: '700px', marginTop: '10px' }}
          component={Paper}
        >
          <Table size="small" aria-label="customized table">
            <TableHead sx={{ backgroundColor: '#263238' }}>
              <TableRow>
                <TableCell
                  padding="nomal"
                  sx={{ width: '30px', color: '#eceff1' }}
                >
                  <Checkbox
                    sx={{
                      color: '#eceff1',
                      '&.Mui-checked': {
                        color: '#eceff1',
                      },
                    }}
                    onChange={() =>
                      handleAllCheck(!(dailyReports === deleteDailyReports))
                    }
                    checked={dailyReports === deleteDailyReports}
                  />
                </TableCell>
                <TableCell
                  padding="nomal"
                  sx={{ width: '300px', color: '#eceff1' }}
                >
                  工事名
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ width: '150px', color: '#eceff1' }}
                >
                  作成日時
                </TableCell>
                <TableCell align="left" sx={{ color: '#eceff1' }}>
                  作成者
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: '#eceff1', width: '100px' }}
                ></TableCell>
              </TableRow>
            </TableHead>
            {dailyReports.map((dailyReport, i) => {
              const isChecked = dailyReportIsChecked(dailyReport.dailyReportId);
              return (
                <TableBody key={dailyReport.dailyReportId}>
                  <TableRow
                    sx={{
                      backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                    }}
                  >
                    <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                      <Checkbox
                        sx={{
                          color: '#263238',
                          '&.Mui-checked': {
                            color: '#263238',
                          },
                        }}
                        onChange={() => handleCheck(!isChecked, dailyReport)}
                        checked={isChecked}
                      />
                    </TableCell>
                    <TableCell>{dailyReport.projectName}</TableCell>
                    <TableCell align="left">
                      {FormatDateFromStr(dailyReport.createDate)}
                    </TableCell>
                    <TableCell align="left">{dailyReport.createrId}</TableCell>
                    <TableCell align="left">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="編集">
                          <IconButton
                            disabled={deleteDailyReports.length !== 0}
                            onClick={() => edit(dailyReport)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="削除">
                          <IconButton
                            onClick={() => {
                              setDeleteDailyReports([dailyReport]);
                              setIsOpenDeleteDialog(true);
                            }}
                            sx={{ color: '#f44336' }}
                            disabled={deleteDailyReports.length !== 0}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      )}
      <ControlDialog
        isOpen={isOpenDeleteDialog}
        doYes={deleteDailyReport}
        doNo={() => {
          setIsOpenDeleteDialog(false);
          setDeleteDailyReports([]);
        }}
        yesText={'削除する'}
        noText={'キャンセル'}
        title={'日報削除'}
        content={
          deleteDailyReports.length === 0
            ? ''
            : deleteDailyReports.length === 1
            ? `「${deleteDailyReports[0].projectName}」を削除しますか？`
            : '選択した日報を一括削除しますか？'
        }
      />
    </div>
  );
}

export default SelectOption;

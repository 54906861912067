import React, { useEffect, useState } from 'react';
import {
  Card,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ControlDialog from './controlDialog';

function ObjectEditDialog(props) {
  const { allObject, object, setObject, isOpen, setIsOpen, setIsApplyChange } =
    props;

  const [candidate, setCandidate] = useState([]);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setCandidate(
        allObject.filter(
          (a) =>
            !team.some((o) => o.userName === a.userName) &&
            a.affilation === null
        )
      );
      setTeam(object);
    }
  }, [isOpen, object]);

  const handleAdd = (object) => {
    setCandidate((candidates) => {
      return candidates.filter((c) => c.userName !== object.userName);
    });
    setTeam((objects) => {
      // 新規
      return [...objects, object];
    });
  };

  const displayName = (userName) => {
    const temp = allObject.find((emp) => emp.userName === userName);
    if (temp === null || temp === undefined) {
      return '不明なユーザー';
    } else {
      return temp.lastName + ' ' + temp.firstName;
    }
  };

  const content = () => {
    return (
      <div style={{ display: 'flex', height: '400px' }}>
        <Paper
          sx={{
            width: '300px',
            borderRadius: '4px',
            marginLeft: '10px',
            flexGrow: 1,
          }}
          elevation={3} // 影のレベルを指定
        >
          <ListSubheader
            sx={{
              backgroundColor: '#263238',
              color: '#eceff1',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              display: 'flex',
            }}
          >
            社員
          </ListSubheader>
          <List sx={{ overflowY: 'auto', height: '333px' }}>
            {candidate.length !== 0
              ? candidate.map((c) => (
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleAdd(c)}
                      >
                        <AddIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={displayName(c.userName)} />
                  </ListItem>
                ))
              : null}
          </List>
        </Paper>
        <Card
          variant="outlined"
          style={{
            width: '300px',
            borderRadius: '4px',
            marginLeft: '20px',
            flexGrow: 1,
            padding: '15px',
          }}
        >
          <Typography>所属社員</Typography>
          <div style={{ marginTop: '10px' }}>
            {team.length !== 0 ? (
              team.map((c) => (
                <Chip
                  style={{ marginRight: '3px', marginBottom: '5px' }}
                  label={displayName(c.userName)}
                  onDelete={() => {
                    setCandidate((candidates) => {
                      return [...candidates, c];
                    });
                    setTeam((obj) => {
                      return obj.filter((o) => o.userName !== c.userName);
                    });
                  }}
                />
              ))
            ) : (
              <Typography>所属社員がいません</Typography>
            )}
          </div>
        </Card>
      </div>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpen}
      doYes={() => {
        setIsOpen(false);
        setObject(team);
        setIsApplyChange(true);
      }}
      doNo={() => {
        setIsOpen(false);
      }}
      yesText={'追加'}
      noText={'キャンセル'}
      title={'所属社員編集'}
      content={content()}
    />
  );
}
export default ObjectEditDialog;

import './dailyReportDesign.css';
import React, { useEffect, useState } from 'react';
import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { STEP } from '../../../Util/define';
import SelectOption from './selectOption';
import FormatSelect from './formatSelect';
import Design from './design';

function DailyReportDesign() {
  const [designStep, setDesignStep] = useState(STEP.ONE);
  const [dailyReportId, setDailyReportId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editDailyReport, setEditDailyReport] = useState([]);

  const viewComponent = () => {
    switch (designStep) {
      case STEP.ONE:
        return (
          <SelectOption
            setDesignStep={setDesignStep}
            setDailyReportId={setDailyReportId}
            setIsEdit={setIsEdit}
            setEditDailyReport={setEditDailyReport}
          />
        );
      case STEP.TWO:
        return (
          <FormatSelect
            isEdit={isEdit}
            dailyReport={editDailyReport}
            dailyReportId={dailyReportId}
            setDesignStep={setDesignStep}
            setDailyReportId={setDailyReportId}
          />
        );
      case STEP.THREE:
      case STEP.FOUR:
      case STEP.FIVE:
        return (
          <Design
            isEdit={isEdit}
            designStep={designStep}
            setDesignStep={setDesignStep}
            dailyReportId={dailyReportId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="pcDailyReportDesign">
      <Typography>日報設計</Typography>
      <Stepper
        activeStep={designStep}
        style={{ paddingTop: '10px', paddingBottom: '20px' }}
      >
        <Step key={STEP.ONE}>
          <StepLabel>{'作成区分選択'}</StepLabel>
        </Step>
        <Step key={STEP.TWO}>
          <StepLabel>{'フォーマット指定'}</StepLabel>
        </Step>
        <Step key={STEP.THREE}>
          <StepLabel>{'設計'}</StepLabel>
        </Step>
        <Step key={STEP.FOUR}>
          <StepLabel>{'確認'}</StepLabel>
        </Step>
        <Step key={STEP.FIVE}>
          <StepLabel>{'完成'}</StepLabel>
        </Step>
      </Stepper>
      {viewComponent()}
    </div>
  );
}

export default DailyReportDesign;

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  GetAllDailyReportComponentByDailyReportId,
  SubmitDailyReport,
} from '../../../Util/web_api';
import { CONTROL, STEP } from '../../../Util/define';
import { TypographyControl } from '../../../Util/dailyReportComponent/PC/typography';
import { Icon } from '../../../Util/dailyReportComponent/PC/icon';
import { Image } from '../../../Util/dailyReportComponent/PC/image';
import { CheckBox } from '../../../Util/dailyReportComponent/PC/checkBox';
import { ComboBox } from '../../../Util/dailyReportComponent/PC/comboBox';
import { RadioControl } from '../../../Util/dailyReportComponent/PC/radio';
import { TextFieldText } from '../../../Util/dailyReportComponent/PC/textFieldText';
import { TextFieldNum } from '../../../Util/dailyReportComponent/PC/textFieldNum';
import { Calender } from '../../../Util/dailyReportComponent/PC/calender';
import { Weather } from '../../../Util/dailyReportComponent/PC/weather';
import { Photo } from '../../../Util/dailyReportComponent/PC/photo';
import { Table } from '../../../Util/dailyReportComponent/PC/table';
import dayjs from 'dayjs';
import { TotalValueControl } from '../../../Util/dailyReportComponent/PC/totalValue';
import { MonthlyTextFieldNum } from '../../../Util/dailyReportComponent/PC/inputGroup/monthly/monthlyTextFieldNum';
import { Time } from '../../../Util/dailyReportComponent/PC/time';
import { FixedTextField } from '../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTextField';
import { FixedTextFieldNum } from '../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTextFieldNum';
import { FixedTime } from '../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTime';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import { TimeToUTC } from '../../../Util/function';

function EditDailyReport(props) {
  const { site, date, setCreateStep } = props;

  const [dailyReportComponent, setDailyReportComponent] = useState([]);

  // 拡大率
  const [scalingFactor, setScalingFactor] = useState(1);

  // 表示モード
  const [isPcView, setIsPcView] = useState(true);

  // 日報の値
  const [dailyReportValues, setDailyReportValues] = useState([]);

  // 合計値
  const [totalValue, setTotalValue] = useState({});

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const componentValue = (id) => {
    return dailyReportValues.find((obj) => obj.id === id).value;
  };

  const setComponentValue = (id, value) => {
    setDailyReportValues((prevArrayOfObjects) =>
      prevArrayOfObjects.map((obj) =>
        obj.id === id ? { ...obj, value: value } : obj
      )
    );
  };

  const submitDailyReport = () => {
    // TimeSpan文字列に変換
    const dayjsToTimeSpan = (time) => {
      if (time === null) {
        return;
      }
      // hours, minutes, secondsを取得
      const hours = time.hour();
      const minutes = time.minute();
      const seconds = time.second();

      // TimeSpan形式にフォーマット (hh:mm:ss)
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:${String(seconds).padStart(2, '0')}`;
    };

    // 二次元配列を分解して処理を適応させる
    const process2DArray = (array) => {
      return array.map((innerArray) =>
        innerArray.map((time) => dayjsToTimeSpan(time))
      );
    };

    // dayjs形式をISO形式に変換する
    const setupValues = () => {
      return dailyReportValues.map((valueSet) =>
        valueSet.componentType === CONTROL.CALENDER
          ? {
              ComponentType: valueSet.componentType,
              ComponentId: valueSet.id,
              Value: valueSet.value.format('YYYY-MM-DDTHH:mm:ssZ'),
            }
          : valueSet.componentType === CONTROL.TIME
          ? {
              ComponentType: valueSet.componentType,
              ComponentId: valueSet.id,
              Value: dayjsToTimeSpan(valueSet.value),
            }
          : valueSet.componentType === CONTROL.INPUT_GROUP.TIME.FIXED
          ? {
              ComponentType: valueSet.componentType,
              ComponentId: valueSet.id,
              Value: process2DArray(valueSet.value),
            }
          : {
              ComponentType: valueSet.componentType,
              ComponentId: valueSet.id,
              Value: valueSet.value,
            }
      );
    };

    const Poster = {
      DailyReportId: site.dailyReport.dailyReportId,
      TargetDate: date,
      SubmitDate: TimeToUTC(new Date()),
      SubmitterId: _loginInfo.loginUserName,
      SiteId: site.siteId,
      Values: setupValues(),
    };

    SubmitDailyReport(
      Poster,
      (res) => {
        console.log(res);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 引数から二次元配列を作成する
  const create2DArray = (xCount, yCount) => {
    const array2D = Array.from({ length: yCount }, () =>
      Array(xCount).fill(null)
    );
    return array2D;
  };

  const calcTotalValue = (component) => {
    // 計算対象を取得する
    const calcTargets = component.calcTargets.map(
      (target) => target.componentId
    );
    // 計算対象の値を取得する
    const targetValues = dailyReportValues
      .filter((valueSet) => calcTargets.includes(valueSet.id))
      .map((valueSet) => valueSet.value);
    return targetValues.reduce((sum, current) => {
      if (current !== null && current !== undefined) {
        return sum + Number(current);
      }
      return sum;
    }, 0);
  };

  // 日報コンポーネントを取得
  useEffect(() => {
    GetAllDailyReportComponentByDailyReportId(
      site.dailyReport.dailyReportId,
      (res) => {
        setDailyReportComponent(res.data);
        let array = [];
        let totalArray = [];
        res.data.forEach((ele) => {
          if (
            ele.componentType === CONTROL.CHECKBOX ||
            ele.componentType === CONTROL.COMBOBOX ||
            ele.componentType === CONTROL.RADIO ||
            ele.componentType === CONTROL.TEXTFIELD.NUM ||
            ele.componentType === CONTROL.TEXTFIELD.TEXT ||
            ele.componentType === CONTROL.CALENDER ||
            ele.componentType === CONTROL.WEATHER ||
            ele.componentType === CONTROL.PHOTO ||
            ele.componentType === CONTROL.TIME ||
            ele.componentType === CONTROL.INPUT_GROUP.TEXTFIELD.FIXED ||
            ele.componentType === CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED ||
            ele.componentType === CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY ||
            ele.componentType === CONTROL.INPUT_GROUP.TIME.FIXED
          ) {
            array.push({
              id: ele.componentId,
              componentType: ele.componentType,
              value:
                ele.componentType === CONTROL.CALENDER
                  ? ele.defaultValue === 'today' && dayjs()
                  : ele.componentType === CONTROL.INPUT_GROUP.TEXTFIELD.FIXED ||
                    ele.componentType ===
                      CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED ||
                    ele.componentType === CONTROL.INPUT_GROUP.TIME.FIXED
                  ? create2DArray(ele.xCount, ele.yCount)
                  : ele.defaultValue,
            });
          }
          if (ele.componentType === CONTROL.TOTAL_VALUE) {
            totalArray.push({
              id: ele.componentId,
              value: 0,
            });
          }
        });
        setDailyReportValues(array);
        setTotalValue(totalArray);
      },
      (e) => {
        console.warn(e);
      }
    );
  }, []);

  const showComponent = (component) => {
    if (isPcView) {
      switch (component.componentType) {
        case CONTROL.TYPOGRAPHY:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <TypographyControl
                component={component}
                scalingFactor={scalingFactor}
              />
            </Box>
          );
        case CONTROL.ICON:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <Icon component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.IMAGE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Image component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.CHECKBOX:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <CheckBox
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.COMBOBOX:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <ComboBox
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.RADIO:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <RadioControl
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.TEXTFIELD.TEXT:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <TextFieldText
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.TEXTFIELD.NUM:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <TextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.CALENDER:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <Calender
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.WEATHER:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <Weather
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.PHOTO:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Photo
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.TIME:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Time
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
              />
            </Box>
          );
        case CONTROL.TABLE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Table component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.TOTAL_VALUE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <TotalValueControl
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={calcTotalValue(component)}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTextField
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <MonthlyTextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TIME.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTime
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                setValue={setComponentValue}
                date={date}
              />
            </Box>
          );
        default:
          break;
      }
    } else {
      switch (component.componentType) {
        case CONTROL.TYPOGRAPHY:
          return (
            <TypographyControl
              component={component}
              scalingFactor={scalingFactor}
            />
          );
        case CONTROL.ICON:
          return <Icon component={component} scalingFactor={scalingFactor} />;
        case CONTROL.IMAGE:
          return <Image component={component} scalingFactor={scalingFactor} />;
        case CONTROL.CHECKBOX:
          return (
            <CheckBox
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.COMBOBOX:
          return (
            <ComboBox
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.RADIO:
          return (
            <RadioControl
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.TEXTFIELD.TEXT:
          return (
            <TextFieldText
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.TEXTFIELD.NUM:
          return (
            <TextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.CALENDER:
          return (
            <Calender
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.WEATHER:
          return (
            <Weather
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.PHOTO:
          return (
            <Photo
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.TIME:
          return (
            <Time
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.TABLE:
          return <Table component={component} scalingFactor={scalingFactor} />;
        case CONTROL.TOTAL_VALUE:
          return (
            <TotalValueControl
              component={component}
              scalingFactor={scalingFactor}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
          return (
            <FixedTextField
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
          return (
            <FixedTextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
          return (
            <MonthlyTextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
              date={date}
            />
          );
        case CONTROL.INPUT_GROUP.TIME.FIXED:
          return (
            <FixedTime
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              setValue={setComponentValue}
            />
          );
        default:
          break;
      }
    }
  };

  return (
    <div style={{ width: 'auto' }}>
      <div
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
          marginLeft: '20px',
          alignItems: 'flex-end',
        }}
      >
        <FormControl sx={{ width: '100px' }}>
          <InputLabel id="demo-simple-select-label">{'拡大率'}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label={'拡大率'}
            value={scalingFactor}
            onChange={(e) => setScalingFactor(e.target.value)}
          >
            <MenuItem value={1}>1.0</MenuItem>
            <MenuItem value={0.75}>0.75</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '100px', marginLeft: '10px' }}>
          <InputLabel id="demo-simple-select-label">{'表示モード'}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label={'表示モード'}
            value={isPcView}
            onChange={(e) => setIsPcView(e.target.value)}
          >
            <MenuItem value={true}>PC</MenuItem>
            <MenuItem value={false}>スマホ</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          sx={{ marginLeft: '30px', marginRight: '10px' }}
          onClick={submitDailyReport}
        >
          作成完了
        </Button>
        <Button variant="contained" sx={{ marginRight: '10px' }}>
          前日コピー
        </Button>
        <Button variant="contained" onClick={() => setCreateStep(STEP.ONE)}>
          戻る
        </Button>
      </div>
      <div style={{ display: 'flex', marginBottom: '50px' }}>
        <Paper
          elevation={3}
          style={{
            marginLeft: '20px',
            padding: !isPcView ? 20 : 10 * scalingFactor + 'mm',
          }}
        >
          {isPcView ? (
            <Box
              style={{
                width: 400 * scalingFactor + 'mm',
                height: 277 * scalingFactor + 'mm',
                border: '1px solid black',
                position: 'relative',
              }}
            >
              {dailyReportComponent.map((component) =>
                showComponent(component)
              )}
            </Box>
          ) : (
            <React.Fragment>
              {dailyReportComponent.map((component) => (
                <Box
                  style={{
                    width:
                      component.componentType === CONTROL.IMAGE
                        ? component.width * (30 * scalingFactor)
                        : 'auto',
                    maxWidth: '400px',
                    height:
                      component.componentType === CONTROL.IMAGE
                        ? component.height * (30 * scalingFactor)
                        : 'auto',
                    marginBottom: '8px',
                  }}
                >
                  {showComponent(component)}
                </Box>
              ))}
            </React.Fragment>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default EditDailyReport;

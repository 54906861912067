import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';

function Image(props) {
  return (
    <img
      draggable={false}
      src={props.component.imagePhoto}
      width={'100%'}
      height={'100%'}
    />
  );
}

export { Image };

import './dailyReportDesign.css';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import RndComponentDialog from './rndControl/rndComponentDialog';
import { RndTypography } from './rndControl/Typography/rndTypography';
import DesignMenu from './designMenu';
import { RndControl } from './rndControl/rndControl';
import { CONTROL, STEP } from '../../../Util/define';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  DeleteDailyReportByDailyReportId,
  GetAllDailyReportComponentByDailyReportId,
  RegistDailyReportComponent,
} from '../../../Util/web_api';
import { useSetRecoilState } from 'recoil';
import { toastState } from '../../../Util/recoil/atom';
import DoneIcon from '@mui/icons-material/Done';

function Design(props) {
  // 拡大率
  const [scalingFactor, setScalingFactor] = useState(1);

  // 枠線の表示非表示
  const [isShowStripe, setIsShowStripe] = useState(true);

  // ダイアログの開閉状態
  const [isOpenRndDialog, setIsOpenRndDialog] = useState(false);

  // コンポーネント
  const [rndComponents, setRndComponents] = useState([]);
  console.log(rndComponents);

  // コンポーネントの編集情報
  const [editComponentId, setEditComponentId] = useState('');
  const [editComponent, setEditComponent] = useState({});
  const [isNew, setIsNew] = useState(false);

  // 確定・取り消しダイアログ
  const [isOpenFinishDialog, setIsOpenFinishDialog] = useState(false);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);

  // プレビューモード
  const [isPreview, setIsPreview] = useState(false);
  const [isMobilePreview, setIsMobilePreview] = useState(false);

  // recoil
  const _setToastState = useSetRecoilState(toastState);

  useEffect(() => {
    if (props.isEdit) {
      GetAllDailyReportComponentByDailyReportId(
        props.dailyReportId,
        (res) => {
          setRndComponents(() => {
            if (res.data) {
              // 編集
              return res.data.map((ele) =>
                ele.componentType === CONTROL.TOTAL_VALUE
                  ? {
                      componentId: ele.componentId,
                      componentType: ele.componentType,
                      text: ele.text,
                      fontSize: ele.fontSize,
                      x: ele.x,
                      y: ele.y,
                      sequence: ele.sequence,
                      calcTargets: ele.calcTargets.map(
                        (target) => target.componentId
                      ),
                    }
                  : ele
              );
            }
          });
        },
        (e) => {
          console.warn(e);
        }
      );
    }
  }, []);

  // 日報を削除
  const deleteDailyReport = () => {
    if (!props.isEdit) {
      DeleteDailyReportByDailyReportId(
        props.dailyReportId,
        (res) => {
          props.setDesignStep(STEP.ONE);
          setIsOpenCancelDialog(false);
        },
        (e) => {
          _setToastState({ isOpen: true, context: e });
          props.setDesignStep(STEP.ONE);
          setIsOpenCancelDialog(false);
        }
      );
    } else {
      props.setDesignStep(STEP.ONE);
      setIsOpenCancelDialog(false);
    }
  };

  const designPaper = () => {
    const onDragEnd = (result) => {
      if (!result.destination) return;

      const reorderedItems = Array.from(rndComponents);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);

      const updatedItems = reorderedItems.map((item, index) => ({
        ...item,
        sequence: index + 1,
      }));

      setRndComponents(updatedItems);
    };
    return (
      <Paper
        elevation={3}
        style={{
          marginLeft: '20px',
          padding: isMobilePreview ? 20 : 10 * scalingFactor + 'mm',
        }}
      >
        {isMobilePreview ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    width: '400px',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    padding: '5px',
                  }}
                >
                  {rndComponents.map((component, index) => {
                    if (!isPreview) {
                      return (
                        <Draggable
                          key={component.componentId}
                          draggableId={component.componentId}
                          index={index}
                        >
                          {(provided) => (
                            <Paper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                ...provided.draggableProps.style,
                                padding: '5px',
                                marginBottom: '5px',
                                position: 'relative',
                              }}
                              variant="outlined"
                              onClick={(e) => {
                                if (e.ctrlKey) {
                                  setIsOpenRndDialog(true);
                                  setEditComponentId(component.componentId);
                                  setEditComponent(component);
                                  setIsNew(false);
                                }
                              }}
                            >
                              <Stack
                                direction="row"
                                sx={{ alignItems: 'center' }}
                              >
                                <DragHandleIcon style={{ color: 'gray' }} />
                                <Box
                                  style={{ width: '100%', marginLeft: '3px' }}
                                >
                                  <RndControl
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    component={component}
                                    setComponentInfos={setRndComponents}
                                    scalingFactor={scalingFactor}
                                    setEditComponentId={setEditComponentId}
                                    setEditComponent={setEditComponent}
                                    setIsOpenRndDialog={setIsOpenRndDialog}
                                    setIsNew={setIsNew}
                                    isPreview={isPreview}
                                    isMobilePreview={isMobilePreview}
                                  />
                                </Box>
                                <Box
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  right={0}
                                  bottom={0}
                                  style={{
                                    backgroundColor: 'transparent',
                                  }}
                                />
                              </Stack>
                            </Paper>
                          )}
                        </Draggable>
                      );
                    } else {
                      return (
                        <RndControl
                          component={component}
                          setComponentInfos={setRndComponents}
                          scalingFactor={scalingFactor}
                          setEditComponentId={setEditComponentId}
                          setEditComponent={setEditComponent}
                          setIsOpenRndDialog={setIsOpenRndDialog}
                          setIsNew={setIsNew}
                          isPreview={isPreview}
                          isMobilePreview={isMobilePreview}
                        />
                      );
                    }
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Box
            className={
              isShowStripe
                ? scalingFactor === 1
                  ? 'designPaperFull'
                  : scalingFactor === 0.5
                  ? 'designPaperHalf'
                  : 'designPaperThreeFourths'
                : ''
            }
            style={{
              width: 400 * scalingFactor + 'mm',
              height: 277 * scalingFactor + 'mm',
              border: '1px solid black',
              position: 'relative',
            }}
          >
            {rndComponents.map((component) => {
              return (
                <RndControl
                  component={component}
                  setComponentInfos={setRndComponents}
                  scalingFactor={scalingFactor}
                  setEditComponentId={setEditComponentId}
                  setEditComponent={setEditComponent}
                  setIsOpenRndDialog={setIsOpenRndDialog}
                  setIsNew={setIsNew}
                  isPreview={isPreview}
                  isMobilePreview={isMobilePreview}
                />
              );
            })}
          </Box>
        )}
      </Paper>
    );
  };

  const finish = () => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const transformKeysToUpperCase = (obj) => {
      const transformedObject = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = capitalizeFirstLetter(key);
          let value = obj[key];

          // 選択肢が存在する場合はそれも変換する
          if (key === 'choices' && Array.isArray(value)) {
            value = value.map(transformKeysToUpperCase);
          }

          transformedObject[newKey] = value;
        }
      }
      return transformedObject;
    };

    const transformArrayOfObjectsKeysToUpperCase = (array) => {
      return array.map(transformKeysToUpperCase);
    };

    const transformedArray =
      transformArrayOfObjectsKeysToUpperCase(rndComponents);

    RegistDailyReportComponent(
      transformedArray,
      props.dailyReportId,
      !props.isEdit,
      (res) => {
        props.setDesignStep(STEP.FIVE);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  if (props.designStep === STEP.THREE) {
    return (
      <div style={{ display: 'flex', margin: '10px' }}>
        <RndComponentDialog
          isOpen={isOpenRndDialog}
          setIsOpenDialog={setIsOpenRndDialog}
          component={editComponent}
          setRndComponents={setRndComponents}
          editComponentId={editComponentId}
          setEditComponentId={setEditComponentId}
          setEditComponent={setEditComponent}
          isNew={isNew}
          componentsLength={rndComponents.length}
          components={rndComponents}
        />
        <DesignMenu
          scalingFactor={scalingFactor}
          setScalingFactor={setScalingFactor}
          setIsOpenRndDialog={setIsOpenRndDialog}
          setEditComponentId={setEditComponentId}
          setIsNew={setIsNew}
          setIsOpenFinishDialog={setIsOpenFinishDialog}
          setIsOpenCancelDialog={setIsOpenCancelDialog}
          isShowStripe={isShowStripe}
          setIsShowStripe={setIsShowStripe}
          isMobilePreview={isMobilePreview}
          setIsMobilePreview={setIsMobilePreview}
        />
        <ControlDialog
          isOpen={isOpenFinishDialog}
          doYes={() => {
            props.setDesignStep(STEP.FOUR);
            setIsPreview(true);
            setIsOpenFinishDialog(false);
          }}
          doNo={() => setIsOpenFinishDialog(false)}
          yesText={'はい'}
          noText={'キャンセル'}
          title={'日報設計'}
          content={'日報設計をプレビューしますか？'}
        />
        <ControlDialog
          isOpen={isOpenCancelDialog}
          doYes={deleteDailyReport}
          doNo={() => setIsOpenCancelDialog(false)}
          yesText={'破棄する'}
          noText={'キャンセル'}
          title={'日報設計'}
          content={'日報設計を破棄しますか？'}
        />
        <div
          style={{
            paddingRight: isMobilePreview ? '0px' : '50px',
            paddingBottom: isMobilePreview ? '0px' : '50px',
            display: isMobilePreview ? 'flex' : 'block',
          }}
        >
          {designPaper()}
        </div>
      </div>
    );
  } else if (props.designStep === STEP.FOUR) {
    return (
      <div style={{ paddingRight: '50px', paddingBottom: '50px' }}>
        <div>
          <Typography style={{ display: 'inline-block' }}>
            作成した日報用紙のプレビュー
          </Typography>
          <FormControl>
            <InputLabel id="demo-simple-select-label">拡大率</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={scalingFactor}
              label="拡大率"
              onChange={(e) => setScalingFactor(e.target.value)}
              style={{ width: '100px' }}
            >
              <MenuItem value={0.5}>0.5</MenuItem>
              <MenuItem value={0.75}>0.75</MenuItem>
              <MenuItem value={1}>1</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label">グリッド線</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isShowStripe}
              label="グリッド線"
              onChange={(e) => setIsShowStripe(e.target.value)}
              style={{ width: '100px' }}
            >
              <MenuItem value={true}>表示</MenuItem>
              <MenuItem value={false}>非表示</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label">表示モード</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isMobilePreview}
              label="表示モード"
              onChange={(e) => setIsMobilePreview(e.target.value)}
              style={{ width: '120px' }}
            >
              <MenuItem value={false}>PC</MenuItem>
              <MenuItem value={true}>モバイル</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              props.setDesignStep(STEP.THREE);
              setIsPreview(false);
            }}
          >
            設計に戻る
          </Button>
          <Button onClick={finish}>設計を完了する</Button>
        </div>
        <div style={{ display: 'flex', margin: '10px' }}>{designPaper()}</div>
      </div>
    );
  } else if (props.designStep === STEP.FIVE) {
    return (
      <div>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <DoneIcon
            sx={{ fontSize: '30px', marginRight: '5px', color: 'green' }}
          />
          {'日報の作成が完了しました。'}
        </Typography>
        <div style={{ display: 'flex', margin: '10px' }}>{designPaper()}</div>
      </div>
    );
  }
}

export default Design;

import React, { useEffect, useRef, useState } from 'react';
import ControlDialog from '../../../../Util/commonComponent/controlDialog';
import {
  Box,
  Button,
  Card,
  Chip,
  FilledInput,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { CONTROL } from '../../../../Util/define';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/SquareRounded';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

function RndComponentDialog(props) {
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState(0);
  const [count, setCount] = useState(1);
  const [iconName, setIconName] = useState('');
  const [image, setImage] = useState('');
  const fileInputRef = useRef(null);
  const [choiceId, setChoiceId] = useState('');
  const [choiceName, setChoiceName] = useState('');
  const [choices, setChoices] = useState([]);
  const [selectChoiceId, setSelectChoiceId] = useState('');
  const [sequence, setSequence] = useState(0);
  const [defaultValue, setDefaultValue] = useState('');
  const [calcTargets, setCalcTargets] = useState([]);
  const [isShowLabel, setIsShowLabel] = useState(true);
  const [xCount, setXCount] = useState(1);
  const [yCount, setYCount] = useState(1);
  const [isMultiline, setIsMultiline] = useState(false);

  useEffect(() => {
    if (props.isNew) {
      setCount(props.componentsLength + 1);
    }
    setText(
      props.isNew || props.component.text === undefined
        ? ''
        : props.component.text
    );
    setFontSize(
      props.isNew || props.component.fontSize === undefined
        ? 16
        : props.component.fontSize
    );
    setIconName(
      props.isNew || props.component.iconName === undefined
        ? ''
        : props.component.iconName
    );
    setImage(
      props.isNew || props.component.image === undefined
        ? ''
        : props.component.image
    );
    setChoiceId('');
    setChoiceName('');
    setChoices(
      props.isNew || props.component.choices === undefined
        ? []
        : props.component.choices
    );
    setSelectChoiceId('');
    setSequence(
      props.isNew || props.component.sequence === undefined
        ? props.componentsLength + 1
        : props.component.sequence
    );
    setDefaultValue(
      props.isNew || props.component.defaultValue === undefined
        ? ''
        : props.component.defaultValue
    );
    setCalcTargets(
      props.isNew || props.component.calcTargets === undefined
        ? []
        : props.component.calcTargets
    );
    setIsShowLabel(
      props.isNew || props.component.isShowLabel === undefined
        ? true
        : props.component.isShowLabel
    );
    setXCount(
      props.isNew || props.component.xCount === undefined
        ? 1
        : props.component.xCount
    );
    setYCount(
      props.isNew || props.component.yCount === undefined
        ? 1
        : props.component.yCount
    );
    setIsMultiline(
      props.isNew || props.component.isMultiline === undefined
        ? false
        : props.component.isMultiline
    );
  }, [props.isOpen]);

  // 任意の数値以下の場合、整合して返す処理を行います
  const alignNum = (baseNum, num) => {
    if (baseNum > num) {
      num = baseNum;
    }
    return num;
  };

  const dialogContent = () => {
    return showDialogContent(
      props.isNew ? props.editComponentId : props.component.componentType
    );
  };

  // 画像を選択させます
  const handleChoiceImage = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage('');
    }
  };

  // 選択肢を追加・編集します
  const handleEditChoices = () => {
    if (choiceId === '' || choiceName === '') {
      return;
    }

    setSelectChoiceId('');
    setChoiceId('');
    setChoiceName('');
    setChoices((choices) => {
      if (selectChoiceId !== '') {
        // 編集
        return choices.map((choice) =>
          choice.choiceId === selectChoiceId
            ? {
                choiceId: choiceId,
                choiceValue: choiceName,
              }
            : choice
        );
      } else {
        // 新規
        return [
          ...choices,
          {
            choiceId: choiceId,
            choiceValue: choiceName,
          },
        ];
      }
    });
  };

  // 計算対象に追加します
  const handleChangeCalcTargets = (e) => {
    const {
      target: { value },
    } = e;
    setCalcTargets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  // 計算対象となりうるコンポーネント一覧を返します
  const calcableComponent = () => {
    return props.components.filter(
      (component) =>
        component.componentType === CONTROL.TEXTFIELD.NUM ||
        component.componentType === CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY
    );
  };

  const showTitleLabel = (type) => {
    let tempLabel = '';
    switch (type) {
      case CONTROL.TYPOGRAPHY:
        tempLabel = '文字';
        break;
      case CONTROL.ICON:
        tempLabel = 'アイコン';
        break;
      case CONTROL.IMAGE:
        tempLabel = '画像';
        break;
      case CONTROL.CHECKBOX:
        tempLabel = 'チェックボックス';
        break;
      case CONTROL.COMBOBOX:
        tempLabel = 'コンボボックス';
        break;
      case CONTROL.RADIO:
        tempLabel = 'ラジオ';
        break;
      case CONTROL.TEXTFIELD.TEXT:
        tempLabel = 'テキストフィールド';
        break;
      case CONTROL.TEXTFIELD.NUM:
        tempLabel = 'テキストフィールド(数字)';
        break;
      case CONTROL.CALENDER:
        tempLabel = 'カレンダー';
        break;
      case CONTROL.WEATHER:
        tempLabel = '天気';
        break;
      case CONTROL.PHOTO:
        tempLabel = '写真';
        break;
      case CONTROL.TIME:
        tempLabel = '時間';
        break;
      case CONTROL.TABLE:
        tempLabel = '表形式';
        break;
      case CONTROL.TOTAL_VALUE:
        tempLabel = '合計値';
        break;
      case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
        tempLabel = '固定長テキストフィールド';
        break;
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
        tempLabel = '固定長テキストフィールド(数字)';
        break;
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
        tempLabel = '月別テキストフィールド(数字)';
        break;
      case CONTROL.INPUT_GROUP.TIME.FIXED:
        tempLabel = '固定長時間';
        break;
      default:
        tempLabel = 'その他';
        break;
    }
    tempLabel += props.isNew ? 'の設定' : 'の変更';
    return tempLabel;
  };

  const showDialogContent = (type) => {
    switch (type) {
      case CONTROL.TYPOGRAPHY:
        return (
          <div>
            <div>
              <TextField
                label="文字"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="表示したい文字を入力してください。"
                variant="filled"
                multiline
                rows={5}
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.ICON:
        return (
          <div>
            <div>
              <TextField
                label="文字"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="表示したい文字があれば入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="アイコンサイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="アイコンの種類"
                value={iconName}
                onChange={(e) => setIconName(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={'circle'}>
                  <ListItemIcon>
                    <CircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>丸</ListItemText>
                </MenuItem>
                <MenuItem value={'square'}>
                  <ListItemIcon>
                    <SquareIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>四角</ListItemText>
                </MenuItem>
              </TextField>
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.IMAGE:
        return (
          <div>
            <div>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={(e) => handleChoiceImage(e)}
              />
              <Button
                onClick={() => fileInputRef.current.click()}
                variant="contained"
              >
                画像を選択する
              </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Typography>画像のプレビュー</Typography>
              {image !== '' ? (
                <img src={image} width={300} />
              ) : (
                <Typography style={{ marginLeft: '5px' }}>
                  画像が選択されていません
                </Typography>
              )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.CHECKBOX:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="初期値"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>ON</MenuItem>
                <MenuItem value={false}>OFF</MenuItem>
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.COMBOBOX:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Card
              variant="outlined"
              style={{ marginTop: '20px', padding: '10px' }}
            >
              <Typography>選択肢の設定</Typography>
              <div style={{ margin: '10px' }}>
                {choices.length !== 0 ? (
                  choices.map((choice) => {
                    return (
                      <Chip
                        color={
                          selectChoiceId === choice.choiceId
                            ? 'primary'
                            : 'default'
                        }
                        label={choice.choiceValue}
                        onClick={() => {
                          if (selectChoiceId === choice.choiceId) {
                            setSelectChoiceId('');
                            setChoiceId('');
                            setChoiceName('');
                          } else {
                            setSelectChoiceId(choice.choiceId);
                            setChoiceId(choice.choiceId);
                            setChoiceName(choice.choiceValue);
                          }
                        }}
                        onDelete={() => {
                          const otherChoices = choices.filter(
                            (ele) => ele.choiceId !== choice.choiceId
                          );
                          setChoices(otherChoices);
                          const initFlag = otherChoices.some(
                            (ele) => ele.choiceId === selectChoiceId
                          );
                          if (!initFlag) {
                            setSelectChoiceId('');
                            setChoiceId('');
                            setChoiceName('');
                          }
                        }}
                        style={{ marginRight: '3px', marginBottom: '5px' }}
                      />
                    );
                  })
                ) : (
                  <Typography style={{ marginLeft: '5px' }}>
                    選択肢が設定されていません。
                  </Typography>
                )}
                <Card
                  variant="outlined"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    marginTop: '10px',
                  }}
                >
                  <TextField
                    label="選択肢のID"
                    value={choiceId}
                    onChange={(e) => setChoiceId(e.target.value)}
                    variant="filled"
                    style={{ width: '120px' }}
                    size="small"
                  />
                  <TextField
                    label="選択肢の名称"
                    value={choiceName}
                    onChange={(e) => setChoiceName(e.target.value)}
                    variant="filled"
                    style={{ width: '230px', marginLeft: '20px' }}
                    size="small"
                  />
                  <Button
                    variant="contained"
                    startIcon={
                      selectChoiceId === '' ? (
                        <AddCircleOutlineOutlinedIcon />
                      ) : (
                        <EditIcon />
                      )
                    }
                    style={{ marginLeft: '10px' }}
                    onClick={handleEditChoices}
                  >
                    {selectChoiceId === '' ? '追加' : '変更'}
                  </Button>
                </Card>
              </div>
            </Card>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="初期値"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                {choices.length !== 0 && (
                  <MenuItem value={''}>{'未選択'}</MenuItem>
                )}
                {choices.length !== 0 ? (
                  choices.map((choice) => {
                    return (
                      <MenuItem value={choice.choiceId}>
                        {choice.choiceValue}
                      </MenuItem>
                    );
                  })
                ) : (
                  <ListSubheader>選択肢を設定してください</ListSubheader>
                )}
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.RADIO:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Card
              variant="outlined"
              style={{ marginTop: '20px', padding: '10px' }}
            >
              <Typography>選択肢の設定</Typography>
              <div style={{ margin: '10px' }}>
                {choices.length !== 0 ? (
                  choices.map((choice) => {
                    return (
                      <Chip
                        color={
                          selectChoiceId === choice.choiceId
                            ? 'primary'
                            : 'default'
                        }
                        label={choice.choiceValue}
                        onClick={() => {
                          if (selectChoiceId === choice.choiceId) {
                            setSelectChoiceId('');
                            setChoiceId('');
                            setChoiceName('');
                          } else {
                            setSelectChoiceId(choice.choiceId);
                            setChoiceId(choice.choiceId);
                            setChoiceName(choice.choiceValue);
                          }
                        }}
                        onDelete={() => {
                          const otherChoices = choices.filter(
                            (ele) => ele.choiceId !== choice.choiceId
                          );
                          setChoices(otherChoices);
                          const initFlag = otherChoices.some(
                            (ele) => ele.choiceId === selectChoiceId
                          );
                          if (!initFlag) {
                            setSelectChoiceId('');
                            setChoiceId('');
                            setChoiceName('');
                          }
                        }}
                        style={{ marginRight: '3px', marginBottom: '5px' }}
                      />
                    );
                  })
                ) : (
                  <Typography style={{ marginLeft: '5px' }}>
                    選択肢が設定されていません。
                  </Typography>
                )}
                <Card
                  variant="outlined"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    marginTop: '10px',
                  }}
                >
                  <TextField
                    label="選択肢のID"
                    value={choiceId}
                    onChange={(e) => setChoiceId(e.target.value)}
                    variant="filled"
                    style={{ width: '120px' }}
                    size="small"
                  />
                  <TextField
                    label="選択肢の名称"
                    value={choiceName}
                    onChange={(e) => setChoiceName(e.target.value)}
                    variant="filled"
                    style={{ width: '230px', marginLeft: '20px' }}
                    size="small"
                  />
                  <Button
                    variant="contained"
                    startIcon={
                      selectChoiceId === '' ? (
                        <AddCircleOutlineOutlinedIcon />
                      ) : (
                        <EditIcon />
                      )
                    }
                    style={{ marginLeft: '10px' }}
                    onClick={handleEditChoices}
                  >
                    {selectChoiceId === '' ? '追加' : '変更'}
                  </Button>
                </Card>
              </div>
            </Card>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="初期値"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                {choices.length !== 0 && (
                  <MenuItem value={''}>{'未選択'}</MenuItem>
                )}
                {choices.length !== 0 ? (
                  choices.map((choice) => {
                    return (
                      <MenuItem value={choice.choiceId}>
                        {choice.choiceValue}
                      </MenuItem>
                    );
                  })
                ) : (
                  <ListSubheader>選択肢を設定してください</ListSubheader>
                )}
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TEXTFIELD.TEXT:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="複数行入力"
                value={isMultiline}
                onChange={(e) => setIsMultiline(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>可</MenuItem>
                <MenuItem value={false}>不可</MenuItem>
              </TextField>
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TEXTFIELD.NUM:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.CALENDER:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="初期値"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={''}>未選択</MenuItem>
                <MenuItem value={'today'}>本日日付</MenuItem>
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.WEATHER:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="名称の表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px', marginRight: '20px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="初期値"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={''}>未選択</MenuItem>
                <MenuItem value={'auto'}>自動選択</MenuItem>
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.PHOTO:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TIME:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TEXTFIELD.TEXT:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TABLE:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.TOTAL_VALUE:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <FormControl variant="filled" sx={{ width: '500px' }}>
                <InputLabel id="demo-multiple-chip-label">合計対象</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={calcTargets}
                  onChange={handleChangeCalcTargets}
                  input={
                    <FilledInput id="select-multiple-chip" label="合計対象" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            props.components.find(
                              (component) => component.componentId === value
                            ).text
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {calcableComponent().length !== 0
                    ? calcableComponent().map((component) => (
                        <MenuItem
                          key={component.componentId}
                          value={component.componentId}
                        >
                          {component.text}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="文字サイズ"
                value={fontSize}
                onChange={(e) => setFontSize(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
      case CONTROL.INPUT_GROUP.TIME.FIXED:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="横個数"
                value={xCount}
                onChange={(e) => setXCount(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="縦個数"
                value={yCount}
                onChange={(e) => setYCount(alignNum(1, e.target.value))}
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
        return (
          <div>
            <div>
              <TextField
                label="名称"
                value={text}
                onChange={(e) => setText(e.target.value)}
                helperText="名称を入力してください。"
                variant="filled"
                style={{ width: '500px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                label="日付表示"
                value={isShowLabel}
                onChange={(e) => setIsShowLabel(e.target.value)}
                variant="filled"
                style={{ width: '200px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                select
              >
                <MenuItem value={true}>有</MenuItem>
                <MenuItem value={false}>無</MenuItem>
              </TextField>
              <TextField
                label="表示順"
                helperText="モバイルモードでの表示順"
                value={sequence}
                onChange={(e) =>
                  setSequence(
                    alignNum(props.component.length + 1, e.target.value)
                  )
                }
                variant="filled"
                style={{ width: '200px', marginLeft: '20px' }}
                type={'number'}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const yes = () => {
    props.setEditComponentId('');
    props.setEditComponent({});
    props.setRndComponents((componentInfo) => {
      if (!props.isNew) {
        // 編集
        return componentInfo.map((ele) =>
          ele.componentId === props.component.componentId
            ? {
                width: props.component.width,
                height: props.component.height,
                componentId: props.component.componentId,
                componentType: props.component.componentType,
                text: text,
                fontSize: Number(fontSize),
                x: props.component.x,
                y: props.component.y,
                iconName: iconName,
                imagePhoto: image,
                choices: choices,
                sequence: sequence,
                defaultValue: defaultValue,
                calcTargets: calcTargets,
                isShowLabel: isShowLabel,
                xCount: Number(xCount),
                yCount: Number(yCount),
                isMultiline: isMultiline,
              }
            : ele
        );
      } else {
        // 新規
        setCount(count + 1);
        return [
          ...componentInfo,
          {
            width:
              props.editComponentId === CONTROL.IMAGE ||
              props.editComponentId === CONTROL.PHOTO ||
              props.editComponentId === CONTROL.TABLE ||
              props.editComponentId === CONTROL.COMBOBOX ||
              props.editComponentId === CONTROL.TEXTFIELD.NUM ||
              props.editComponentId === CONTROL.TEXTFIELD.TEXT ||
              props.editComponentId === CONTROL.CALENDER ||
              props.editComponentId === CONTROL.WEATHER ||
              props.editComponentId === CONTROL.TIME ||
              props.editComponentId ===
                CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY
                ? 2
                : props.editComponentId ===
                    CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED ||
                  props.editComponentId === CONTROL.INPUT_GROUP.TIME.FIXED ||
                  props.editComponentId === CONTROL.INPUT_GROUP.TEXTFIELD.FIXED
                ? xCount * 2
                : 0,
            height:
              props.editComponentId === CONTROL.IMAGE ||
              props.editComponentId === CONTROL.PHOTO ||
              props.editComponentId === CONTROL.TABLE ||
              props.editComponentId === CONTROL.COMBOBOX ||
              props.editComponentId === CONTROL.TEXTFIELD.NUM ||
              props.editComponentId === CONTROL.TEXTFIELD.TEXT ||
              props.editComponentId === CONTROL.CALENDER ||
              props.editComponentId === CONTROL.WEATHER ||
              props.editComponentId === CONTROL.TIME
                ? 2
                : 0,
            componentId: props.editComponentId + count,
            componentType: props.editComponentId,
            text: text,
            fontSize: Number(fontSize),
            x: 0,
            y: 0,
            iconName: iconName,
            imagePhoto: image,
            choices: choices,
            sequence: sequence,
            defaultValue: defaultValue,
            calcTargets: calcTargets,
            isShowLabel: isShowLabel,
            xCount: Number(xCount),
            yCount: Number(yCount),
            isMultiline: isMultiline,
          },
        ];
      }
    });
    props.setIsOpenDialog(false);
  };

  const no = () => {
    props.setEditComponentId('');
    props.setEditComponent({});
    props.setIsOpenDialog(false);
  };

  const doDelete = () => {
    props.setRndComponents((componentInfo) => {
      return componentInfo.filter(
        (ele) => ele.componentId !== props.component.componentId
      );
    });
    props.setIsOpenDialog(false);
  };

  return (
    <ControlDialog
      isOpen={props.isOpen}
      doYes={yes}
      doNo={no}
      doDelete={doDelete}
      yesText={'配置する'}
      noText={'キャンセル'}
      title={showTitleLabel(
        props.isNew ? props.editComponentId : props.component.componentType
      )}
      content={dialogContent()}
      isNew={props.isNew}
    />
  );
}
export default RndComponentDialog;

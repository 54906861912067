import {
  APPLICATION_TYPE,
  APPROVE_KIND,
  APPROVE_MENU,
  NOTIFICATION_KIND,
  SCHEDULE_KIND,
} from './define';

export const FormatDateFromStr = (strDate) => {
  const date = new Date(strDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1します
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

/**
 * 与えられたDateオブジェクトをUTC形式のISO文字列に変換します。
 * @param {Date} date - 変換するDateオブジェクト
 * @returns {string} - UTC形式のISO 8601文字列
 * @throws {TypeError} - 入力がDateオブジェクトでない場合に発生
 */
export const TimeToUTC = (date) => {
  if (!(date instanceof Date)) {
    throw new TypeError('Input should be a Date object');
  }
  // 9時間足す
  const utcDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
  return utcDate.toISOString();
};

export const DisplayApproveKindName = (approveKind) => {
  let approveKindName = '';
  if (approveKind === APPROVE_KIND.ALL) {
    approveKindName = '全員(順不同)';
  } else if (approveKind === APPROVE_KIND.ALL_STEP) {
    approveKindName = '全員(ステップ)';
  } else if (approveKind === APPROVE_KIND.SINGLE) {
    approveKindName = '誰かひとり';
  }
  return approveKindName;
};

export const DisplayApplicationTypeName = (type) => {
  let applicationTypeName = '';
  if (type === APPLICATION_TYPE.LEAVE) {
    applicationTypeName = '有給';
  } else if (type === APPLICATION_TYPE.OVERTIME) {
    applicationTypeName = '残業';
  } else if (type === APPLICATION_TYPE.BUSINESS_TRIP) {
    applicationTypeName = '出張';
  } else if (type === APPLICATION_TYPE.EXPENSE) {
    applicationTypeName = '交通費';
  }
  return applicationTypeName;
};

export const DisplayMessageChip = (notification) => {
  let label = '';
  let color = '';
  switch (notification.kind) {
    case APPROVE_MENU.DAILY_REPORT:
      label = '日報承認';
      color = 'warning';
      break;
    case NOTIFICATION_KIND.SYSTEM_NOTIFICATION:
      label = 'お知らせ';
      color = 'info';
      break;
    default:
      break;
  }
  return { label: label, color: color };
};

export const DisplayScheduleChip = (schedule) => {
  let label = '';
  let color = '';
  switch (schedule.kind) {
    case SCHEDULE_KIND.MEETING:
      label = 'ミーティング';
      color = 'primary';
      break;
    case SCHEDULE_KIND.CONFERENCE:
      label = '会議';
      color = 'secondary';
      break;
    case SCHEDULE_KIND.TRAVEL:
      label = '移動';
      color = 'info';
      break;
    case SCHEDULE_KIND.WORK:
      label = '仕事';
      color = 'success';
      break;
    case SCHEDULE_KIND.BREAK:
      label = '休憩';
      color = 'warning';
      break;
    case SCHEDULE_KIND.CUSTOMER_SERVICE:
      label = '顧客対応';
      color = 'error';
      break;
    default:
      label = '未定義';
      color = 'default';
      break;
  }
  return { label: label, color: color };
};

export const GetCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude: latitude, longitude: longitude });
        },
        (error) => {
          resolve({ latitude: null, longitude: null });
        }
      );
    } else {
      resolve({ latitude: null, longitude: null });
    }
  });
};

export const ExtractTimeFromDatetimeString = (datetime) => {
  const date = new Date(datetime);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const FormatAddressFromGeocordingAPI = (res) => {
  const result = res.data.results[0];
  const components = result.components;

  // 日本の住所をフォーマットする
  return `${components.province}${components.city}${components.suburb}${components.neighbourhood}`;
};

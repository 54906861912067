import { atom } from 'recoil';

export const loading = atom({
  key: 'isLoading',
  default: false,
});

// ユーザーネームは一意な名前(ID)
// 本名
export const loginInfo = atom({
  key: 'loginInfo',
  default: {
    companyId: '',
    loginUserName: '',
    loginUserFullName: '',
  },
});

// トーストの開閉状態
export const toastState = atom({
  key: 'isOpenToast',
  default: { isOpen: false, context: '' },
});

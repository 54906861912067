import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Paper,
  Avatar,
  Button,
} from '@mui/material';

const paperStyle = {
  padding: '20px',
  textAlign: 'center',
  color: '#555',
};

const avatarStyle = {
  width: '100px',
  height: '100px',
  margin: 'auto',
  marginBottom: '20px',
};

function Account() {
  return (
    <Container maxWidth="md" style={{ marginTop: '40px' }}>
      <Typography variant="h2" align="center" gutterBottom>
        My Account
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper style={paperStyle}>
            <Avatar
              alt="User Avatar"
              src="/static/images/avatar/1.jpg"
              style={avatarStyle}
            />
            <Typography variant="h4" gutterBottom>
              John Doe
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: johndoe@example.com
            </Typography>
            <Button variant="contained" color="primary">
              Edit Profile
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper style={paperStyle}>
            <Typography variant="h4" gutterBottom>
              Account Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              Manage your account settings and preferences here.
            </Typography>
            <Button variant="contained" color="primary">
              Change Password
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Account;

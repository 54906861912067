import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';

function TotalValueControl(props) {
  if (props.isEdit) {
    return (
      <Typography
        fontSize={props.component.fontSize * props.scalingFactor + 'px'}
      >
        {props.value}
      </Typography>
    );
  } else {
    return (
      <Typography
        fontSize={props.component.fontSize * props.scalingFactor + 'px'}
      >
        {'合計値'}
      </Typography>
    );
  }
}

export { TotalValueControl };

import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ID } from '../define';
import { LoginUser } from '../web_api';
import logo from './../../image/logo.PNG';
import { loginInfo, toastState } from '../recoil/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');

  // ログイン時にatomに値を保持する関数
  const _setLoginInfo = useSetRecoilState(loginInfo);
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!username) newErrors.username = 'ユーザーネームを入力してください';
    if (!password) newErrors.password = 'パスワードを入力してください';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = () => {
    if (validate()) {
      // ログイン処理をここに追加
      LoginUser(
        username,
        password,
        (res) => {
          _setLoginInfo({
            companyId: res.data.companyId,
            loginUserName: res.data.userName,
            loginUserFullName: res.data.lastName + ' ' + res.data.firstName,
          });
          _setToastState({ isOpen: true, context: 'ログインしました' });
          navigate('/' + PAGE_ID.HOME);
        },
        (e) => {
          console.warn(e);
          setLoginError('ユーザーネームかパスワードが違います');
        }
      );
    }
  };

  const handleForgotPassword = () => {
    // パスワード忘れの処理をここに追加
    console.log('Forgot Password');
  };

  const handleRegister = () => {
    // 会員登録の処理をここに追加
    navigate('/' + PAGE_ID.REGIST);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100vw', height: '100vh', backgroundColor: '#c8c8c8' }}
    >
      <Paper
        elevation={3}
        style={{ padding: '20px', maxWidth: '400px', width: '100%' }}
      >
        <img src={logo} />
        <form noValidate autoComplete="off">
          <TextField
            label="ユーザーネーム"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!errors.username}
            helperText={errors.username}
            required
          />
          <TextField
            label="パスワード"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            required
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleLogin()}
            >
              ログイン
            </Button>
          </Box>
          {loginError && (
            <Typography variant="body2" color="error" align="center" mt={2}>
              {loginError}
            </Typography>
          )}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Link
              style={{ cursor: 'pointer' }}
              onClick={handleForgotPassword}
              variant="body2"
            >
              パスワードを忘れた方
            </Link>
            <Link
              style={{ cursor: 'pointer' }}
              onClick={handleRegister}
              variant="body2"
            >
              会員登録はこちら
            </Link>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/SquareRounded';

function Icon(props) {
  const displayIcon = () => {
    switch (props.component.iconName) {
      case 'circle':
        return (
          <CircleIcon
            style={{
              fontSize: props.component.fontSize * props.scalingFactor + 'px',
            }}
          />
        );
      case 'square':
        return (
          <SquareIcon
            style={{
              fontSize: props.component.fontSize * props.scalingFactor + 'px',
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {displayIcon()}
      <Typography
        fontSize={props.component.fontSize * props.scalingFactor + 'px'}
        style={{ display: 'inline-block' }}
      >
        {props.component.text}
      </Typography>
    </div>
  );
}

export { Icon };

import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Paper,
  Divider,
  Button,
} from '@mui/material';

function Settings() {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Profile Settings
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" gutterBottom>
              Edit your profile information here.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              Edit Profile
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Account Settings
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" gutterBottom>
              Manage your account settings here.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              Manage Account
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Settings;

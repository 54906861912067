// 定義ファイル

//export const BASE_ADDRESS = 'https://localhost:44363/api/';
export const BASE_ADDRESS = 'https://nippo0831.ddo.jp/api/api/';

export const PAGE_ID = {
  ACCOUNT: 'Account',
  REGIST: 'Regist',
  SETTINGS: 'Settings',
  REGIST_USER: 'Regist/User',
  REGIST_COMPANY: 'Regist/Company',
  HOME: 'Home',
  CREATE_DAILY_REPORT: 'CreateDailyReport',
  SCHEDULE: 'Schedule',
  PAID_LEAVE_CHECK: 'PaidLeaveCheck',
  STAMP_HISTORY: 'StampHistory',
  EQUIPMENT_RESERVATION: 'EquipmentReservation',
  APPLICATION: 'Application',
  STAMP_MANAGE: 'StampManage',
  PAID_LEAVE_MANAGE: 'PaidLeaveManage',
  EQUIPMENT_MANAGE: 'EquipmentManage',
  DAILY_REPORT_APPROVAL: 'DailyReportApproval',
  DAILY_REPORT_REVIEW: 'DailyReportReview',
  APPLICATION_APPROVAL: 'ApplicationApproval',
  APPLICATION_REVIEW: 'ApplicationReview',
  COMPANY_SETTINGS: 'CompanySettings',
  EMPLOYEE_SETTINGS: 'EmployeeSettings',
  DEPARTMENT_SETTINGS: 'DepartmentSettings',
  APPLICATION_SETTINGS: 'ApplicationSettings',
  SITE_SETTINGS: 'SiteSettings',
  WORKFLOW_SETTINGS: 'WorkflowSettings',
  DAILY_REPORT_DESIGN: 'DailyReportDesign',
};

export const STEP = {
  ONE: 0,
  TWO: 1,
  THREE: 2,
  FOUR: 3,
  FIVE: 4,
};

export const CONTROL = {
  TYPOGRAPHY: 'typography',
  ICON: 'icon',
  IMAGE: 'image',
  PHOTO: 'photo',
  RADIO: 'radio',
  TEXTFIELD: { TEXT: 'textfield_text', NUM: 'textfield_num' },
  WEATHER: 'weather',
  TABLE: 'table',
  CHECKBOX: 'checkbox',
  COMBOBOX: 'combobx',
  CALENDER: 'calender',
  TIME: 'time',
  TOTAL_VALUE: 'total_value',
  INPUT_GROUP: {
    TEXTFIELD: { FIXED: 'fixed_textfield' },
    TEXTFIELD_NUM: {
      FIXED: 'fixed_textfield_num',
      MONTHLY: 'monthly_textfield_num',
    },
    TIME: { FIXED: 'fixed_time' },
  },
};

export const APPROVE_KIND = {
  ALL: 0,
  ALL_STEP: 1,
  SINGLE: 2,
};

export const APPROVE_MENU = {
  DAILY_REPORT: 0,
};

export const NOTIFICATION_KIND = {
  SYSTEM_NOTIFICATION: 10,
};

export const SCHEDULE_KIND = {
  MEETING: 0,
  CONFERENCE: 1,
  TRAVEL: 2,
  WORK: 3,
  BREAK: 4,
  CUSTOMER_SERVICE: 5,
};

export const APPLICATION_TYPE = {
  LEAVE: 0,
  OVERTIME: 1,
  BUSINESS_TRIP: 2,
  EXPENSE: 3,
};

export const PERMISSION = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
};

export const STAMP_TYPE = {
  CLOCK_IN: 0,
  CLOCK_OUT: 1,
  BREAK_START: 10,
  BREAK_END: 11,
  WORK_START: 20,
  WORK_END: 21,
};

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableBody,
  Button,
} from '@mui/material';
import './dailyReportApproval.css';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  FindApprovalStatuses,
  FindUsersByCompanyId,
} from '../../../Util/web_api';
import UserSelector from '../../../Util/commonComponent/userSelector';
import { STEP } from '../../../Util/define';

function ChoiceUser(props) {
  const {
    users,
    submitDailyReports,
    choicedUserId,
    setChoicedUserId,
    setStep,
    setApprovalTargetUserName,
  } = props;

  const FindUsersSubmitDailyReport = (userName) => {
    console.log(submitDailyReports);

    return submitDailyReports.filter(
      (dailyReport) => dailyReport.submitDailyReport.submitterId === userName
    );
  };

  const displayName = (userName) => {
    const temp = users.find((emp) => emp.userName === userName);
    if (temp === null || temp === undefined) {
      return '不明なユーザー';
    } else {
      return temp.lastName + ' ' + temp.firstName;
    }
  };

  return (
    <div>
      <div
        style={{ display: 'flex', maxWidth: '800px', alignItems: 'flex-end' }}
      >
        <UserSelector
          users={users}
          choicedUserId={choicedUserId}
          setChoicedUserId={setChoicedUserId}
          style={{ width: '250px', marginTop: '10px' }}
        />
        <div style={{ flexGrow: 1 }} />
        <Button variant="outlined">まとめて承認する</Button>
      </div>

      <TableContainer
        sx={{ maxWidth: '800px', marginTop: '10px' }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '30px', color: '#eceff1' }}
              >
                <Checkbox
                  sx={{
                    color: '#eceff1',
                    '&.Mui-checked': {
                      color: '#eceff1',
                    },
                  }}
                />
              </TableCell>
              <TableCell
                padding="nomal"
                sx={{ width: '250px', color: '#eceff1' }}
              >
                社員名
              </TableCell>
              <TableCell align="left" sx={{ width: '150px', color: '#eceff1' }}>
                日報
              </TableCell>
            </TableRow>
          </TableHead>
          {users.map((user, i) => {
            const dailyReports = FindUsersSubmitDailyReport(user.userName);
            return (
              <TableBody key={user.workflowId}>
                <TableRow
                  sx={{
                    backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                  }}
                >
                  <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                    <Checkbox
                      sx={{
                        color: '#263238',
                        '&.Mui-checked': {
                          color: '#263238',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>{displayName(user.userName)}</TableCell>
                  {dailyReports.length > 0 ? (
                    <TableCell>
                      <Link
                        align="left"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setStep(STEP.TWO);
                          setApprovalTargetUserName(user.userName);
                        }}
                      >
                        {dailyReports.length + '件'}
                      </Link>
                    </TableCell>
                  ) : (
                    <TableCell align="left">{'0件'}</TableCell>
                  )}
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </div>
  );
}

export default ChoiceUser;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';

function TextFieldText(props) {
  if (props.isEdit) {
    return (
      <TextField
        value={props.value}
        onChange={(e) =>
          props.setValue(props.component.componentId, e.target.value)
        }
        style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
        label={props.component.isShowLabel ? props.component.text : ''}
        multiline={props.component.isMultiline}
        rows={props.component.isMultiline ? props.component.height : 1}
        sx={{
          height: '100%',
          '& .MuiInputBase-root': {
            width: '100%',
            height: '100%', // 変更したい高さ
          },
          '& .MuiInputBase-input': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
          },
        }}
      />
    );
  } else {
    return (
      <TextField
        style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
        label={props.component.isShowLabel ? props.component.text : ''}
        multiline={props.component.isMultiline}
        rows={props.component.isMultiline ? props.component.height : 1}
        sx={{
          '& .MuiInputBase-root': {
            width: '100%',
            height: '100%', // 変更したい高さ
          },
          '& .MuiInputBase-input': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
          },
        }}
      />
    );
  }
}

export { TextFieldText };

import React from 'react';
import { Typography, Container, Grid, Link } from '@mui/material';
import './dailyReportReview.css';

function DailyReportReview() {
  return (
    <div className="pcDailyReportReview">
      <Typography>日報確認</Typography>
    </div>
  );
}

export default DailyReportReview;

import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import './createDailyReport.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ja';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { STEP } from '../../../Util/define';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import { GetDailyReportByUserIdAndTargetDate } from '../../../Util/web_api';
import { TimeToUTC } from '../../../Util/function';

function SelectDate(props) {
  const {
    selectedMonth,
    setSelectedMonth,
    dates,
    setCreateStep,
    sites,
    setSelectedDate,
    setSite,
  } = props;

  // datepickerを日本語に
  dayjs.locale('ja');

  const [date, setDate] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [dailyReports, setDailyReports] = useState([]);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  useEffect(() => {
    GetDailyReportByUserIdAndTargetDate(
      _loginInfo.loginUserName,
      TimeToUTC(new Date(selectedMonth.year(), selectedMonth.month(), 1)),
      TimeToUTC(new Date(selectedMonth.year(), selectedMonth.month() + 1, 1)),
      (res) => {
        setDailyReports(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  }, [selectedMonth]);

  const handleClickCreate = (e, date) => {
    setDate(date);
    setAnchorEl(e.currentTarget);
    setOpenMenu(true);
  };

  const handleClickDailyReport = (site) => {
    setSelectedDate(date);
    setSite(site);
    setOpenMenu(false);
    setCreateStep(STEP.TWO);
  };

  const close = () => {
    setDate(null);
    setOpenMenu(false);
  };

  const menu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={close}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {sites.length !== 0 ? (
          sites.map((site) => (
            <MenuItem
              key={site.siteId}
              onClick={() => handleClickDailyReport(site)}
            >
              <ListItemText>{site.siteName}</ListItemText>
            </MenuItem>
          ))
        ) : (
          <ListSubheader>現場が作成されていません</ListSubheader>
        )}
      </Menu>
    );
  };

  // 指定した日付の日報が存在すればそれを返す
  const searchDailyReportByDate = (date) => {
    const getDayFromISOString = (isoString) => {
      if (typeof isoString !== 'string') {
        throw new TypeError('Input should be a string');
      }

      // 年月日の部分だけを抽出
      const datePart = isoString.split('T')[0];
      // 日の部分だけを抽出
      const day = datePart.split('-')[2];

      return Number(day);
    };
    if (dailyReports.length > 0) {
      return dailyReports.filter(
        (dr) => getDayFromISOString(dr.targetDate) === date
      );
    } else {
      return [];
    }
  };

  // tableRowを返す関数
  const row = (date, i) => {
    const drs = searchDailyReportByDate(date.getDate());
    if (drs.length > 0) {
      console.log(drs);
      return (
        <TableRow
          sx={{
            backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
          }}
        >
          <TableCell>{date.getMonth() + 1 + '/' + date.getDate()}</TableCell>
          <TableCell align="left">
            {drs.map((dr, i) =>
              dr.isApprove ? (
                <Box>
                  <Chip
                    label="承認済"
                    sx={{
                      borderRadius: '4px',
                      marginRight: '10px',
                      backgroundColor: '#388e3c',
                      color: 'white',
                      marginTop: i > 0 ? '3px' : '0px',
                    }}
                  />

                  {dr.dailyReportId}
                </Box>
              ) : (
                <Box>
                  <Chip
                    label="未承認"
                    sx={{
                      borderRadius: '4px',
                      marginRight: '10px',
                      backgroundColor: '#ef5350',
                      color: 'white',
                      marginTop: i > 0 ? '3px' : '0px',
                    }}
                  />
                  {dr.site.siteName}
                </Box>
              )
            )}
          </TableCell>
          <TableCell align="left">
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'right',
              }}
            >
              {isCopyMode ? (
                <Tooltip title="コピー">
                  <IconButton>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                drs.map((dr, i) =>
                  dr.isApprove ? (
                    <Box>
                      <Tooltip title="新規作成">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="編集">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <DeleteIcon sx={{ color: '#f44336' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box>
                      <Tooltip title="新規作成">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="編集">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除">
                        <IconButton onClick={(e) => handleClickCreate(e, date)}>
                          <DeleteIcon sx={{ color: '#f44336' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )
                )
              )}
            </div>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow
          sx={{
            backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
          }}
        >
          <TableCell>{date.getMonth() + 1 + '/' + date.getDate()}</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
              }}
            >
              {isCopyMode ? null : (
                <React.Fragment>
                  <Tooltip title="新規作成">
                    <IconButton onClick={(e) => handleClickCreate(e, date)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </div>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <div>
      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'flex-end',
          width: '700px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker
            slotProps={{
              calendarHeader: {
                format: 'YYYY年MM月', // カレンダーの年月の部分
              },
            }}
            format="YYYY/MM" // 入力欄
            label="表示月"
            views={['year', 'month']}
            openTo="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(dayjs(e))}
          />
        </LocalizationProvider>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          sx={{ marginLeft: '10px' }}
          onClick={(e) => handleClickCreate(e, new Date())}
          disabled={isCopyMode}
        >
          本日の日報を作成
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: '10px' }}
          onClick={() => setIsCopyMode(!isCopyMode)}
          color={isCopyMode ? 'secondary' : 'primary'}
        >
          {isCopyMode ? 'コピー終了' : '日報コピー'}
        </Button>
      </div>
      <TableContainer
        sx={{
          maxWidth: '700px',
          minWidth: '700px',
          marginTop: '10px',
          marginBottom: '30px',
        }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '100px', color: '#eceff1' }}
              >
                日付
              </TableCell>
              <TableCell align="left" sx={{ width: '383px', color: '#eceff1' }}>
                日報
              </TableCell>
              <TableCell align="left" sx={{ color: '#eceff1' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.length > 0 ? dates.map((date, i) => row(date, i)) : null}
          </TableBody>
        </Table>
      </TableContainer>
      {menu()}
    </div>
  );
}

export default SelectDate;

import React, { useEffect, useState } from 'react';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { RegistDepartment, RegistUser } from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import DailyReportSelector from '../../../Util/commonComponent/dailyReportSelector';
import WorkflowSelector from '../../../Util/commonComponent/workflowSelector';

function CreateDepartmentDialog(props) {
  const { isOpenDialog, setIsOpenDialog, setUpdateFlag } = props;
  const [isUse, setIsUse] = useState(true);
  const [departmentName, setDepartmentName] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const handleRegister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // フォームのバリデーション
    const errors = {};
    if (!departmentName.trim()) {
      errors.departmentName = '部門名を入力してください';
    }
    if (!departmentId.trim()) {
      errors.departmentId = '部門IDを入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      DepartmentId: departmentId,
      DepartmentName: departmentName,
      ParentId: null,
      CompanyId: _loginInfo.companyId,
      Hierarchy: 0,
      IsUse: isUse,
    };
    RegistDepartment(
      Poster,
      (res) => {
        setUpdateFlag(true);
        setIsOpenDialog(false);
        setIsUse(true);
        setDepartmentName('');
        setDepartmentId('');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const content = () => {
    return (
      <form noValidate autoComplete="off" style={{ marginTop: '10px' }}>
        <div>
          <FormControlLabel
            control={<Switch />}
            label="使用する"
            checked={isUse}
            onChange={() => setIsUse(!isUse)}
          />
        </div>

        <TextField
          label="部門名"
          variant="outlined"
          margin="normal"
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
          error={!!errors.departmentName}
          helperText={errors.departmentName}
          required
          style={{ width: '215px' }}
        />
        <TextField
          label="部門ID"
          variant="outlined"
          margin="normal"
          value={departmentId}
          onChange={(e) => setDepartmentId(e.target.value)}
          error={!!errors.departmentId}
          helperText={errors.departmentId}
          required
          style={{ width: '215px', marginLeft: '20px' }}
        />
      </form>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpenDialog}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpenDialog(false);
        setIsUse(true);
        setDepartmentName('');
        setDepartmentId('');
      }}
      yesText={'追加'}
      noText={'キャンセル'}
      title={'部門設定'}
      content={content()}
    />
  );
}

export default CreateDepartmentDialog;

import React, { useEffect, useState } from 'react';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import { Box, Button, TextField } from '@mui/material';
import { RegistUser } from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';

function AddUserDialog(props) {
  const { isOpenDialog, setIsOpenDialog, setUpdateFlag } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const handleRegister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // フォームのバリデーション
    const errors = {};
    if (!username.trim()) {
      errors.username = 'ユーザーネームを入力してください';
    }
    if (!password.trim()) {
      errors.password = 'パスワードを入力してください';
    }
    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'パスワード（確認用）を入力してください';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'パスワードが一致しません';
    }
    if (!lastName.trim()) {
      errors.lastName = '姓を入力してください';
    }
    if (!firstName.trim()) {
      errors.firstName = '名を入力してください';
    }
    if (!email.trim()) {
      errors.email = 'メールアドレスを入力してください';
    } else if (!emailRegex.test(email.trim())) {
      errors.email = '正しいメールアドレス形式で入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      CompanyId: _loginInfo.companyId,
      UserName: username,
      Mailaddress: email,
      LastName: lastName,
      FirstName: firstName,
      Password: password,
    };
    console.log(Poster);
    RegistUser(
      Poster,
      (res) => {
        setIsOpenDialog(false);
        setUpdateFlag(true);
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setLastName('');
        setFirstName('');
        setEmail('');
        setErrors({});
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const content = () => {
    return (
      <form noValidate autoComplete="off">
        <TextField
          label="姓"
          variant="outlined"
          margin="normal"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={!!errors.lastName}
          helperText={errors.lastName}
          required
          style={{ width: '215px' }}
        />
        <TextField
          label="名"
          variant="outlined"
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={!!errors.firstName}
          helperText={errors.firstName}
          required
          style={{ width: '215px', marginLeft: '20px' }}
        />
        <TextField
          label="メールアドレス"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
          required
        />
        <TextField
          label="ユーザーネーム"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={!!errors.username}
          helperText={errors.username}
          required
        />
        <TextField
          label="パスワード"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
          required
        />
        <TextField
          label="パスワード（確認用）"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          required
        />
      </form>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpenDialog}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpenDialog(false);
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setLastName('');
        setFirstName('');
        setEmail('');
        setErrors({});
      }}
      yesText={'OK'}
      noText={'キャンセル'}
      title={'社員設定'}
      content={content()}
    />
  );
}

export default AddUserDialog;

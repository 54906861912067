import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

// 天気情報
const weatherData = [
  { id: 1, weather: '晴れ' },
  { id: 2, weather: '曇り' },
  { id: 3, weather: '雨' },
  { id: 4, weather: '雪' },
];

function Weather(props) {
  if (props.isEdit) {
    return (
      <FormControl
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <InputLabel id="demo-simple-select-label">
          {props.component.isShowLabel ? props.component.text : ''}
        </InputLabel>
        <Select
          value={props.value}
          onChange={(e) =>
            props.setValue(props.component.componentId, e.target.value)
          }
          labelId="demo-simple-select-label"
          label={props.component.isShowLabel ? props.component.text : ''}
          style={{
            width: '100%',
            height: '100%',
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
            backgroundColor: 'white',
          }}
        >
          {weatherData.map((weather) => (
            <MenuItem value={weather.id}>{weather.weather}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <InputLabel id="demo-simple-select-label">
          {props.component.isShowLabel ? props.component.text : ''}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label={props.component.isShowLabel ? props.component.text : ''}
          style={{
            width: '100%',
            height: '100%',
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
            backgroundColor: 'white',
          }}
        >
          {weatherData.map((weather) => (
            <MenuItem value={weather.id}>{weather.weather}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export { Weather };

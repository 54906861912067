import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ID } from '../define';
import { Password } from '@mui/icons-material';
import { RegistCompany as RG } from '../web_api';
import logo from './../../image/logo.PNG';
import { useSetRecoilState } from 'recoil';
import { toastState } from '../recoil/atom';

const RegistCompany = () => {
  const [companyname, setCompanyname] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [contractIdCount, setContractIdCount] = useState(10);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();

  const handleRegister = () => {
    // メールアドレスの正規表現
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 電話番号の正規表現
    const domesticPhoneRegex = /^0[1-9]0\d{8}$/;
    const mobilePhoneRegex = /^0[789]0\d{8}$/;
    const internationalPhoneRegex = /^\+\d{1,15}$/;

    // フォームのバリデーション
    const errors = {};
    if (!companyname.trim()) {
      errors.companyname = '会社名を入力してください';
    }
    if (!lastName.trim()) {
      errors.lastName = '姓を入力してください';
    }
    if (!firstName.trim()) {
      errors.firstName = '名を入力してください';
    }
    if (!contact.trim()) {
      errors.contact = '電話番号を入力してください';
    } else if (
      !(
        domesticPhoneRegex.test(contact) ||
        mobilePhoneRegex.test(contact) ||
        internationalPhoneRegex.test(contact)
      )
    ) {
      errors.contact = '無効な電話番号です';
    }
    if (!email.trim()) {
      errors.email = 'メールアドレスを入力してください';
    } else if (!emailRegex.test(email.trim())) {
      errors.email = '無効なメールアドレスです';
    }
    if (!companyId.trim()) {
      errors.companyId = '会社IDを入力してください';
    }
    if (contractIdCount % 10 !== 0) {
      errors.contractIdCount = '10ID単位です';
    }
    if (!password.trim()) {
      errors.password = 'パスワードを入力してください';
    }
    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'パスワード（確認用）を入力してください';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'パスワードが一致しません';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      CompanyId: companyId,
      CompanyName: companyname,
      Contact: contact,
      Mailaddress: email,
      LastName: lastName,
      FirstName: firstName,
      ContractIdCount: contractIdCount,
      Password: password,
    };
    RG(
      Poster,
      (res) => {
        _setToastState({
          isOpen: true,
          context: '会社の登録が完了しました',
        });
        navigate('/');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100vw', height: '100vh', backgroundColor: '#c8c8c8' }}
    >
      <Paper
        elevation={3}
        style={{ padding: '20px', maxWidth: '600px', width: '100%' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <img src={logo} />
        </div>
        <Typography variant="h5" align="center" gutterBottom>
          会社登録フォーム
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            label="会社名"
            variant="outlined"
            fullWidth
            margin="normal"
            value={companyname}
            onChange={(e) => setCompanyname(e.target.value)}
            error={!!errors.companyname}
            helperText={errors.companyname}
            required
          />
          <TextField
            label="担当者姓"
            variant="outlined"
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
            required
            style={{ width: '250px' }}
          />
          <TextField
            label="担当者名"
            variant="outlined"
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
            required
            style={{ width: '250px', marginLeft: '20px' }}
          />
          <TextField
            label="担当者電話番号"
            variant="outlined"
            margin="normal"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            error={!!errors.contact}
            helperText={errors.contact}
            required
            style={{ width: '250px' }}
          />
          <TextField
            label="担当者メールアドレス"
            variant="outlined"
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            required
            style={{ width: '330px', marginLeft: '20px' }}
          />
          <TextField
            label="会社ID"
            variant="outlined"
            margin="normal"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
            error={!!errors.companyId}
            helperText={errors.companyId}
            required
            style={{ width: '250px' }}
          />
          <TextField
            label="契約ID数"
            variant="outlined"
            margin="normal"
            value={contractIdCount}
            onChange={(e) => setContractIdCount(e.target.value)}
            error={!!errors.contractIdCount}
            helperText={errors.contractIdCount}
            required
            style={{ width: '250px', marginLeft: '20px' }}
            type="number"
          />
          <TextField
            label="管理用パスワード"
            variant="outlined"
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            required
            style={{ width: '250px' }}
          />
          <TextField
            label="管理用パスワード（確認用）"
            variant="outlined"
            margin="normal"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            required
            style={{ width: '250px', marginLeft: '20px' }}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRegister}
            >
              会社を登録する
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" align="center">
              すでにアカウントをお持ちの場合は
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
                variant="body2"
              >
                ログイン
              </Link>
              してください。
            </Typography>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default RegistCompany;

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableBody,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  TextField,
  Card,
} from '@mui/material';
import './dailyReportApproval.css';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  ApproveStatus,
  ChatOpenAIWithMessage,
  FindApprovalStatuses,
  FindDailyReportValuesBySubmitId,
  FindUsersByCompanyId,
  GetAllDailyReportComponentByDailyReportId,
} from '../../../Util/web_api';
import UserSelector from '../../../Util/commonComponent/userSelector';
import { APPROVE_MENU, STEP } from '../../../Util/define';
import DailyReportShow from '../../../Util/commonComponent/dailyReportShow';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import { TimeToUTC } from '../../../Util/function';

function Approve(props) {
  const { submitDailyReports, setStep } = props;

  const [targetDailyReportId, setTargetDailyReportId] = useState('');
  const [targetDailyReport, setTargetDailyReport] = useState([]);
  const [targetDailyReportComponents, setTargetDailyReportComponents] =
    useState([]);
  const [targetDailyReportValues, setTargetDailyReportValues] = useState([]);
  const [scalingFactor, setScalingFactor] = useState(1);
  const [isPcView, setisPcView] = useState(true);
  const [isInit, setIsInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState('');

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  useEffect(() => {
    console.log(submitDailyReports);
    setTargetDailyReport(submitDailyReports[0]);
    setTargetDailyReportId(submitDailyReports[0].submitDailyReport.submitId);
    findDailyReport(submitDailyReports[0].submitDailyReport.dailyReportId);
    findDailyReportValues(submitDailyReports[0].submitDailyReport.submitId);
  }, []);

  useEffect(() => {
    if (targetDailyReportId === '') {
      return;
    }
    if (isInit) {
      setIsInit(false);
      return;
    }
    if (targetDailyReportId !== '') {
      setTargetDailyReport(
        submitDailyReports.find(
          (dr) => dr.submitDailyReport.submitId === targetDailyReportId
        )
      );
      findDailyReport(submitDailyReports[0].submitDailyReport.dailyReportId);
      findDailyReportValues(submitDailyReports[0].submitDailyReport.submitId);
    }
  }, [targetDailyReportId]);

  useEffect(() => {
    if (
      targetDailyReportValues.length > 0 &&
      targetDailyReportComponents.length > 0 &&
      !loading
    ) {
      sendOpenAIMessage();
      setLoading(true);
    }
  }, [targetDailyReportValues, targetDailyReportComponents]);

  const findDailyReport = (id) => {
    GetAllDailyReportComponentByDailyReportId(
      id !== null ? id : targetDailyReport.submitDailyReport.dailyReportId,
      (res) => {
        setTargetDailyReportComponents(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const findDailyReportValues = (id) => {
    FindDailyReportValuesBySubmitId(
      id !== null ? id : targetDailyReport.submitDailyReport.submitId,
      (res) => {
        setTargetDailyReportValues(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const sendOpenAIMessage = () => {
    const extractDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const workDate = extractDate(
      targetDailyReport.submitDailyReport.targetDate
    );
    const siteName = targetDailyReport.submitDailyReport.site.siteName;
    let prepareData = targetDailyReportValues.map((drv) => {
      const component = targetDailyReportComponents.find(
        (drc) => drc.componentId === drv.componentId
      );
      if (component && component.text) {
        return { name: component.text, context: drv.value };
      }
    });
    const message = `これは${workDate}に提出された${siteName}での作業日報データです。解析して一日の状況を「1, 作業時間、2, 作業内容」に焦点を当てて300文字以内で要約してください。${JSON.stringify(
      prepareData
    )}`;
    console.log(message);
    ChatOpenAIWithMessage(
      message,
      (res) => {
        setSummary(res.data.choices[0].message.content);
        setLoading(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const approveDailyReport = () => {
    const poster = {
      ApprovalMenu: APPROVE_MENU.DAILY_REPORT,
      TargetId: targetDailyReportId,
      IsApproved: true,
      ApproveDate: TimeToUTC(new Date()),
      UserName: _loginInfo.loginUserName,
    };

    ApproveStatus(
      poster,
      (res) => {
        console.log(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          margin: '15px',
          maxWidth: '1200px',
          alignItems: 'flex-end',
        }}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">日報</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={targetDailyReportId}
            label="日報"
            onChange={(e) => setTargetDailyReportId(e.target.value)}
            style={{ width: '150px' }}
          >
            {submitDailyReports.map((dr) => (
              <MenuItem value={dr.submitDailyReport.submitId}>
                {dr.submitDailyReport.site.siteName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '10px' }}>
          <InputLabel id="demo-simple-select-label">拡大率</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={scalingFactor}
            label="拡大率"
            onChange={(e) => setScalingFactor(e.target.value)}
            style={{ width: '100px' }}
          >
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.75}>0.75</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '10px' }}>
          <InputLabel id="demo-simple-select-label">表示モード</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={isPcView}
            label="表示モード"
            onChange={(e) => setisPcView(e.target.value)}
            style={{ width: '120px' }}
          >
            <MenuItem value={true}>PC</MenuItem>
            <MenuItem value={false}>モバイル</MenuItem>
          </Select>
        </FormControl>
        <div style={{ flexGrow: 1 }} />
        <Button
          startIcon={<ThumbUpAltRoundedIcon />}
          sx={{ marginLeft: '30px', width: '130px' }}
          variant="contained"
          onClick={approveDailyReport}
        >
          承認する
        </Button>
        <Button
          startIcon={<ThumbDownAltRoundedIcon />}
          color="error"
          sx={{ marginLeft: '10px', width: '130px' }}
          variant="outlined"
        >
          差し戻す
        </Button>
        <Button
          sx={{ marginLeft: '20px', width: '50px' }}
          variant="outlined"
          onClick={() => setStep(STEP.ONE)}
        >
          戻る
        </Button>
      </div>
      <Card
        variant="outlined"
        sx={{
          margin: '15px',
          maxWidth: '1200px',
          minHeight: '80px',
          padding: '10px',
        }}
      >
        <Typography color="#2196f3">{'AIによる日報要約'}</Typography>
        {loading ? <CircularProgress /> : <Typography>{summary}</Typography>}
      </Card>

      {targetDailyReportValues.length !== 0 && (
        <DailyReportShow
          dailyReport={targetDailyReportComponents}
          scalingFactor={scalingFactor}
          isPcView={isPcView}
          dailyReportValues={targetDailyReportValues}
          date={targetDailyReport.submitDailyReport.targetDate}
        />
      )}
    </div>
  );
}

export default Approve;

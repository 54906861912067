import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Paper,
  ListSubheader,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import './siteSettings.css';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FindSiteBySiteId,
  FindSitesByCompanyId,
  FindWorkflowsByCompanyId,
  GetAllDailyReportByCompanyId,
  UpdateSite,
} from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import WorkflowSelector from '../../../Util/commonComponent/workflowSelector';
import DailyReportSelector from '../../../Util/commonComponent/dailyReportSelector';
import CreateSiteDialog from './createSiteDialog';
import CreateChildSiteDialog from './createChildSiteDialog';

function SiteSettings() {
  const [sites, setSites] = useState([]);
  const [errors, setErrors] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isOpenCreateSiteDialog, setIsOpenCreateSiteDialog] = useState(false);
  const [isOpenCreateChildSiteDialog, setIsOpenCreateChildSiteDialog] =
    useState(false);
  const [choicedSiteId, setChoicedSiteId] = useState('');
  const [choicedSite, setChoicedSite] = useState([]);
  const [editChoicedSite, setEditChoicedSite] = useState([]);
  const [isApplyChange, setIsApplyChange] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [choicedWorkflowId, setChoicedWorkflowId] = useState('');
  const [dailyReports, setDailyReports] = useState([]);
  const [choicedDailyReportId, setChoicedDailyReportId] = useState('');

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const FindSites = () => {
    FindSitesByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setSites(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindSite = () => {
    FindSiteBySiteId(
      choicedSiteId,
      (res) => {
        setChoicedSite(res.data);
        setEditChoicedSite(res.data);
        setChoicedDailyReportId(
          res.data.dailyReport !== null
            ? res.data.dailyReport.dailyReportId
            : ''
        );
        setChoicedWorkflowId(
          res.data.workflow !== null ? res.data.workflow.workflowId : ''
        );
        setIsApplyChange(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindWorkflow = () => {
    FindWorkflowsByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setWorkflows(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindDailyReport = () => {
    GetAllDailyReportByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setDailyReports(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    FindSites();
    FindWorkflow();
    FindDailyReport();
  }, []);

  useEffect(() => {
    if (updateFlag) {
      FindSites();
      setUpdateFlag(false);
    }
  }, [updateFlag]);

  useEffect(() => {
    if (choicedSiteId !== '') {
      FindSite();
    }
  }, [choicedSiteId]);

  const updateEditChoicedDepartment = (key, value) => {
    setEditChoicedSite((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const update = () => {
    const poster = {
      SiteId: choicedSite.siteId,
      UpdateSiteId: editChoicedSite.siteId,
      UpdateSiteName: editChoicedSite.siteName,
      UpdateDailyReportId:
        choicedDailyReportId !== '' ? choicedDailyReportId : null,
      UpdateWorkflowId: choicedWorkflowId !== '' ? choicedWorkflowId : null,
      UpdateIsUse: editChoicedSite.isUse,
    };
    UpdateSite(
      poster,
      (res) => {
        setUpdateFlag(true);
        FindSites();
        setIsApplyChange(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <div className="pcSiteSettings">
      <CreateSiteDialog
        isOpenDialog={isOpenCreateSiteDialog}
        setIsOpenDialog={setIsOpenCreateSiteDialog}
        setUpdateFlag={setUpdateFlag}
        dailyReports={dailyReports}
        workflows={workflows}
      />
      <CreateChildSiteDialog
        isOpenDialog={isOpenCreateChildSiteDialog}
        setIsOpenDialog={setIsOpenCreateChildSiteDialog}
        setUpdateFlag={setUpdateFlag}
        dailyReports={dailyReports}
        workflows={workflows}
        hierarchy={choicedSite.hierarchy + 1}
        parentId={choicedSite.siteId}
      />
      <Typography>現場設定</Typography>
      <div
        style={{
          width: '1000px',
          marginTop: '10px',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setIsOpenCreateSiteDialog(true)}
          >
            現場を作成する
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '600px' }}>
          <Paper
            sx={{
              width: '200px',
              borderRadius: '4px',
              marginLeft: '4px',
            }}
            elevation={3} // 影のレベルを指定
          >
            <ListSubheader
              sx={{
                backgroundColor: '#263238',
                color: '#eceff1',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                display: 'flex',
              }}
            >
              現場
            </ListSubheader>
            <List
              sx={{
                overflowY: 'auto',
                maxHeight: '532px', // 適切な高さを指定
              }}
            >
              {sites.map((site, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    selected={choicedSiteId === site.siteId}
                    onClick={() => setChoicedSiteId(site.siteId)}
                  >
                    <ListItemText
                      primary={site.siteName}
                      sx={{ marginLeft: site.hierarchy * 15 + 'px' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
          <Paper
            sx={{
              width: '200px',
              borderRadius: '4px',
              marginLeft: '20px',
              flexGrow: 1,
              padding: '20px',
            }}
            elevation={3} // 影のレベルを指定
          >
            {choicedSite.length !== 0 ? (
              <React.Fragment>
                <div style={{ display: 'flex' }}>
                  <Typography>{choicedSite.siteName}</Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ marginRight: '10px' }}
                    onClick={() => {
                      setIsOpenCreateChildSiteDialog(true);
                    }}
                  >
                    子現場を作成する
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    現場を削除する
                  </Button>
                </div>
                <form
                  noValidate
                  autoComplete="off"
                  style={{ marginTop: '10px' }}
                >
                  <div>
                    <FormControlLabel
                      control={<Switch />}
                      label="使用する"
                      checked={editChoicedSite.isUse}
                      onChange={(e) =>
                        updateEditChoicedDepartment('isUse', e.target.checked)
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      label="現場名"
                      variant="outlined"
                      margin="normal"
                      value={editChoicedSite.siteName}
                      onChange={(e) => {
                        updateEditChoicedDepartment('siteName', e.target.value);
                        setIsApplyChange(true);
                      }}
                      error={!!errors.departmentName}
                      helperText={errors.departmentName}
                      required
                      style={{ width: '215px' }}
                    />
                    <TextField
                      label="現場ID"
                      variant="outlined"
                      margin="normal"
                      value={editChoicedSite.siteId}
                      onChange={(e) => {
                        updateEditChoicedDepartment('siteId', e.target.value);
                        setIsApplyChange(true);
                      }}
                      error={!!errors.departmentId}
                      helperText={errors.departmentId}
                      required
                      style={{ width: '215px', marginLeft: '20px' }}
                    />
                  </div>
                  <div>
                    <DailyReportSelector
                      dailyReports={dailyReports}
                      choicedDailyReportId={choicedDailyReportId}
                      setChoicedDailyReportId={setChoicedDailyReportId}
                      setIsApplyChange={setIsApplyChange}
                      style={{
                        width: '215px',
                        marginTop: '20px',
                      }}
                    />
                    <WorkflowSelector
                      workflows={workflows}
                      choicedWorkflowId={choicedWorkflowId}
                      setChoicedWorkflowId={setChoicedWorkflowId}
                      setIsApplyChange={setIsApplyChange}
                      style={{
                        width: '215px',
                        marginTop: '20px',
                        marginLeft: '20px',
                      }}
                      helperText={'日報承認に使用するワークフロー'}
                    />
                  </div>
                </form>
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    disabled={!isApplyChange}
                    onClick={update}
                  >
                    変更を適応する
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <Typography>現場ツリーから現場を選択してください</Typography>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default SiteSettings;

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Paper,
  Chip,
  Button,
  Divider,
  CircularProgress,
  Popover,
} from '@mui/material';
import './home.css';
import {
  GetAddressByLocation,
  GetStampsByUserIdAndTargetDate,
  GetWeatherByLocation,
  RegistStamp,
} from '../../../Util/web_api';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import {
  DisplayMessageChip,
  DisplayScheduleChip,
  ExtractTimeFromDatetimeString,
  FormatAddressFromGeocordingAPI,
  GetCurrentLocation,
  TimeToUTC,
} from '../../../Util/function';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { PieChart } from '@mui/x-charts';
import { PERMISSION, STAMP_TYPE } from '../../../Util/define';
import { useRecoilValue } from 'recoil';
import { Login } from '@mui/icons-material';
import { loginInfo } from '../../../Util/recoil/atom';

const not = [
  { id: 'a', kind: 0, date: new Date(), title: '日報が提出されました。' },
  { id: 'd', kind: 0, date: new Date(), title: '日報が提出されました。' },
  {
    id: 'b',
    kind: 10,
    date: new Date(),
    title: '使い方はマニュアルを参考にしてください。',
  },
  { id: 'c', kind: 10, date: new Date(), title: 'Nippo0930へようこそ！' },
];

const squ = [
  {
    id: 'a',
    kind: 0,
    startDate: new Date(),
    endDate: new Date(),
    title: '社長と面談',
  },
  {
    id: 'd',
    kind: 1,
    startDate: new Date(),
    endDate: new Date(),
    title: '取引先と会議',
  },
  {
    id: 'b',
    kind: 2,
    startDate: new Date(),
    endDate: new Date(),
    title: '東京に移動',
  },
  {
    id: 'c',
    kind: 4,
    startDate: new Date(),
    endDate: new Date(),
    title: 'ホテルにチェックイン',
  },
];

const pie = [
  { value: 10, label: '今年度迄' },
  { value: 5, label: '来年度迄' },
  { value: 2, label: '再来年度迄' },
  { value: 10, label: '取得済み' },
];

function Home() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [weatherInfo, setWeatherInfo] = useState(null);
  const [isLoadingWeather, setIsLoadingWeather] = useState(true);
  const [weatherPerThreeHour, setWeatherPerThreeHour] = useState([]);
  const [notifications, setNotifications] = useState(not);
  const [schedules, setSchedules] = useState(squ);
  const [paidLeave, setPaidLeave] = useState(pie);
  const [stamps, setStamps] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [address, setAddress] = useState('');

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  useEffect(() => {
    // 1分ごとに更新
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // 60000ms = 1 minute

    // コンポーネントがアンマウントされるときにタイマーをクリア
    return () => clearInterval(intervalId);
  }, []);

  // 打刻情報を取得
  const getTodaysStamp = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 時間、分、秒、ミリ秒をすべて0に設定
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0); // 時間、分、秒、ミリ秒をすべて0に設定
    GetStampsByUserIdAndTargetDate(
      _loginInfo.loginUserName,
      TimeToUTC(today),
      TimeToUTC(tomorrow),
      (res) => {
        setStamps(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const getAddress = (latitude, longitude) => {
    GetAddressByLocation(
      latitude,
      longitude,
      (res) => {
        setAddress(FormatAddressFromGeocordingAPI(res));
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 天気情報を取得
  const fetchWeather = async () => {
    try {
      const location = await GetCurrentLocation();

      if (location.latitude && location.longitude) {
        GetWeatherByLocation(
          location.latitude,
          location.longitude,
          (res) => {
            const hours = res.data.forecast.forecastday[0].hour;
            const filteredHours = hours.filter((hour) => {
              const hourTime = new Date(hour.time);
              const hourInt = hourTime.getHours();
              return hourInt >= 6 && hourInt <= 21 && hourInt % 3 === 0; // 6時から21時までの3時間ごとのデータを抽出
            });
            setWeatherPerThreeHour(filteredHours);
            setWeatherInfo(res.data);
            setIsLoadingWeather(false);
          },
          (e) => {
            console.warn(e);
            setIsLoadingWeather(false);
          }
        );
      } else {
        setIsLoadingWeather(false);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (_loginInfo.loginUserName !== '') {
      fetchWeather();
      getTodaysStamp();
    }
  }, []);

  // 曜日を取得
  const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
  const dayOfWeek = daysOfWeek[currentTime.getDay()];

  // 年月日時分曜日の形式で時刻を表示
  const formattedDate = `${currentTime.getFullYear()}年${String(
    currentTime.getMonth() + 1
  ).padStart(2, '0')}月${String(currentTime.getDate()).padStart(
    2,
    '0'
  )}日 (${dayOfWeek})`;

  // 時間部分をフォーマット
  const formattedTime = `${currentTime.getHours()}:${String(
    currentTime.getMinutes()
  ).padStart(2, '0')}`;

  function formatDateToYYYYMMDD(date) {
    // 年を4桁で取得
    const year = date.getFullYear();
    // 月を2桁にする
    const month = String(date.getMonth() + 1).padStart(2, '0');
    // 日を2桁にする
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}年${month}月${day}日`;
  }

  function formatTime(date) {
    const hours = String(date.getHours()).padStart(2, '0'); // 時間を2桁にする
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 分を2桁にする

    return `${hours}時${minutes}分`;
  }

  const registStamp = async (stampType) => {
    try {
      const location = await GetCurrentLocation();
      const poster = {
        UserName: _loginInfo.loginUserName,
        StampType: stampType,
        StampDate: TimeToUTC(new Date()),
        Latitude: location.latitude,
        Longitude: location.longitude,
        IsStampFromApplication: false,
        IsCancel: false,
      };
      RegistStamp(
        poster,
        (res) => {
          console.log(res.data);
        },
        (e) => {
          console.warn(e);
        }
      );
    } catch {
      console.warn('打刻を登録できませんでした');
    }
  };

  const displayStampArea = () => {
    const clockInStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.CLOCK_IN
    );
    const clockOutStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.CLOCK_OUT
    );
    const breakStartStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.BREAK_START
    );
    const breakEndStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.BREAK_END
    );
    const workStartStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.WORK_START
    );
    const workEndStamp = stamps.filter(
      (ss) => ss.stampType === STAMP_TYPE.WORK_END
    );
    const handleClick = (event, latitude, longitude) => {
      setAnchorEl(event.currentTarget);
      if (!latitude || !longitude) {
        setAddress('位置情報が登録されていません');
      } else {
        getAddress(latitude, longitude);
      }
    };

    const handleClose = () => {
      setAddress('');
      setAnchorEl(null);
    };
    return (
      <Box sx={{ flex: 1 }}>
        <Popover
          id="popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                overflowY: 'hidden',
              },
            },
          }}
        >
          {address !== '' ? (
            <Typography sx={{ p: 1 }}>{address}</Typography>
          ) : (
            <CircularProgress size={20} sx={{ p: 1 }} />
          )}
        </Popover>
        <Paper elevation={3} sx={{ maxWidth: '750px', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '15px',
            }}
          >
            <Typography>{formattedDate}</Typography>
            <Typography variant="h2">{formattedTime}</Typography>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box
              sx={{
                margin: '20px',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <CardActionArea onClick={() => registStamp(STAMP_TYPE.CLOCK_IN)}>
                <Paper
                  sx={{
                    width: '100%',
                    height: '150px',
                    backgroundColor: '#26a69a',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    color="white"
                  >
                    出勤
                  </Typography>
                </Paper>
              </CardActionArea>
              <Chip
                sx={{ marginTop: '10px', fontSize: '16px' }}
                label={
                  clockInStamp.length > 0 ? (
                    <Link
                      onClick={(e) =>
                        handleClick(
                          e,
                          clockInStamp.at(-1).latitude,
                          clockInStamp.at(-1).longitude
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      {ExtractTimeFromDatetimeString(
                        clockInStamp.at(-1).stampDate
                      )}
                    </Link>
                  ) : (
                    '--:--'
                  )
                }
              />
            </Box>

            <Box
              sx={{
                margin: '20px',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <CardActionArea
                onClick={() => registStamp(STAMP_TYPE.BREAK_START)}
              >
                <Paper
                  sx={{
                    width: '100%',
                    height: '150px',
                    backgroundColor: '#ffb74d',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    color="white"
                  >
                    休憩
                  </Typography>
                </Paper>
              </CardActionArea>
              <Chip
                sx={{ marginTop: '10px', fontSize: '16px' }}
                label={
                  breakStartStamp.length > 0 ? (
                    <Link
                      onClick={(e) =>
                        handleClick(
                          e,
                          clockInStamp.at(-1).latitude,
                          clockInStamp.at(-1).longitude
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      {ExtractTimeFromDatetimeString(
                        breakStartStamp.at(-1).stampDate
                      )}
                    </Link>
                  ) : (
                    '--:--'
                  )
                }
              />
            </Box>
            <Box
              sx={{
                margin: '20px',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <CardActionArea onClick={() => registStamp(STAMP_TYPE.BREAK_END)}>
                <Paper
                  sx={{
                    width: '100%',
                    height: '150px',
                    backgroundColor: '#ff9800',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    color="white"
                  >
                    戻り
                  </Typography>
                </Paper>
              </CardActionArea>
              <Chip
                sx={{ marginTop: '10px', fontSize: '16px' }}
                label={
                  breakEndStamp.length > 0 ? (
                    <Link
                      onClick={(e) =>
                        handleClick(
                          e,
                          clockInStamp.at(-1).latitude,
                          clockInStamp.at(-1).longitude
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      {ExtractTimeFromDatetimeString(
                        breakEndStamp.at(-1).stampDate
                      )}
                    </Link>
                  ) : (
                    '--:--'
                  )
                }
              />
            </Box>
            <Box
              sx={{
                margin: '20px',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <CardActionArea onClick={() => registStamp(STAMP_TYPE.CLOCK_OUT)}>
                <Paper
                  sx={{
                    width: '100%',
                    height: '150px',
                    backgroundColor: '#03a9f4',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    color="white"
                  >
                    退勤
                  </Typography>
                </Paper>
              </CardActionArea>
              <Chip
                sx={{ marginTop: '10px', fontSize: '16px' }}
                label={
                  clockOutStamp.length > 0 ? (
                    <Link
                      onClick={(e) =>
                        handleClick(
                          e,
                          clockInStamp.at(-1).latitude,
                          clockInStamp.at(-1).longitude
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      {ExtractTimeFromDatetimeString(
                        clockOutStamp.at(-1).stampDate
                      )}
                    </Link>
                  ) : (
                    '--:--'
                  )
                }
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  const displayWeatherArea = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Paper elevation={3} sx={{ maxWidth: '750px', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
          >
            <Typography variant="h5">{'今日の天気'}</Typography>
            {isLoadingWeather ? (
              <CircularProgress />
            ) : weatherInfo !== null ? (
              <React.Fragment>
                <img
                  width="100px"
                  src={weatherInfo.forecast.forecastday[0].day.condition.icon}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ marginRight: '10px' }}>
                    {weatherInfo.forecast.forecastday[0].day.condition.text}
                  </Typography>
                  <WaterDropOutlinedIcon />
                  <Typography>
                    {weatherInfo.forecast.forecastday[0].day.totalprecip_mm +
                      'mm'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="h6"
                    color="#e53935"
                    sx={{ marginRight: '10px' }}
                  >
                    {weatherInfo.forecast.forecastday[0].day.maxtemp_c + '°C'}
                  </Typography>
                  <Typography color="#1976d2" variant="h6">
                    {weatherInfo.forecast.forecastday[0].day.mintemp_c + '°C'}
                  </Typography>
                </Box>
                {weatherInfo.alerts.alert.length === 0 ? (
                  <Box>
                    <Typography variant="h6">
                      {'本日の気象警報はありません。'}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="h6"
                      color="#e53935"
                      sx={{ marginRight: '10px' }}
                    >
                      {weatherInfo.alerts.alert.length}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderTop: '1px gray solid',
                    borderBottom: '1px gray solid',
                    marginTop: '10px',
                  }}
                >
                  <Box sx={{ padding: '10px', display: 'flex' }}>
                    {weatherPerThreeHour.map((hour, i) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '5px',
                        }}
                      >
                        <Box>
                          <Typography>{6 + i * 3 + '時'}</Typography>
                        </Box>
                        <Box sx={{ marginTop: '3px' }}>
                          <img src={hour.condition.icon} />
                        </Box>
                        <Box sx={{ marginTop: '3px' }}>
                          <Typography>{hour.temp_c + '°C'}</Typography>
                        </Box>
                        <Box sx={{ marginTop: '3px' }}>
                          <Typography>{hour.precip_mm + 'mm'}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </React.Fragment>
            ) : (
              <Typography>位置情報の取得が許可されていません。</Typography>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  const displayNotifications = () => {
    return (
      <Box sx={{ flex: 2 }}>
        <Paper elevation={3} sx={{ maxWidth: '750px', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '3px',
            }}
          >
            <span className="square" />
            <Typography variant="h6" sx={{ paddingTop: '5px' }}>
              メッセージボックス
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {notifications.map((notification) => {
              const notificationDesign = DisplayMessageChip(notification);
              return (
                <CardActionArea>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px',
                      marginLeft: '20px',
                    }}
                  >
                    <Box sx={{ minWidth: '130px' }}>
                      <Typography>
                        {formatDateToYYYYMMDD(notification.date)}
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: '90px' }}>
                      <Chip
                        label={notificationDesign.label}
                        color={notificationDesign.color}
                      />
                    </Box>
                    <Box>
                      <Link sx={{ cursor: 'pointer' }}>
                        {notification.title}
                      </Link>
                    </Box>
                    <div style={{ flexGrow: 1 }} />
                    <KeyboardArrowRightOutlinedIcon />
                  </Box>
                  <Divider />
                </CardActionArea>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }} />
            <Link
              sx={{ cursor: 'pointer', marginRight: '10px', marginTop: '5px' }}
            >
              メッセージをすべて見る
            </Link>
          </Box>
        </Paper>
      </Box>
    );
  };

  const displaySchedule = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Paper elevation={3} sx={{ height: '100%', marginRight: '0px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '3px',
            }}
          >
            <span className="square" />
            <Typography variant="h6" sx={{ paddingTop: '5px' }}>
              今日のスケジュール
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {schedules.map((schedule) => {
              const notificationDesign = DisplayScheduleChip(schedule);
              return (
                <CardActionArea>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px',
                      marginLeft: '20px',
                    }}
                  >
                    <Box sx={{ minWidth: '120px' }}>
                      <Chip
                        label={notificationDesign.label}
                        color={notificationDesign.color}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '15px' }}>
                        {schedule.title}
                      </Typography>
                      <Typography sx={{ color: '#757575', fontSize: '13px' }}>
                        {formatTime(schedule.startDate) +
                          '～' +
                          formatTime(schedule.endDate)}
                      </Typography>
                    </Box>
                    <div style={{ flexGrow: 1 }} />
                    <KeyboardArrowRightOutlinedIcon />
                  </Box>
                  <Divider />
                </CardActionArea>
              );
            })}
          </Box>
        </Paper>
      </Box>
    );
  };

  const displayPaidLeave = () => {
    return (
      <Box sx={{ flex: 1, marginLeft: '15px' }}>
        <Paper elevation={3} sx={{ height: '100%', marginRight: '0px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '3px',
            }}
          >
            <span className="square" />
            <Typography variant="h6" sx={{ paddingTop: '5px' }}>
              残有給
            </Typography>
          </Box>
          <PieChart
            series={[
              {
                data: paidLeave,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: 'gray',
                },
              },
            ]}
            width={400}
            height={200}
          />
          <Typography sx={{ marginLeft: '20px', margintop: '20px' }}>
            ※「取得済み」には「取得予定」の有給数は含みません。
          </Typography>
        </Paper>
      </Box>
    );
  };

  return (
    <div className="pchome">
      <Typography>ホーム</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '93%',
          width: '93%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            margin: '10px',
          }}
        >
          {displayStampArea()}
          {displayWeatherArea()}
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, margin: '10px' }}>
          {displayNotifications()}
          {displaySchedule()}
          {displayPaidLeave()}
        </Box>
      </Box>
    </div>
  );
}

export default Home;

import { Button, ButtonGroup, Paper, Typography } from '@mui/material';
import './header.css';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_ID } from '../../../Util/define';
import logo from './../../../image/logo.PNG';
import { loginInfo, toastState } from '../../../Util/recoil/atom';
import { useSetRecoilState } from 'recoil';

function Header() {
  // recoil
  const _setLoginInfo = useSetRecoilState(loginInfo);
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    _setLoginInfo({ companyId: '', loginUserName: '', loginUserFullName: '' });
    _setToastState({ isOpen: true, context: 'ログアウトしました' });
    navigate('/');
  };

  if (
    location.pathname !== '/' &&
    location.pathname !== '/' + PAGE_ID.REGIST &&
    location.pathname !== '/' + PAGE_ID.REGIST_COMPANY &&
    location.pathname !== '/' + PAGE_ID.REGIST_USER
  ) {
    return (
      <div className="pcHeader">
        <div
          style={{
            display: 'inline-block',
            width: '30%',
            textAlign: 'left',
            marginTop: '3px',
          }}
        >
          <img src={logo} height="60px" />
        </div>

        <div
          style={{
            display: 'inline-block',
            width: '70%',
            textAlign: 'right',
          }}
        >
          <ButtonGroup
            size="large"
            variant="text"
            aria-label="Loading button group"
            sx={{ marginRight: '15px' }}
          >
            <Button startIcon={<HelpCenterIcon />}>ヘルプ</Button>
            <Button
              onClick={() => navigate('/' + PAGE_ID.SETTINGS)}
              startIcon={<SettingsIcon />}
            >
              設定
            </Button>
            <Button onClick={logout} startIcon={<LogoutIcon />}>
              ログアウト
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Header;

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableBody,
  IconButton,
  Tooltip,
} from '@mui/material';
import './workflowSettings.css';
import { APPROVE_KIND } from '../../../Util/define';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupEditDialog from './groupEditDialog';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  FindApproverGroupsByCompanyId,
  FindUsersByCompanyId,
  FindWorkflowsByCompanyId,
} from '../../../Util/web_api';
import { Group } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WorkflowEditDialog from './workflowEditDialog';
import { DisplayApproveKindName } from '../../../Util/function';

function WorkflowSettings() {
  const [workflows, setWorkflows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isOpenWorkflowDialog, setIsOpenWorkflowDialog] = useState(false);
  const [isOpenGroupDialog, setIsOpenGroupEditDialog] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [editWorkflow, setEditWorkflow] = useState([]);
  const [editGroup, setEditGroup] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const FindEmployees = () => {
    FindUsersByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setEmployees(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindWorkflow = () => {
    FindWorkflowsByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setWorkflows(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindApproverGroups = () => {
    FindApproverGroupsByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setGroups(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    FindEmployees();
    FindWorkflow();
    FindApproverGroups();
  }, []);

  useEffect(() => {
    if (updateFlag) {
      FindApproverGroups();
      FindWorkflow();
      setUpdateFlag(false);
    }
  }, [updateFlag]);

  // ワークフローを編集
  const handleEditWorkflow = (workflow) => {
    setEditWorkflow(workflow);
    setIsOpenWorkflowDialog(true);
    setIsInit(false);
  };

  // 承認者グループを編集
  const handleEditGroup = (group) => {
    setEditGroup(group);
    setIsOpenGroupEditDialog(true);
    setIsInit(false);
  };

  // ワークフローのテーブルを表示する
  const displayWorkflowTable = () => {
    return (
      <TableContainer
        sx={{ maxWidth: '800px', marginTop: '10px' }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '30px', color: '#eceff1' }}
              >
                <Checkbox
                  sx={{
                    color: '#eceff1',
                    '&.Mui-checked': {
                      color: '#eceff1',
                    },
                  }}
                />
              </TableCell>
              <TableCell
                padding="nomal"
                sx={{ width: '250px', color: '#eceff1' }}
              >
                ワークフロー名
              </TableCell>
              <TableCell align="left" sx={{ width: '150px', color: '#eceff1' }}>
                承認種別
              </TableCell>
              <TableCell align="left" sx={{ color: '#eceff1', width: '60px' }}>
                使用する
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: '#eceff1', width: '100px' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          {workflows.map((flow, i) => {
            return (
              <TableBody key={flow.workflowId}>
                <TableRow
                  sx={{
                    backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                  }}
                >
                  <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                    <Checkbox
                      sx={{
                        color: '#263238',
                        '&.Mui-checked': {
                          color: '#263238',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>{flow.workflowName}</TableCell>
                  <TableCell align="left">
                    {DisplayApproveKindName(flow.approveKind)}
                  </TableCell>
                  <TableCell align="center">
                    {flow.isUse ? <CheckCircleIcon /> : ''}
                  </TableCell>
                  <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="編集">
                        <IconButton onClick={() => handleEditWorkflow(flow)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除">
                        <IconButton sx={{ color: '#f44336' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    );
  };

  const displayGroupTable = () => {
    return (
      <TableContainer
        sx={{ maxWidth: '800px', marginTop: '10px' }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '30px', color: '#eceff1' }}
              >
                <Checkbox
                  sx={{
                    color: '#eceff1',
                    '&.Mui-checked': {
                      color: '#eceff1',
                    },
                  }}
                />
              </TableCell>
              <TableCell
                padding="nomal"
                sx={{ width: '250px', color: '#eceff1' }}
              >
                グループ名
              </TableCell>
              <TableCell align="left" sx={{ width: '150px', color: '#eceff1' }}>
                人数
              </TableCell>
              <TableCell align="left" sx={{ color: '#eceff1', width: '60px' }}>
                使用する
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: '#eceff1', width: '100px' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          {groups.map((group, i) => {
            return (
              <TableBody key={group.approverGroupId}>
                <TableRow
                  sx={{
                    backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                  }}
                >
                  <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                    <Checkbox
                      sx={{
                        color: '#263238',
                        '&.Mui-checked': {
                          color: '#263238',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>{group.approverGroupName}</TableCell>
                  <TableCell align="left">
                    {group.approverGroupAffilations !== null
                      ? group.approverGroupAffilations.length
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {group.isUse ? <CheckCircleIcon /> : ''}
                  </TableCell>
                  <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="編集">
                        <IconButton onClick={() => handleEditGroup(group)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除">
                        <IconButton sx={{ color: '#f44336' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className="pcWorkflowSettings">
      <WorkflowEditDialog
        companyId={_loginInfo.companyId}
        allEmployees={employees}
        workflow={editWorkflow}
        allApproverGroup={groups}
        isOpen={isOpenWorkflowDialog}
        setIsOpen={setIsOpenWorkflowDialog}
        isInit={isInit}
        setIsInit={setIsInit}
        setUpdateFlag={setUpdateFlag}
      />
      <GroupEditDialog
        companyId={_loginInfo.companyId}
        allEmployees={employees}
        group={editGroup}
        isOpen={isOpenGroupDialog}
        setIsOpen={setIsOpenGroupEditDialog}
        isInit={isInit}
        setIsInit={setIsInit}
        setUpdateFlag={setUpdateFlag}
      />
      <Typography>承認フロー設定</Typography>
      <div style={{ width: '800px', marginLeft: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography>ワークフロー</Typography>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsOpenWorkflowDialog(true);
              setIsInit(true);
            }}
          >
            新規作成
          </Button>
          <Button
            sx={{ marginLeft: '10px' }}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            まとめて削除
          </Button>
        </div>
        {displayWorkflowTable()}
      </div>
      <div style={{ width: '800px', marginLeft: '10px', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography>承認者グループ</Typography>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsOpenGroupEditDialog(true);
              setIsInit(true);
            }}
          >
            新規作成
          </Button>
          <Button
            sx={{ marginLeft: '10px' }}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            まとめて削除
          </Button>
        </div>
        {displayGroupTable()}
      </div>
    </div>
  );
}

export default WorkflowSettings;

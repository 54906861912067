import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CONTROL, STEP } from '../define';
import { TypographyControl } from '../dailyReportComponent/PC/typography';
import { Icon } from '../dailyReportComponent/PC/icon';
import { Image } from '../dailyReportComponent/PC/image';
import { CheckBox } from '../dailyReportComponent/PC/checkBox';
import { ComboBox } from '../dailyReportComponent/PC/comboBox';
import { RadioControl } from '../dailyReportComponent/PC/radio';
import { TextFieldText } from '../dailyReportComponent/PC/textFieldText';
import { TextFieldNum } from '../dailyReportComponent/PC/textFieldNum';
import { Calender } from '../dailyReportComponent/PC/calender';
import { Weather } from '../dailyReportComponent/PC/weather';
import { Photo } from '../dailyReportComponent/PC/photo';
import { Table } from '../dailyReportComponent/PC/table';
import { TotalValueControl } from '../dailyReportComponent/PC/totalValue';
import { MonthlyTextFieldNum } from '../dailyReportComponent/PC/inputGroup/monthly/monthlyTextFieldNum';
import { Time } from '../dailyReportComponent/PC/time';
import { FixedTextField } from '../dailyReportComponent/PC/inputGroup/fixed/fixedTextField';
import { FixedTextFieldNum } from '../dailyReportComponent/PC/inputGroup/fixed/fixedTextFieldNum';
import { FixedTime } from '../dailyReportComponent/PC/inputGroup/fixed/fixedTime';
import zIndex from '@mui/material/styles/zIndex';
import dayjs from 'dayjs';

function DailyReportShow(props) {
  const { dailyReport, scalingFactor, isPcView, dailyReportValues, date } =
    props;

  const componentValue = (id, type) => {
    let temp = dailyReportValues.find((obj) => obj.componentId === id);
    if (temp === undefined) {
      return;
    }
    temp = temp.value;
    if (type !== null && type !== undefined && type === 'time') {
      const today = new Date();
      const todayString = today.toISOString().split('T')[0];
      // 時間文字列と今日の日付を組み合わせて新しい Date オブジェクトを作成
      const dateTimeString = `${todayString}T${temp}`;
      const dateObject = new Date(dateTimeString);
      // Date オブジェクトを Day.js オブジェクトに変換
      temp = dayjs(dateObject);
    }
    return temp;
  };

  const calcTotalValue = (component) => {
    // 計算対象を取得する
    const calcTargets = component.calcTargets.map(
      (target) => target.componentId
    );
    // 計算対象の値を取得する
    const targetValues = dailyReportValues
      .filter((valueSet) => calcTargets.includes(valueSet.id))
      .map((valueSet) => valueSet.value);
    return targetValues.reduce((sum, current) => {
      if (current !== null && current !== undefined) {
        return sum + Number(current);
      }
      return sum;
    }, 0);
  };
  const showComponent = (component) => {
    if (isPcView) {
      switch (component.componentType) {
        case CONTROL.TYPOGRAPHY:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <TypographyControl
                component={component}
                scalingFactor={scalingFactor}
              />
            </Box>
          );
        case CONTROL.ICON:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <Icon component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.IMAGE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Image component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.CHECKBOX:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <CheckBox
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.COMBOBOX:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <ComboBox
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.RADIO:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <RadioControl
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.TEXTFIELD.TEXT:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <TextFieldText
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.TEXTFIELD.NUM:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <TextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.CALENDER:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <Calender
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.WEATHER:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <Weather
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.PHOTO:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Photo
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
              />
            </Box>
          );
        case CONTROL.TIME:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Time
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId, 'time')}
              />
            </Box>
          );
        case CONTROL.TABLE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
                height: component.height * (30 * scalingFactor),
              }}
            >
              <Table component={component} scalingFactor={scalingFactor} />
            </Box>
          );
        case CONTROL.TOTAL_VALUE:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
              }}
            >
              <TotalValueControl
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={calcTotalValue(component)}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTextField
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <MonthlyTextFieldNum
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                date={date}
              />
            </Box>
          );
        case CONTROL.INPUT_GROUP.TIME.FIXED:
          return (
            <Box
              sx={{
                position: 'absolute',
                top: component.y * (30 * scalingFactor),
                left: component.x * (30 * scalingFactor),
                width: component.width * (30 * scalingFactor),
              }}
            >
              <FixedTime
                component={component}
                scalingFactor={scalingFactor}
                isEdit={true}
                value={componentValue(component.componentId)}
                date={date}
              />
            </Box>
          );
        default:
          break;
      }
    } else {
      switch (component.componentType) {
        case CONTROL.TYPOGRAPHY:
          return (
            <TypographyControl
              component={component}
              scalingFactor={scalingFactor}
            />
          );
        case CONTROL.ICON:
          return <Icon component={component} scalingFactor={scalingFactor} />;
        case CONTROL.IMAGE:
          return <Image component={component} scalingFactor={scalingFactor} />;
        case CONTROL.CHECKBOX:
          return (
            <CheckBox
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.COMBOBOX:
          return (
            <ComboBox
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.RADIO:
          return (
            <RadioControl
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.TEXTFIELD.TEXT:
          return (
            <TextFieldText
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.TEXTFIELD.NUM:
          return (
            <TextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.CALENDER:
          return (
            <Calender
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.WEATHER:
          return (
            <Weather
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.PHOTO:
          return (
            <Photo
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.TIME:
          return (
            <Time
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId, 'time')}
            />
          );
        case CONTROL.TABLE:
          return <Table component={component} scalingFactor={scalingFactor} />;
        case CONTROL.TOTAL_VALUE:
          return (
            <TotalValueControl
              component={component}
              scalingFactor={scalingFactor}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
          return (
            <FixedTextField
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
          return (
            <FixedTextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
          return (
            <MonthlyTextFieldNum
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
              date={date}
            />
          );
        case CONTROL.INPUT_GROUP.TIME.FIXED:
          return (
            <FixedTime
              component={component}
              scalingFactor={scalingFactor}
              isEdit={true}
              value={componentValue(component.componentId)}
            />
          );
        default:
          break;
      }
    }
  };

  return (
    <div style={{ width: 'auto' }}>
      <div style={{ display: 'flex', marginBottom: '50px' }}>
        <Box position="relative">
          <Paper
            elevation={3}
            style={{
              marginLeft: '20px',
              padding: !isPcView ? 20 : 10 * scalingFactor + 'mm',
            }}
          >
            {isPcView ? (
              <Box
                style={{
                  width: 400 * scalingFactor + 'mm',
                  height: 277 * scalingFactor + 'mm',
                  border: '1px solid black',
                  position: 'relative',
                }}
              >
                {dailyReport.map((component) => showComponent(component))}
              </Box>
            ) : (
              <React.Fragment>
                {dailyReport.map((component) => (
                  <Box
                    style={{
                      width:
                        component.componentType === CONTROL.IMAGE
                          ? component.width * (30 * scalingFactor)
                          : 'auto',
                      maxWidth: '400px',
                      height:
                        component.componentType === CONTROL.IMAGE
                          ? component.height * (30 * scalingFactor)
                          : 'auto',
                      marginBottom: '8px',
                    }}
                  >
                    {showComponent(component)}
                  </Box>
                ))}
              </React.Fragment>
            )}
          </Paper>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            style={{
              backgroundColor: 'transparent',
              zIndex: 100,
            }}
          />
        </Box>
      </div>
    </div>
  );
}

export default DailyReportShow;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Padding } from '@mui/icons-material';
import './style.css';

function Table(props) {
  return <Box className="designBorder" />;
}

export { Table };

import React, { useEffect, useState } from 'react';
import {
  Card,
  Chip,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import { CheckBox } from '@mui/icons-material';
import { RegistApproverGroup } from '../../../Util/web_api';

function GroupEditDialog(props) {
  const {
    companyId,
    allEmployees,
    group,
    isOpen,
    setIsOpen,
    isInit,
    setIsInit,
    setUpdateFlag,
  } = props;

  const [candidate, setCandidate] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isUse, setIsUse] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [errors, setErrors] = useState({});

  const handleRegister = () => {
    // フォームのバリデーション
    const errors = {};
    if (!groupName.trim()) {
      errors.groupName = 'グループ名を入力してください';
    }
    if (!groupId.trim()) {
      errors.groupId = 'グループIDを入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションに問題なければ登録処理
    const poster = {
      PrevApproverGroupId: isInit ? groupId : group.approverGroupId,
      ApproverGroupId: groupId,
      CompanyId: companyId,
      ApproverGroupName: groupName,
      IsUse: isUse,
      UserName: employees.map((emp) => emp.userName),
    };

    RegistApproverGroup(
      poster,
      (res) => {
        setIsOpen(false);
        setIsInit(false);
        setUpdateFlag(true);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      if (isInit) {
        setCandidate(allEmployees);
        setIsUse(true);
        setGroupName('');
        setGroupId('');
        setEmployees([]);
        setErrors({});
      } else {
        setCandidate(
          allEmployees.filter(
            (a) =>
              !group.approverGroupAffilations
                .map((affilation) => affilation.user)
                .some((o) => o.userName === a.userName)
          )
        );
        setIsUse(group.isUse);
        setGroupName(group.approverGroupName);
        setGroupId(group.approverGroupId);
        setEmployees(
          group.approverGroupAffilations.map((affilation) => affilation.user)
        );
        setErrors({});
      }
    }
  }, [isOpen, group]);

  const handleAdd = (object) => {
    setCandidate((candidates) => {
      return candidates.filter((c) => c.userName !== object.userName);
    });
    setEmployees((objects) => {
      // 新規
      return [...objects, object];
    });
  };

  const displayName = (userName) => {
    const temp = allEmployees.find((emp) => emp.userName === userName);
    if (temp === null || temp === undefined) {
      return '不明なユーザー';
    } else {
      return temp.lastName + ' ' + temp.firstName;
    }
  };

  const content = () => {
    return (
      <div style={{ marginLeft: '10px' }}>
        <FormControlLabel
          control={<Switch />}
          label="使用する"
          checked={isUse}
          onChange={() => setIsUse(!isUse)}
        />
        <div>
          <TextField
            label="承認者グループ名"
            variant="outlined"
            margin="normal"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            error={!!errors.groupName}
            helperText={errors.groupName}
            required
            style={{ width: '215px' }}
          />
          <TextField
            label="承認者グループID"
            variant="outlined"
            margin="normal"
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
            error={!!errors.groupId}
            helperText={errors.groupId}
            required
            style={{ width: '215px', marginLeft: '20px' }}
          />
        </div>
        <div style={{ display: 'flex', height: '400px', marginTop: '20px' }}>
          <Paper
            sx={{
              width: '300px',
              borderRadius: '4px',

              flexGrow: 1,
            }}
            elevation={3} // 影のレベルを指定
          >
            <ListSubheader
              sx={{
                backgroundColor: '#263238',
                color: '#eceff1',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                display: 'flex',
              }}
            >
              社員
            </ListSubheader>
            <List sx={{ overflowY: 'auto', height: '333px' }}>
              {candidate.length !== 0
                ? candidate.map((c) => (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleAdd(c)}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={displayName(c.userName)} />
                    </ListItem>
                  ))
                : null}
            </List>
          </Paper>
          <Card
            variant="outlined"
            style={{
              width: '300px',
              borderRadius: '4px',
              marginLeft: '20px',
              flexGrow: 1,
              padding: '15px',
            }}
          >
            <Typography>承認者</Typography>
            <div style={{ marginTop: '10px' }}>
              {employees.length !== 0 ? (
                employees.map((c) => (
                  <Chip
                    style={{ marginRight: '3px', marginBottom: '5px' }}
                    label={displayName(c.userName)}
                    onDelete={() => {
                      setCandidate((candidates) => {
                        return [...candidates, c];
                      });
                      setEmployees((obj) => {
                        return obj.filter((o) => o.userName !== c.userName);
                      });
                    }}
                  />
                ))
              ) : (
                <Typography>承認者がいません</Typography>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpen}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpen(false);
        setIsInit(false);
      }}
      yesText={'変更を適応'}
      noText={'キャンセル'}
      title={'承認者グループ編集'}
      content={content()}
    />
  );
}
export default GroupEditDialog;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja';

function Calender(props) {
  const dayOfWeekFormatter = (date, locale) => {
    const dayOfWeek = new Intl.DateTimeFormat(locale, {
      weekday: 'short',
    }).format(date);
    return dayOfWeek;
  };
  if (props.isEdit) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
        <DatePicker
          slotProps={{
            calendarHeader: {
              format: 'YYYY年MM月', // カレンダーの年月の部分
            },
          }}
          dayOfWeekFormatter={dayOfWeekFormatter}
          format="YYYY/MM/DD" // 入力欄
          value={props.value}
          onChange={(date) => props.setValue(props.component.componentId, date)}
          style={{ width: '100%', height: '100%' }}
          label={props.component.isShowLabel ? props.component.text : ''}
          sx={{
            backgroundColor: 'white',
            '& .MuiInputBase-root': {
              width: '100%',
              height: '100%', // 変更したい高さ
            },
            '& .MuiInputBase-input': {
              fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
            },
          }}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
        <DatePicker
          slotProps={{
            calendarHeader: {
              format: 'YYYY年MM月', // カレンダーの年月の部分
            },
          }}
          dayOfWeekFormatter={dayOfWeekFormatter}
          format="YYYY/MM/DD" // 入力欄
          style={{ width: '100%', height: '1000px' }}
          label={props.component.isShowLabel ? props.component.text : ''}
          sx={{
            backgroundColor: 'white',
            '& .MuiInputBase-root': {
              width: '100%',
              height: '100%', // 変更したい高さ
            },
            '& .MuiInputBase-input': {
              fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
            },
          }}
        />
      </LocalizationProvider>
    );
  }
}

export { Calender };

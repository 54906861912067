import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja';

function Time(props) {
  if (props.isEdit) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
        <TimeField
          value={props.value}
          onChange={(newTime) =>
            props.setValue(props.component.componentId, newTime)
          }
          style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
          label={props.component.isShowLabel ? props.component.text : ''}
          format="HH:mm"
          defaultValue={dayjs()}
          sx={{
            '& .MuiInputBase-root': {
              width: '100%',
              height: '100%', // 変更したい高さ
            },
            '& .MuiInputBase-input': {
              fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
            },
          }}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
        <TimeField
          style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
          label={props.component.isShowLabel ? props.component.text : ''}
          format="HH:mm"
          defaultValue={dayjs()}
          sx={{
            '& .MuiInputBase-root': {
              width: '100%',
              height: '100%', // 変更したい高さ
            },
            '& .MuiInputBase-input': {
              fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
            },
          }}
        />
      </LocalizationProvider>
    );
  }
}

export { Time };

import { Badge, Box, Button, Collapse, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ApprovalIcon from '@mui/icons-material/Approval';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FoundationIcon from '@mui/icons-material/Foundation';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import HouseboatOutlinedIcon from '@mui/icons-material/HouseboatOutlined';
import './sideMenu.css';
import { useEffect, useState } from 'react';
import { PAGE_ID } from '../../../Util/define';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  loginInfo,
  loginUserFullName,
  loginUserName,
  toastState,
} from '../../../Util/recoil/atom';

function SideMenu() {
  const [openGeneralMenu, setOpenGeneralMenu] = useState(false);
  const [openChargerMenu, setOpenChargerMenu] = useState(false);
  const [openManageMenu, setOpenManageMenu] = useState(false);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (_loginInfo.companyId === '') {
      navigate('/');
    }
  }, []);

  if (
    location.pathname !== '/' &&
    location.pathname !== '/' + PAGE_ID.REGIST &&
    location.pathname !== '/' + PAGE_ID.REGIST_COMPANY &&
    location.pathname !== '/' + PAGE_ID.REGIST_USER
  ) {
    return (
      <div className="pcSideMenu">
        <Button
          className="fullWidth"
          color={
            location.pathname === '/' + PAGE_ID.ACCOUNT ? 'info' : 'inherit'
          }
          style={{
            justifyContent: 'flex-start',
            marginLeft: '2%',
          }}
          sx={{
            backgroundColor:
              location.pathname === '/' + PAGE_ID.ACCOUNT && '#e3f2fd',
            '&:hover': {
              backgroundColor:
                location.pathname === '/' + PAGE_ID.ACCOUNT && '#bbdefb',
            },
          }}
          startIcon={<AccountCircleIcon />}
          onClick={() => navigate('/' + PAGE_ID.ACCOUNT)}
        >
          {_loginInfo.loginUserFullName}
        </Button>
        <Button
          className="fullWidth"
          color={location.pathname === '/' + PAGE_ID.HOME ? 'info' : 'inherit'}
          style={{
            justifyContent: 'flex-start',
            marginLeft: '2%',
          }}
          sx={{
            backgroundColor:
              location.pathname === '/' + PAGE_ID.HOME && '#e3f2fd',
            '&:hover': {
              backgroundColor:
                location.pathname === '/' + PAGE_ID.HOME && '#bbdefb',
            },
          }}
          startIcon={<HomeIcon />}
          onClick={() => navigate('/' + PAGE_ID.HOME)}
        >
          ホーム
        </Button>
        <Button
          className="fullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={
            openGeneralMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />
          }
          onClick={() => setOpenGeneralMenu(!openGeneralMenu)}
        >
          一般メニュー
        </Button>
        <Collapse in={openGeneralMenu} timeout="auto">
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.SCHEDULE ? 'info' : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.SCHEDULE && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.SCHEDULE && '#bbdefb',
              },
            }}
            startIcon={<ScheduleOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.SCHEDULE)}
          >
            スケジュール
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.STAMP_HISTORY
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.STAMP_HISTORY && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.STAMP_HISTORY &&
                  '#bbdefb',
              },
            }}
            startIcon={<HistoryOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.STAMP_HISTORY)}
          >
            打刻履歴
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.PAID_LEAVE_CHECK
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.PAID_LEAVE_CHECK &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.PAID_LEAVE_CHECK &&
                  '#bbdefb',
              },
            }}
            startIcon={<FreeBreakfastOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.PAID_LEAVE_CHECK)}
          >
            有給確認
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.CREATE_DAILY_REPORT
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.CREATE_DAILY_REPORT &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.CREATE_DAILY_REPORT &&
                  '#bbdefb',
              },
            }}
            startIcon={<EditNoteIcon />}
            onClick={() => navigate('/' + PAGE_ID.CREATE_DAILY_REPORT)}
          >
            日報作成
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.EQUIPMENT_RESERVATION
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.EQUIPMENT_RESERVATION &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.EQUIPMENT_RESERVATION &&
                  '#bbdefb',
              },
            }}
            startIcon={<ConstructionOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.EQUIPMENT_RESERVATION)}
          >
            備品予約
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.APPLICATION
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.APPLICATION && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.APPLICATION && '#bbdefb',
              },
            }}
            startIcon={<DescriptionOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.APPLICATION)}
          >
            申請
          </Button>
        </Collapse>
        <Button
          className="fullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={
            openChargerMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />
          }
          onClick={() => setOpenChargerMenu(!openChargerMenu)}
        >
          担当者メニュー
        </Button>
        <Collapse in={openChargerMenu} timeout="auto">
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.STAMP_MANAGE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              paddingRight: '30px',
              backgroundColor:
                location.pathname === '/' + PAGE_ID.STAMP_MANAGE && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.STAMP_MANAGE && '#bbdefb',
              },
            }}
            startIcon={<ManageSearchOutlinedIcon />}
            endIcon={<Badge badgeContent={0} color="primary" />}
            onClick={() => navigate('/' + PAGE_ID.STAMP_MANAGE)}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>打刻管理</Box>
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.PAID_LEAVE_MANAGE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              paddingRight: '30px',
              backgroundColor:
                location.pathname === '/' + PAGE_ID.PAID_LEAVE_MANAGE &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.PAID_LEAVE_MANAGE &&
                  '#bbdefb',
              },
            }}
            startIcon={<HouseboatOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.PAID_LEAVE_MANAGE)}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>有給管理</Box>
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.EQUIPMENT_MANAGE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              paddingRight: '30px',
              backgroundColor:
                location.pathname === '/' + PAGE_ID.EQUIPMENT_MANAGE &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.EQUIPMENT_MANAGE &&
                  '#bbdefb',
              },
            }}
            startIcon={<ConstructionOutlinedIcon />}
            endIcon={<Badge badgeContent={0} color="primary" />}
            onClick={() => navigate('/' + PAGE_ID.EQUIPMENT_MANAGE)}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>備品管理</Box>
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.APPLICATION_APPROVAL
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              paddingRight: '30px',
              backgroundColor:
                location.pathname === '/' + PAGE_ID.APPLICATION_APPROVAL &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.APPLICATION_APPROVAL &&
                  '#bbdefb',
              },
            }}
            startIcon={<TaskOutlinedIcon />}
            endIcon={<Badge badgeContent={0} color="primary" />}
            onClick={() => navigate('/' + PAGE_ID.APPLICATION_APPROVAL)}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>申請承認</Box>
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.APPLICATION_REVIEW
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.APPLICATION_REVIEW &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.APPLICATION_REVIEW &&
                  '#bbdefb',
              },
            }}
            startIcon={<ArticleOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.APPLICATION_REVIEW)}
          >
            申請確認
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.DAILY_REPORT_APPROVAL
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              paddingRight: '30px',
              backgroundColor:
                location.pathname === '/' + PAGE_ID.DAILY_REPORT_APPROVAL &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.DAILY_REPORT_APPROVAL &&
                  '#bbdefb',
              },
            }}
            startIcon={<ApprovalIcon />}
            endIcon={<Badge badgeContent={2} color="primary" />}
            onClick={() => navigate('/' + PAGE_ID.DAILY_REPORT_APPROVAL)}
          >
            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>日報承認</Box>
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.DAILY_REPORT_REVIEW
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.DAILY_REPORT_REVIEW &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.DAILY_REPORT_REVIEW &&
                  '#bbdefb',
              },
            }}
            startIcon={<DescriptionOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.DAILY_REPORT_REVIEW)}
          >
            日報確認
          </Button>
        </Collapse>
        <Button
          className="fullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={openManageMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          onClick={() => setOpenManageMenu(!openManageMenu)}
        >
          管理者メニュー
        </Button>
        <Collapse in={openManageMenu} timeout="auto">
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.COMPANY_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.COMPANY_SETTINGS &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.COMPANY_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<BusinessOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.COMPANY_SETTINGS)}
          >
            会社設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.EMPLOYEE_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.EMPLOYEE_SETTINGS &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.EMPLOYEE_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<PeopleAltOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.EMPLOYEE_SETTINGS)}
          >
            社員設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.DEPARTMENT_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.DEPARTMENT_SETTINGS &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.DEPARTMENT_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<Diversity3Icon />}
            onClick={() => navigate('/' + PAGE_ID.DEPARTMENT_SETTINGS)}
          >
            部門設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.APPLICATION_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.APPLICATION_SETTINGS &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.APPLICATION_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<DescriptionOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.APPLICATION_SETTINGS)}
          >
            申請設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.SITE_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.SITE_SETTINGS && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.SITE_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<FoundationIcon />}
            onClick={() => navigate('/' + PAGE_ID.SITE_SETTINGS)}
          >
            現場設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.WORKFLOW_SETTINGS
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.WORKFLOW_SETTINGS &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.WORKFLOW_SETTINGS &&
                  '#bbdefb',
              },
            }}
            startIcon={<SchemaOutlinedIcon />}
            onClick={() => navigate('/' + PAGE_ID.WORKFLOW_SETTINGS)}
          >
            承認フロー設定
          </Button>
          <Button
            className="fullWidth"
            color={
              location.pathname === '/' + PAGE_ID.DAILY_REPORT_DESIGN
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.DAILY_REPORT_DESIGN &&
                '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.DAILY_REPORT_DESIGN &&
                  '#bbdefb',
              },
            }}
            startIcon={<BorderOuterIcon />}
            onClick={() => navigate('/' + PAGE_ID.DAILY_REPORT_DESIGN)}
          >
            日報設計
          </Button>
        </Collapse>
      </div>
    );
  } else {
    return null;
  }
}

export default SideMenu;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Photo } from '../../../../../Util/dailyReportComponent/PC/photo';

function RndPhoto(props) {
  const [info, setInfo] = useState({
    componentId: props.component.componentId,
    x: props.component.x,
    y: props.component.y,
    width: props.component.width,
    height: props.component.height,
  });

  //Effect********************************************************************************************************************************************************

  useEffect(() => {
    props.setComponentInfos((componentInfo) => {
      return componentInfo.map((ele) =>
        ele.componentId === props.component.componentId
          ? {
              width: info.width,
              height: info.height,
              componentId: props.component.componentId,
              componentType: props.component.componentType,
              text: props.component.text,
              fontSize: props.component.fontSize,
              x: info.x,
              y: info.y,
              iconName: props.component.iconName,
              imagePhoto: props.component.imagePhoto,
              choices: props.component.choices,
              sequence: props.component.sequence,
              defaultValue: props.component.defaultValue,
              totalValue: props.component.totalValue,
              isShowLabel: props.component.isShowLabel,
              xCount: props.component.xCount,
              yCount: props.component.yCount,
              isMultiline: props.component.isMultiline,
            }
          : ele
      );
    });
  }, [info]);

  //*************************************************************************************************************************************************************

  const dragEnd = (x, y) => {
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    setInfo({
      componentId: info.componentId,
      x: Math.floor(x / (30 * props.scalingFactor)),
      y: Math.floor(y / (30 * props.scalingFactor)),
      width: info.width,
      height: info.height,
    });
  };

  const resizeEnd = (width, height) => {
    if (width <= 0) width = 100;
    if (height <= 0) height = 100;
    setInfo({
      componentId: info.componentId,
      x: info.x,
      y: info.y,
      width: Math.floor(width / (30 * props.scalingFactor)),
      height: Math.floor(height / (30 * props.scalingFactor)),
    });
  };

  return (
    <Rnd
      id="parent"
      default={{
        x: info.x * (30 * props.scalingFactor),
        y: info.y * (30 * props.scalingFactor),
        width: info.width * (30 * props.scalingFactor),
        height: info.height * (30 * props.scalingFactor),
      }}
      position={{
        x: info.x * (30 * props.scalingFactor),
        y: info.y * (30 * props.scalingFactor),
      }}
      size={{
        width: props.component.width * (30 * props.scalingFactor),
        height: props.component.height * (30 * props.scalingFactor),
      }}
      onDragStop={(e, data) => {
        e.ctrlKey ? void 0 : dragEnd(data.lastX, data.lastY);
      }}
      onResizeStop={(e, d, ref) => {
        e.ctrlKey ? void 0 : resizeEnd(ref.offsetWidth, ref.offsetHeight);
      }}
      enableResizing={{
        top: false,
        right: true,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      onClick={(e) => {
        if (e.ctrlKey) {
          props.setIsOpenRndDialog(true);
          props.setEditComponentId(info.componentId);
          props.setEditComponent(props.component);
          props.setIsNew(false);
        }
      }}
      style={{ marginLeft: '1px', marginTop: '1px' }}
    >
      <Photo component={props.component} />
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        style={{ cursor: 'move', backgroundColor: 'transparent' }}
      />
    </Rnd>
  );
}

export { RndPhoto };

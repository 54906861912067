import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import { Apple, CheckBox } from '@mui/icons-material';
import { RegistWorkflow } from '../../../Util/web_api';
import { APPROVE_KIND } from '../../../Util/define';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CloseIcon from '@mui/icons-material/Close';

function WorkflowEditDialog(props) {
  const {
    companyId,
    allEmployees,
    workflow,
    allApproverGroup,
    isOpen,
    setIsOpen,
    isInit,
    setIsInit,
    setUpdateFlag,
  } = props;

  const [candidate, setCandidate] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isUse, setIsUse] = useState(false);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowId, setWorkflowId] = useState('');
  const [errors, setErrors] = useState({});
  const [approveKind, setApproveKind] = useState(APPROVE_KIND.ALL);
  const [isUseGroup, setIsUseGroup] = useState(false);
  const [useApproverGroupId, setUseApproverGroupId] = useState([]);
  const [approverStep, setApproverStep] = useState([]);

  const handleRegister = () => {
    // フォームのバリデーション
    const errors = {};
    if (!workflowName.trim()) {
      errors.workflowName = 'グループ名を入力してください';
    }
    if (!workflowId.trim()) {
      errors.workflowId = 'グループIDを入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションに問題なければ登録処理;
    const poster = {
      PrevWorkflowId: isInit ? workflowId : workflow.workflowId,
      WorkflowId: workflowId,
      CompanyId: companyId,
      WorkflowName: workflowName,
      IsUse: isUse,
      ApproveKind: approveKind,
      IsUseGroup: isUseGroup,
      ApproverGroupId: useApproverGroupId,
      UserName: isUseGroup
        ? approverStep.map((emp) => emp.userName)
        : employees.map((emp) => emp.userName),
    };

    console.log(poster);

    RegistWorkflow(
      poster,
      (res) => {
        setIsOpen(false);
        setIsInit(false);
        setUpdateFlag(true);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    if (isOpen) {
      if (isInit) {
        setCandidate(allEmployees);
        setIsUse(true);
        setWorkflowName('');
        setWorkflowId('');
        setEmployees([]);
        setErrors({});
        setApproveKind(APPROVE_KIND.ALL);
        setIsUseGroup(false);
        setUseApproverGroupId('');
        setApproverStep([]);
      } else {
        const temp = workflow.workflowAffilations.map(
          (affilation) => affilation
        );
        const sortedItemsAsc = temp.sort(
          (a, b) => a.approveStep - b.approveStep
        );
        setCandidate(
          allEmployees.filter(
            (a) =>
              !workflow.workflowAffilations
                .map((affilation) => affilation.user)
                .some((o) => o.userName === a.userName)
          )
        );
        setIsUse(workflow.isUse);
        setWorkflowName(workflow.workflowName);
        setWorkflowId(workflow.workflowId);
        setEmployees(sortedItemsAsc.map((affilation) => affilation.user));
        setErrors({});
        setApproveKind(workflow.approveKind);
        setIsUseGroup(workflow.isUseGroup);
        setUseApproverGroupId(
          workflow.approverGroup !== null
            ? workflow.approverGroup.approverGroupId
            : ''
        );
        setApproverStep(sortedItemsAsc.map((affilation) => affilation.user));
      }
    }
  }, [isOpen, workflow]);

  const handleAdd = (object) => {
    setCandidate((candidates) => {
      return candidates.filter((c) => c.userName !== object.userName);
    });
    setEmployees((objects) => {
      // 新規
      return [...objects, object];
    });
  };

  const displayName = (userName) => {
    const temp = allEmployees.find((emp) => emp.userName === userName);
    if (temp === null || temp === undefined) {
      return '不明なユーザー';
    } else {
      return temp.lastName + ' ' + temp.firstName;
    }
  };

  const displayList = () => {
    if (isUseGroup) {
      return (
        <List sx={{ overflowY: 'auto', height: '333px' }}>
          {allApproverGroup.length !== 0
            ? allApproverGroup.map((c) => (
                <ListItem id={c.approverGroupId} disablePadding>
                  <ListItemButton
                    selected={useApproverGroupId === c.approverGroupId}
                    onClick={() => {
                      const selectedGroup = allApproverGroup.find(
                        (g) => g.approverGroupId === c.approverGroupId
                      );
                      setUseApproverGroupId(c.approverGroupId);
                      if (selectedGroup) {
                        console.log(selectedGroup);
                        setApproverStep(
                          selectedGroup.approverGroupAffilations.map(
                            (affilation) => affilation.user
                          )
                        );
                      }
                    }}
                  >
                    <ListItemText primary={c.approverGroupName} />
                  </ListItemButton>
                </ListItem>
              ))
            : null}
        </List>
      );
    } else {
      return (
        <List sx={{ overflowY: 'auto', height: '333px' }}>
          {candidate.length !== 0
            ? candidate.map((c) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleAdd(c)}
                    >
                      <AddIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={displayName(c.userName)} />
                </ListItem>
              ))
            : null}
        </List>
      );
    }
  };

  const displayDraggableApprover = () => {
    const onGroupDragEnd = (result) => {
      if (!result.destination) return;
      const reorderedItems = Array.from(approverStep);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
      setApproverStep(reorderedItems);
    };
    const onEmployeeDragEnd = (result) => {
      if (!result.destination) return;
      const reorderedItems = Array.from(employees);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
      setEmployees(reorderedItems);
    };
    if (isUseGroup) {
      return (
        <DragDropContext onDragEnd={onGroupDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  padding: '5px',
                }}
              >
                {approverStep.length !== 0 &&
                  approverStep.map((approver, index) => {
                    return (
                      <Draggable
                        key={approver.userName}
                        draggableId={approver.userName}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              padding: '5px',
                              marginBottom: '5px',
                              position: 'relative',
                            }}
                            variant="outlined"
                          >
                            <Stack
                              direction="row"
                              sx={{ alignItems: 'center' }}
                            >
                              <DragHandleIcon style={{ color: 'gray' }} />
                              <Box style={{ width: '100%', marginLeft: '3px' }}>
                                {displayName(approver.userName)}
                              </Box>
                            </Stack>
                          </Paper>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else {
      return (
        <DragDropContext onDragEnd={onEmployeeDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  padding: '5px',
                }}
              >
                {employees.length !== 0 &&
                  employees.map((employee, index) => {
                    return (
                      <Draggable
                        key={employee.userName}
                        draggableId={employee.userName}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              padding: '5px',
                              marginBottom: '5px',
                              position: 'relative',
                            }}
                            variant="outlined"
                          >
                            <Stack
                              direction="row"
                              sx={{ alignItems: 'center' }}
                            >
                              <DragHandleIcon style={{ color: 'gray' }} />
                              <Box
                                style={{
                                  width: '100%',
                                  marginLeft: '3px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {displayName(employee.userName)}
                                <div style={{ flexGrow: 1 }} />
                                <IconButton
                                  onClick={() => {
                                    setCandidate((candidates) => {
                                      return [...candidates, employee];
                                    });
                                    setEmployees((obj) => {
                                      return obj.filter(
                                        (o) => o.userName !== employee.userName
                                      );
                                    });
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Stack>
                          </Paper>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  };

  const displayApprover = () => {
    if (isUseGroup) {
      if (useApproverGroupId !== '') {
        const selectedGroup = allApproverGroup.find(
          (g) => g.approverGroupId === useApproverGroupId
        );

        if (!selectedGroup) {
          return <Typography>承認者グループが見つかりません</Typography>;
        }

        if (approveKind === APPROVE_KIND.ALL_STEP) {
          return displayDraggableApprover();
        } else {
          return allApproverGroup
            .find((g) => g.approverGroupId === useApproverGroupId)
            .approverGroupAffilations.map((c) => (
              <Chip
                style={{ marginRight: '3px', marginBottom: '5px' }}
                label={displayName(c.user.userName)}
              />
            ));
        }
      } else {
        return <Typography>承認者グループを選択してください</Typography>;
      }
    } else {
      if (employees.length === 0) {
        return <Typography>承認者を選択してください</Typography>;
      }

      if (approveKind === APPROVE_KIND.ALL_STEP) {
        return displayDraggableApprover();
      } else {
        return employees.map((c) => (
          <Chip
            style={{ marginRight: '3px', marginBottom: '5px' }}
            label={displayName(c.userName)}
            onDelete={() => {
              setCandidate((candidates) => {
                return [...candidates, c];
              });
              setEmployees((obj) => {
                return obj.filter((o) => o.userName !== c.userName);
              });
            }}
          />
        ));
      }
    }
  };

  const content = () => {
    return (
      <div style={{ marginLeft: '10px' }}>
        <FormControlLabel
          control={<Switch />}
          label="使用する"
          checked={isUse}
          onChange={() => setIsUse(!isUse)}
        />
        <div>
          <TextField
            label="ワークフロー名"
            variant="outlined"
            margin="normal"
            value={workflowName}
            onChange={(e) => setWorkflowName(e.target.value)}
            error={!!errors.workflowName}
            helperText={errors.workflowName}
            required
            style={{ width: '215px' }}
          />
          <TextField
            label="ワークフローID"
            variant="outlined"
            margin="normal"
            value={workflowId}
            onChange={(e) => setWorkflowId(e.target.value)}
            error={!!errors.workflowId}
            helperText={errors.workflowId}
            required
            style={{ width: '215px', marginLeft: '20px' }}
          />
          <FormControl sx={{ marginTop: '10px' }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              承認種別
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={approveKind}
              onChange={(e) => setApproveKind(Number(e.target.value))}
            >
              <FormControlLabel
                value={APPROVE_KIND.ALL}
                control={<Radio />}
                label="全員(順不同)"
              />
              <FormControlLabel
                value={APPROVE_KIND.ALL_STEP}
                control={<Radio />}
                label="全員(ステップ)"
              />
              <FormControlLabel
                value={APPROVE_KIND.SINGLE}
                control={<Radio />}
                label="誰か"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <FormControlLabel
          control={<Switch />}
          label="承認者グループを使用する"
          checked={isUseGroup}
          onChange={() => setIsUseGroup(!isUseGroup)}
          sx={{ marginTop: '20px', marginBottom: '10px' }}
        />
        <div style={{ display: 'flex', height: '400px' }}>
          <Paper
            sx={{
              width: '300px',
              borderRadius: '4px',

              flexGrow: 1,
            }}
            elevation={3} // 影のレベルを指定
          >
            <ListSubheader
              sx={{
                backgroundColor: '#263238',
                color: '#eceff1',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                display: 'flex',
              }}
            >
              {isUseGroup ? '承認者グループ' : '社員'}
            </ListSubheader>
            {displayList()}
          </Paper>
          <Card
            variant="outlined"
            style={{
              width: '350px',
              borderRadius: '4px',
              marginLeft: '20px',
              flexGrow: 1,
              padding: '15px',
            }}
          >
            <Typography>
              {approveKind === APPROVE_KIND.ALL_STEP
                ? '承認者(ステップ)'
                : '承認者'}
            </Typography>
            <div style={{ marginTop: '10px' }}>{displayApprover()}</div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpen}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpen(false);
        setIsInit(false);
      }}
      yesText={'変更を適応'}
      noText={'キャンセル'}
      title={'ワークフロー編集'}
      content={content()}
    />
  );
}
export default WorkflowEditDialog;

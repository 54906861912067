import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';

function WorkflowSelector(props) {
  const {
    workflows,
    choicedWorkflowId,
    setChoicedWorkflowId,
    style,
    setIsApplyChange,
    helperText,
  } = props;

  const showWorkflow = () => {
    if (workflows === null || workflows === undefined) return;
    if (workflows.length !== 0) {
      return workflows.map((workflow) => (
        <MenuItem value={workflow.workflowId}>{workflow.workflowName}</MenuItem>
      ));
    } else {
      return <ListSubheader>ワークフローが登録されていません</ListSubheader>;
    }
  };

  return (
    <FormControl variant="outlined" sx={style}>
      <InputLabel id="demo-simple-select-standard-label">
        ワークフロー
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={choicedWorkflowId}
        onChange={(e) => {
          setChoicedWorkflowId(e.target.value);
          setIsApplyChange && setIsApplyChange(true);
        }}
        label="ワークフロー"
        variant="outlined"
      >
        {showWorkflow()}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default WorkflowSelector;

import React, { useEffect } from 'react';
import axios from 'axios';
import { BASE_ADDRESS } from './define';
import { useSetRecoilState } from 'recoil';
import { loading } from './recoil/atom';

// api実行に関するファイル
// 成功、失敗時の処理はコールバック関数としてsuccess, failureに記述

// 会社を登録
export const RegistCompany = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Companies`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ユーザーを登録
export const RegistUser = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Users`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定ユーザーでログイン
export const FindUsersByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Users/findByCompanyId/?CompanyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定ユーザーの権限を変更
export const UpdateUserAuthority = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Authorities/update`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した複数ユーザーの権限を変更
export const UpdateUsersAuthority = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Authorities/update/several`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定ユーザーでログイン
export const LoginUser = (userName, password, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Users/login/?UserName=${userName}&Password=${password}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 部門を登録
export const RegistDepartment = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Departments`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した部門IDの部門を取得
export const FindDepartmentByDepartmentId = (
  departmentId,
  success,
  failure
) => {
  axios
    .get(`${BASE_ADDRESS}Departments/department/?departmentId=${departmentId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの部門一覧を取得
export const FindDepartmentsByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Departments/departments/?companyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定部門を変更
export const UpdateDepartment = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Departments/update`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定部門の所属社員を変更する
export const UpdateAffilation = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Affilations`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 現場を登録
export const RegistSite = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Sites`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した現場IDの現場を取得
export const FindSiteBySiteId = (siteId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Sites/site/?siteId=${siteId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの現場一覧を取得
export const FindSitesByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Sites/sites/?companyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定現場を変更
export const UpdateSite = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Sites/update`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 日報を登録
export const RegistDailyReport = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}DailyReports/regist`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 日報コンポーネントを登録
export const RegistDailyReportComponent = (
  poster,
  dailyReportId,
  isNew,
  success,
  failure
) => {
  axios
    .post(
      `${BASE_ADDRESS}DailyReportComponents/?dailyReportId=${dailyReportId}&isNew=${isNew}`,
      poster
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの日報一覧を取得
export const GetAllDailyReportByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}DailyReports/allPaper/?companyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの日報一覧を取得
export const GetAllDailyReportComponentByDailyReportId = (
  dailyReportId,
  success,
  failure
) => {
  axios
    .get(`${BASE_ADDRESS}DailyReportComponents/?dailyReportId=${dailyReportId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した日報IDの日報を削除する
export const DeleteDailyReportByDailyReportId = (
  dailyReportId,
  success,
  failure
) => {
  axios
    .delete(`${BASE_ADDRESS}DailyReports/one/?dailyReportId=${dailyReportId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 複数の指定した日報IDの日報を削除する
export const DeleteDailyReportsByDailyReportIds = (
  dailyReportIds,
  success,
  failure
) => {
  axios
    .delete(`${BASE_ADDRESS}DailyReports/multiple/?${dailyReportIds}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 日報を提出
export const SubmitDailyReport = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}DailyReports/submit`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ワークフローを登録
export const RegistWorkflow = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Workflows`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの日報一覧を取得
export const FindWorkflowsByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Workflows/workflow/?companyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 承認者グループを登録
export const RegistApproverGroup = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}ApproverGroups`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した会社IDの日報一覧を取得
export const FindApproverGroupsByCompanyId = (companyId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}ApproverGroups/approverGroup/?companyId=${companyId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したユーザーID・期間の日報一覧を取得
export const GetDailyReportByUserIdAndTargetDate = (
  userId,
  startDate,
  endDate,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}DailyReports/myDailyReport/?userId=${userId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 打刻を登録
export const RegistStamp = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Stamps`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定したユーザーID・期間の打刻一覧を取得
export const GetStampsByUserIdAndTargetDate = (
  userId,
  startDate,
  endDate,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Stamps/myStamps/?userId=${userId}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した申請状態一覧を取得
export const FindApprovalStatuses = (
  approvalMenu,
  userName,
  hasApprovalAuthority,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}ApprovalStatuses/approvalStatuses/?approvalMenu=${approvalMenu}&userName=${userName}&hasApprovalAuthority=${hasApprovalAuthority}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した申請を承認
export const ApproveStatus = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}ApprovalStatuses/approve`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 指定した日報の値一覧を取得
export const FindDailyReportValuesBySubmitId = (submitId, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}DailyReports/submitDailyReportValues/?submitId=${submitId}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// OpenAI APIにメッセージを送信して返答を受け取る
export const ChatOpenAIWithMessage = (message, success, failure) => {
  const data = {
    model: process.env.REACT_APP_OPENAI_API_MODEL,
    messages: [{ role: 'user', content: message }],
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
  };
  const url = process.env.REACT_APP_OPENAI_API_ENDPOINT;

  axios
    .post(url, data, config)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 天気情報を取得
export const GetWeatherByLocation = (latitude, longitude, success, failure) => {
  if (!latitude || !longitude) {
    return '現在地の読み込みに失敗しました';
  }
  axios
    .get(process.env.REACT_APP_WEATHER_API_ENDPOINT, {
      params: {
        key: process.env.REACT_APP_WEATHER_API_KEY,
        q: `${latitude},${longitude}`,
        days: 1, // 予報日数
        aqi: 'yes', // 空気品質データの取得を無効にする（必要に応じて変更）
        alerts: 'yes', // 警報の取得を無効にする（必要に応じて変更）
        lang: 'ja',
      },
    })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 緯度経度から住所を取得
export const GetAddressByLocation = (latitude, longitude, success, failure) => {
  axios
    .get(process.env.REACT_APP_OPENCAGE_API_ENDPOINT, {
      params: {
        key: process.env.REACT_APP_OPENCAGE_API_KEY,
        q: `${latitude},${longitude}`,
        language: 'ja', // 日本語で応答を取得
      },
    })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// インターセプター
// requestで通信前共通処理
// responseで通信後共通処理
export const AxiosClientProvider = ({ children }) => {
  const setIsLoading = useSetRecoilState(loading);

  useEffect(() => {
    // リクエスト インターセプター
    const requestInterceptors = axios.interceptors.request.use(
      function (config) {
        if (
          config.url === process.env.REACT_APP_OPENAI_API_ENDPOINT ||
          config.url === process.env.REACT_APP_WEATHER_API_ENDPOINT ||
          config.url === process.env.REACT_APP_OPENCAGE_API_ENDPOINT
        ) {
          return config;
        }
        setIsLoading(true);
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    // レスポンス インターセプター
    const responseInterceptor = axios.interceptors.response.use(
      function (responce) {
        if (
          responce.config.url === process.env.REACT_APP_OPENAI_API_ENDPOINT ||
          responce.config.url === process.env.REACT_APP_WEATHER_API_ENDPOINT ||
          responce.config.url === process.env.REACT_APP_OPENCAGE_API_ENDPOINT
        ) {
          return responce;
        }
        setIsLoading(false);
        return responce;
      },
      function (err) {
        setIsLoading(false);
        return Promise.reject(err);
      }
    );

    // クリーンアップ
    return () => {
      axios.interceptors.request.eject(requestInterceptors);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};

import React, { useEffect, useState } from 'react';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import {
  RegistDepartment,
  RegistSite,
  RegistUser,
} from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import DailyReportSelector from '../../../Util/commonComponent/dailyReportSelector';
import WorkflowSelector from '../../../Util/commonComponent/workflowSelector';

function CreateChildSiteDialog(props) {
  const {
    isOpenDialog,
    setIsOpenDialog,
    setUpdateFlag,
    dailyReports,
    workflows,
    hierarchy,
    parentId,
  } = props;
  const [isUse, setIsUse] = useState(true);
  const [siteName, setSiteName] = useState('');
  const [siteId, setSiteId] = useState('');
  const [choicedDailyReportId, setChoicedDailyReportId] = useState('');
  const [choicedWorkflowId, setChoicedWorkflowId] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const handleRegister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // フォームのバリデーション
    const errors = {};
    if (!siteName.trim()) {
      errors.siteName = '現場名を入力してください';
    }
    if (!siteId.trim()) {
      errors.siteId = '現場IDを入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      SiteId: siteId,
      SiteName: siteName,
      ParentId: parentId,
      CompanyId: _loginInfo.companyId,
      DailyReportId: choicedDailyReportId !== '' ? choicedDailyReportId : null,
      WorkflowId: choicedWorkflowId !== '' ? choicedWorkflowId : null,
      Hierarchy: hierarchy,
      IsUse: isUse,
    };
    RegistSite(
      Poster,
      (res) => {
        setUpdateFlag(true);
        setIsOpenDialog(false);
        setIsUse(true);
        setSiteName('');
        setSiteId('');
        setChoicedDailyReportId('');
        setChoicedWorkflowId('');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const content = () => {
    return (
      <form noValidate autoComplete="off" style={{ marginTop: '10px' }}>
        <div>
          <FormControlLabel
            control={<Switch />}
            label="使用する"
            checked={isUse}
            onChange={() => setIsUse(!isUse)}
          />
        </div>

        <TextField
          label="現場名"
          variant="outlined"
          margin="normal"
          value={siteName}
          onChange={(e) => setSiteName(e.target.value)}
          error={!!errors.siteName}
          helperText={errors.siteName}
          required
          style={{ width: '215px' }}
        />
        <TextField
          label="現場ID"
          variant="outlined"
          margin="normal"
          value={siteId}
          onChange={(e) => setSiteId(e.target.value)}
          error={!!errors.siteId}
          helperText={errors.siteId}
          required
          style={{ width: '215px', marginLeft: '20px' }}
        />
        <div>
          <DailyReportSelector
            dailyReports={dailyReports}
            choicedDailyReportId={choicedDailyReportId}
            setChoicedDailyReportId={setChoicedDailyReportId}
            style={{
              width: '215px',
              marginTop: '20px',
            }}
          />
          <WorkflowSelector
            workflows={workflows}
            choicedWorkflowId={choicedWorkflowId}
            setChoicedWorkflowId={setChoicedWorkflowId}
            style={{
              width: '215px',
              marginTop: '20px',
              marginLeft: '20px',
            }}
            helperText={'日報承認に使用するワークフロー'}
          />
        </div>
      </form>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpenDialog}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpenDialog(false);
        setIsUse(true);
        setSiteName('');
        setSiteId('');
      }}
      yesText={'追加'}
      noText={'キャンセル'}
      title={'子現場設定'}
      content={content()}
    />
  );
}

export default CreateChildSiteDialog;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material';

function RadioControl(props) {
  if (props.isEdit) {
    return (
      <FormControl>
        <FormLabel
          id="demo-radio-buttons-group-label"
          style={{
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
          }}
        >
          {props.component.isShowLabel ? props.component.text : ''}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          row
          value={props.value}
          onChange={(e) =>
            props.setValue(props.component.componentId, e.target.value)
          }
        >
          {props.component.choices.map((choice, i) => {
            return (
              <FormControlLabel
                value={choice.choiceValue}
                control={<Radio />}
                label={choice.choiceValue}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px', // アイコンのサイズを変更
                  },
                }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  } else {
    return (
      <FormControl>
        <FormLabel
          id="demo-radio-buttons-group-label"
          style={{
            fontSize: props.component.fontSize * props.scalingFactor + 'px',
          }}
        >
          {props.component.isShowLabel ? props.component.text : ''}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          row
          defaultValue={props.component.defaultValue}
        >
          {props.component.choices.map((choice, i) => {
            return (
              <FormControlLabel
                value={choice.choiceValue}
                control={<Radio />}
                label={choice.choiceValue}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px', // アイコンのサイズを変更
                  },
                }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export { RadioControl };

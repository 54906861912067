import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CONTROL } from '../../../../Util/define';
import { RndTypography } from './Typography/rndTypography';
import { RndIcon } from './Icon/rndIcon';
import { RndImage } from './Image/rndImage';
import { RndCheckBox } from './CheckBox/rndCheckBox';
import { RndComboBox } from './ComboBox/rndComboBox';
import { RndRadio } from './Radio/rndRadio';
import { RndTextFieldText } from './TextField/rndTextFieldText';
import { RndTextFieldNum } from './TextField/rndTextFieldNum';
import { RndCalender } from './Calender/rndCalender';
import { RndWeather } from './Weather/rndWeather';
import { RndPhoto } from './Photo/rndPhoto';
import { RndTable } from './Table/rndTable';
import { TypographyControl } from '../../../../Util/dailyReportComponent/PC/typography';
import { Icon } from '../../../../Util/dailyReportComponent/PC/icon';
import { Image } from '../../../../Util/dailyReportComponent/PC/image';
import { CheckBox } from '../../../../Util/dailyReportComponent/PC/checkBox';
import { ComboBox } from '../../../../Util/dailyReportComponent/PC/comboBox';
import { RadioControl } from '../../../../Util/dailyReportComponent/PC/radio';
import { TextFieldText } from '../../../../Util/dailyReportComponent/PC/textFieldText';
import { TextFieldNum } from '../../../../Util/dailyReportComponent/PC/textFieldNum';
import { Calender } from '../../../../Util/dailyReportComponent/PC/calender';
import { Weather } from '../../../../Util/dailyReportComponent/PC/weather';
import { Photo } from '../../../../Util/dailyReportComponent/PC/photo';
import { Table } from '../../../../Util/dailyReportComponent/PC/table';
import { RndTotalValue } from './TotalValue/rndTotalValue';
import { TotalValueControl } from '../../../../Util/dailyReportComponent/PC/totalValue';
import { RndMonthlyTextFieldNum } from './InputGroup/Monthly/rndMonthlyTextFieldNum';
import { RndTime } from './Time/rndTime';
import { MonthlyTextFieldNum } from '../../../../Util/dailyReportComponent/PC/inputGroup/monthly/monthlyTextFieldNum';
import { RndFixedTextField } from './InputGroup/Fixed/rndFixedTextField';
import { RndFixedTextFieldNum } from './InputGroup/Fixed/rndFixedTextFieldNum';
import { RndFixedTime } from './InputGroup/Fixed/rndFixedTime';
import { Time } from '../../../../Util/dailyReportComponent/PC/time';
import { FixedTextField } from '../../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTextField';
import { FixedTextFieldNum } from '../../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTextFieldNum';
import { FixedTime } from '../../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTime';

function RndControl(props) {
  const rndControl = () => {
    switch (props.component.componentType) {
      case CONTROL.TYPOGRAPHY:
        return (
          <RndTypography
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.ICON:
        return (
          <RndIcon
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.IMAGE:
        return (
          <RndImage
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.CHECKBOX:
        return (
          <RndCheckBox
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.COMBOBOX:
        return (
          <RndComboBox
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.RADIO:
        return (
          <RndRadio
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TEXTFIELD.TEXT:
        return (
          <RndTextFieldText
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TEXTFIELD.NUM:
        return (
          <RndTextFieldNum
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.CALENDER:
        return (
          <RndCalender
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.WEATHER:
        return (
          <RndWeather
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.PHOTO:
        return (
          <RndPhoto
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TIME:
        return (
          <RndTime
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TABLE:
        return (
          <RndTable
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TOTAL_VALUE:
        return (
          <RndTotalValue
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
        return (
          <RndFixedTextField
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
        return (
          <RndFixedTextFieldNum
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
        return (
          <RndMonthlyTextFieldNum
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TIME.FIXED:
        return (
          <RndFixedTime
            component={props.component}
            setComponentInfos={props.setComponentInfos}
            scalingFactor={props.scalingFactor}
            setEditComponentId={props.setEditComponentId}
            setEditComponent={props.setEditComponent}
            setIsOpenRndDialog={props.setIsOpenRndDialog}
            setIsNew={props.setIsNew}
            isMobileView={props.isMobileView}
          />
        );
      default:
        break;
    }
  };
  const control = () => {
    // プレビューモード
    switch (props.component.componentType) {
      case CONTROL.TYPOGRAPHY:
        return (
          <TypographyControl
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.ICON:
        return (
          <Icon
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.IMAGE:
        return (
          <Image
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.CHECKBOX:
        return (
          <CheckBox
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.COMBOBOX:
        return (
          <ComboBox
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.RADIO:
        return (
          <RadioControl
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TEXTFIELD.TEXT:
        return (
          <TextFieldText
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TEXTFIELD.NUM:
        return (
          <TextFieldNum
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.CALENDER:
        return (
          <Calender
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.WEATHER:
        return (
          <Weather
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.PHOTO:
        return (
          <Photo
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TIME:
        return (
          <Time
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TABLE:
        return (
          <Table
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.TOTAL_VALUE:
        return (
          <TotalValueControl
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD.FIXED:
        return (
          <FixedTextField
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED:
        return (
          <FixedTextFieldNum
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY:
        return (
          <MonthlyTextFieldNum
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      case CONTROL.INPUT_GROUP.TIME.FIXED:
        return (
          <FixedTime
            component={props.component}
            scalingFactor={props.scalingFactor}
            isMobileView={props.isMobileView}
          />
        );
      default:
        break;
    }
  };

  if (props.isPreview) {
    if (props.isMobilePreview) {
      return (
        <Box
          style={{
            width:
              props.component.componentType === CONTROL.IMAGE
                ? props.component.width * (30 * props.scalingFactor)
                : 'auto',
            height:
              props.component.componentType === CONTROL.IMAGE
                ? props.component.height * (30 * props.scalingFactor)
                : 'auto',
            marginBottom: '8px',
          }}
        >
          {control()}
        </Box>
      );
    } else {
      return (
        <Box
          style={{
            left: props.component.x * (30 * props.scalingFactor),
            top: props.component.y * (30 * props.scalingFactor),
            width:
              props.component.componentType === CONTROL.IMAGE ||
              props.component.componentType === CONTROL.PHOTO ||
              props.component.componentType === CONTROL.COMBOBOX ||
              props.component.componentType === CONTROL.TEXTFIELD.NUM ||
              props.component.componentType === CONTROL.TABLE ||
              props.component.componentType === CONTROL.TEXTFIELD.TEXT ||
              props.component.componentType === CONTROL.CALENDER ||
              props.component.componentType === CONTROL.WEATHER ||
              props.component.componentType === CONTROL.TIME ||
              props.component.componentType ===
                CONTROL.INPUT_GROUP.TEXTFIELD.FIXED ||
              props.component.componentType ===
                CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED ||
              props.component.componentType ===
                CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY ||
              props.component.componentType === CONTROL.INPUT_GROUP.TIME.FIXED
                ? props.component.width * (30 * props.scalingFactor)
                : 'auto',
            height:
              props.component.componentType === CONTROL.IMAGE ||
              props.component.componentType === CONTROL.PHOTO ||
              props.component.componentType === CONTROL.TABLE ||
              props.component.componentType === CONTROL.COMBOBOX ||
              props.component.componentType === CONTROL.TEXTFIELD.NUM ||
              props.component.componentType === CONTROL.TEXTFIELD.TEXT ||
              props.component.componentType === CONTROL.CALENDER ||
              props.component.componentType === CONTROL.WEATHER
                ? props.component.height * (30 * props.scalingFactor)
                : 'auto',
            position: 'absolute',
          }}
        >
          {control()}
        </Box>
      );
    }
  } else {
    if (props.isMobilePreview) {
      return (
        <Box
          style={{
            width:
              props.component.componentType === CONTROL.IMAGE
                ? props.component.width * (30 * props.scalingFactor)
                : 'auto',
            height:
              props.component.componentType === CONTROL.IMAGE
                ? props.component.height * (30 * props.scalingFactor)
                : 'auto',
          }}
        >
          {control()}
        </Box>
      );
    } else {
      return <React.Fragment>{rndControl()}</React.Fragment>;
    }
  }
}

export { RndControl };

import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

function ControlDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>
          {!props.isNew && props.doDelete !== undefined && (
            <Button
              color="error"
              variant="outlined"
              style={{ marginRight: 'auto' }}
              onClick={props.doDelete}
            >
              削除する
            </Button>
          )}
          <Button onClick={props.doNo} color="primary">
            {props.noText}
          </Button>
          <Button onClick={props.doYes} color="primary" variant="outlined">
            {props.yesText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ControlDialog;

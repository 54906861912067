import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

function FixedTextField(props) {
  const viewEditComponent = (x, y) => {
    const items = [];
    for (let i = 0; i < y; i++) {
      items.push(
        <div key={'y' + i} style={{ display: 'flex' }}>
          {Array.from({ length: x }, (_, j) => (
            <TextField
              style={{
                backgroundColor: 'white',
                flex: 1,
              }}
              value={props.value[i][j]}
              onChange={(e) => {
                const newArray = [...props.value]; // 配列全体をコピー
                newArray[i][j] = e.target.value; // 指定個所を更新
                props.setValue(props.component.componentId, newArray);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: 30 * props.scalingFactor + 'px',
                },
                '& .MuiInputBase-input': {
                  fontSize: 16 * props.scalingFactor + 'px', // 変更したいフォントサイズ
                },
              }}
            />
          ))}
        </div>
      );
    }

    return <div>{items}</div>;
  };

  const viewComponent = (x, y) => {
    const items = [];
    for (let i = 0; i < y; i++) {
      items.push(
        <div key={'y' + i} style={{ display: 'flex' }}>
          {Array.from({ length: x }, (_, j) => (
            <TextField
              style={{
                backgroundColor: 'white',
                flex: 1,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: 30 * props.scalingFactor + 'px',
                },
                '& .MuiInputBase-input': {
                  fontSize: 16 * props.scalingFactor + 'px', // 変更したいフォントサイズ
                },
              }}
            />
          ))}
        </div>
      );
    }

    return <div>{items}</div>;
  };

  if (props.isEdit) {
    return viewEditComponent(props.component.xCount, props.component.yCount);
  } else {
    return viewComponent(props.component.xCount, props.component.yCount);
  }
}

export { FixedTextField };

import { Typography } from '@mui/material';
import './createDailyReport.css';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  FindSitesByCompanyId,
  GetAllDailyReportByCompanyId,
} from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import { STEP } from '../../../Util/define';
import SelectDate from './selectDate';
import EditDailyReport from './editDailyReport';

function CreateDailyReport() {
  const [createStep, setCreateStep] = useState(STEP.ONE);

  // 現場一覧
  const [sites, setSites] = useState([]);

  // 月指定
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [dates, setDates] = useState([]);

  // 選択した日報用紙
  const [selectedSite, setSelectedSite] = useState('');

  // 選択した日付
  const [selectedDate, setSelectedDate] = useState(null);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  // 日報用紙の取得
  useEffect(() => {
    FindSitesByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setSites(res.data.filter((site) => site.dailyReport !== null));
      },
      (e) => {
        console.warn(e);
      }
    );
  }, []);

  const viewComponent = () => {
    switch (createStep) {
      case STEP.ONE:
        return (
          <SelectDate
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            dates={dates}
            setCreateStep={setCreateStep}
            sites={sites}
            setSelectedDate={setSelectedDate}
            setSite={setSelectedSite}
          />
        );
      case STEP.TWO:
        return (
          <EditDailyReport
            site={selectedSite}
            date={selectedDate}
            setCreateStep={setCreateStep}
          />
        );
      default:
        return null;
    }
  };

  // datesの初期化
  useEffect(() => {
    if (selectedMonth) {
      const year = selectedMonth.year();
      const month = selectedMonth.month();
      const lastDay = selectedMonth.daysInMonth();
      const newDates = [];

      for (let day = 1; day <= lastDay; day++) {
        newDates.push(new Date(year, month, day));
      }

      setDates(newDates);
    } else {
      setDates([]);
    }
  }, [selectedMonth]);

  return (
    <div className="pcCreateDailyReport">
      <Typography>日報作成</Typography>
      {viewComponent()}
    </div>
  );
}

export default CreateDailyReport;

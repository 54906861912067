import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import './employeeSettings.css';
import {
  UpdateUserAuthority,
  UpdateUsersAuthority,
} from '../../../Util/web_api';
import ControlDialog from '../../../Util/commonComponent/controlDialog';

function EditDialog(props) {
  const {
    isOpenDialog,
    setIsOpenDialog,
    checkedEmployees,
    setCheckedEmployees,
    authority,
    displayName,
    setUpdateFlag,
    departments,
  } = props;
  const [editAuthority, setEditAuthority] = useState({});

  useEffect(() => {
    authority !== undefined && authority !== null
      ? setEditAuthority({
          isGeneralUser: authority.isGeneralUser,
          isDailyReportApprover: authority.isDailyReportApprover,
          isApplicationApprover: authority.isApplicationApprover,
          isSiteManager: authority.isSiteManager,
          isSystemAdministrator: authority.isSystemAdministrator,
          userName: authority.userName,
        })
      : setEditAuthority({
          isGeneralUser: false,
          isDailyReportApprover: false,
          isApplicationApprover: false,
          isSiteManager: false,
          isSystemAdministrator: false,
          userName: '',
        });
  }, [authority]);

  const handleChangeChecked = (event) => {
    setEditAuthority({
      ...editAuthority,
      [event.target.name]: event.target.checked,
    });
  };

  const displayEditDialogContent = () => {
    const {
      isGeneralUser = false,
      isDailyReportApprover = false,
      isApplicationApprover = false,
      isSiteManager = false,
      isSystemAdministrator = false,
    } = editAuthority;

    return (
      <React.Fragment>
        <div>
          {checkedEmployees.length === 1 ? displayName : 'まとめて編集'}
        </div>
        <FormControl
          sx={{ marginLeft: '20px', marginTop: '20px', marginRight: '20px' }}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={isGeneralUser}
                  checked={isGeneralUser}
                  onChange={handleChangeChecked}
                  name="isGeneralUser"
                />
              }
              label="一般利用"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDailyReportApprover}
                  onChange={handleChangeChecked}
                  name="isDailyReportApprover"
                />
              }
              label="日報承認者"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isApplicationApprover}
                  onChange={handleChangeChecked}
                  name="isApplicationApprover"
                />
              }
              label="申請承認者"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSiteManager}
                  onChange={handleChangeChecked}
                  name="isSiteManager"
                />
              }
              label="現場管理者"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSystemAdministrator}
                  onChange={handleChangeChecked}
                  name="isSystemAdministrator"
                />
              }
              label="システム管理者"
            />
          </FormGroup>
        </FormControl>
      </React.Fragment>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpenDialog}
      doYes={() => {
        if (checkedEmployees.length === 1) {
          console.log(editAuthority);
          const poster = {
            UserName: editAuthority.userName,
            IsGeneralUser: editAuthority.isGeneralUser,
            IsDailyReportApprover: editAuthority.isDailyReportApprover,
            IsApplicationApprover: editAuthority.isApplicationApprover,
            IsSiteManager: editAuthority.isSiteManager,
            IsSystemAdministrator: editAuthority.isSystemAdministrator,
          };
          console.log(poster);
          UpdateUserAuthority(
            poster,
            (res) => {
              console.log(res.data);
              setCheckedEmployees([]);
              setIsOpenDialog(false);
              setUpdateFlag(true);
            },
            (e) => {
              console.warn(e);
            }
          );
        } else if (checkedEmployees.length > 1) {
          const poster = {
            UserName: checkedEmployees,
            IsGeneralUser: editAuthority.isGeneralUser,
            IsDailyReportApprover: editAuthority.isDailyReportApprover,
            IsApplicationApprover: editAuthority.isApplicationApprover,
            IsSiteManager: editAuthority.isSiteManager,
            IsSystemAdministrator: editAuthority.isSystemAdministrator,
          };
          UpdateUsersAuthority(
            poster,
            (res) => {
              console.log(res.data);
              console.log(editAuthority);
              setCheckedEmployees([]);
              setIsOpenDialog(false);
              setUpdateFlag(true);
            },
            (e) => {
              console.warn(e);
            }
          );
        }
      }}
      doNo={() => {
        setCheckedEmployees([]);
        setIsOpenDialog(false);
      }}
      yesText={'OK'}
      noText={'キャンセル'}
      title={'社員設定'}
      content={displayEditDialogContent()}
    />
  );
}

export default EditDialog;

import './dailyReportDesign.css';
import React, { useState } from 'react';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { ContentCopy, ContentCut, ContentPaste } from '@mui/icons-material';
import AbcIcon from '@mui/icons-material/AbcOutlined';
import EmotionIcon from '@mui/icons-material/InsertEmoticonOutlined';
import ImageIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import ComboBoxIcon from '@mui/icons-material/AllInboxOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import InputIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import InputNumIcon from '@mui/icons-material/LooksOneOutlined';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import WeatherIcon from '@mui/icons-material/WbSunnyOutlined';
import PhotoIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ScalIcon from '@mui/icons-material/ZoomInOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CompleteIcon from '@mui/icons-material/CheckRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import VerticalSplitOutlinedIcon from '@mui/icons-material/VerticalSplitOutlined';
import { CONTROL } from '../../../Util/define';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import HPlusMobiledataIcon from '@mui/icons-material/HPlusMobiledata';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ScheduleIcon from '@mui/icons-material/Schedule';

function DesignMenu(props) {
  const {
    scalingFactor,
    setScalingFactor,
    setIsOpenRndDialog,
    setEditComponentId,
    setIsNew,
    setIsOpenFinishDialog,
    setIsOpenCancelDialog,
    isShowStripe,
    setIsShowStripe,
    isMobilePreview,
    setIsMobilePreview,
  } = props;

  const [openStrMenu, setOpenStrMenu] = useState(false);
  const [openChangeStrMenu, setOpenChangeStrMenu] = useState(false);
  const [openInputMenu, setOpenInputMenu] = useState(false);
  const [openInputGroupMenu, setOpenInputGroupMenu] = useState(false);
  const [openScalMenu, setOpenScalMenu] = useState(false);
  const [openModeMenu, setOpenModeMenu] = useState(false);
  const [openGridMenu, setOpenGridMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const designMenu = () => {
    const handleClickStrMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenStrMenu(true);
    };

    const handleClickChangeStrMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenChangeStrMenu(true);
    };

    const handleClickInputMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenInputMenu(true);
    };

    const handleClickInputGroupMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenInputGroupMenu(true);
    };

    const handleClickScalMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenScalMenu(true);
    };

    const handleClickModeMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenModeMenu(true);
    };

    const handleClickGridMenu = (e) => {
      setAnchorEl(e.currentTarget);
      setOpenGridMenu(true);
    };

    return (
      <Paper className="designDailyReport" elevation={3}>
        <Typography
          style={{
            marginLeft: '10px',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          設計用メニュー
        </Typography>
        <Divider />
        <MenuList>
          <MenuItem onClick={handleClickStrMenu}>
            <ListItemIcon>
              <HMobiledataIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>固定文字</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClickChangeStrMenu}>
            <ListItemIcon>
              <HPlusMobiledataIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>可変文字</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClickInputMenu}>
            <ListItemIcon>
              <EditNoteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>入力機能</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClickInputGroupMenu}>
            <ListItemIcon>
              <AppRegistrationIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>入力群</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsOpenRndDialog(true);
              setEditComponentId(CONTROL.TABLE);
              setIsNew(true);
            }}
          >
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>表形式</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClickScalMenu}>
            <ListItemIcon>
              <ScalIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>拡大率を変更</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClickModeMenu}>
            <ListItemIcon>
              <VerticalSplitOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>表示モード</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClickGridMenu}>
            <ListItemIcon>
              <Grid4x4Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>グリッド線</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>用紙設定</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setIsOpenFinishDialog(true)}>
            <ListItemIcon>
              <CompleteIcon fontSize="small" style={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText>設計プレビュー</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setIsOpenCancelDialog(true)}>
            <ListItemIcon>
              <CancelIcon fontSize="small" style={{ color: 'red' }} />
            </ListItemIcon>
            <ListItemText>設計を取り消す</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    );
  };

  const contentMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openStrMenu}
        onClose={() => setOpenStrMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenStrMenu(false);
            setEditComponentId(CONTROL.TYPOGRAPHY);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <AbcIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'文字'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenStrMenu(false);
            setEditComponentId(CONTROL.ICON);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <EmotionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'アイコン'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenStrMenu(false);
            setEditComponentId(CONTROL.IMAGE);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <ImageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'画像'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const changeMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openChangeStrMenu}
        onClose={() => setOpenChangeStrMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenChangeStrMenu(false);
            setEditComponentId(CONTROL.TOTAL_VALUE);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <LibraryAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'合計値'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const inputMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openInputMenu}
        onClose={() => setOpenInputMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.CHECKBOX);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <CheckBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'チェックボックス'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.COMBOBOX);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <ComboBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'コンボボックス'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.RADIO);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <RadioButtonCheckedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'ラジオ'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.TEXTFIELD.TEXT);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <InputIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'テキストフィールド'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.TEXTFIELD.NUM);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <InputNumIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'テキストフィールド(数字)'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.CALENDER);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <CalendarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'カレンダー'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.WEATHER);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <WeatherIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'天気'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.PHOTO);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <PhotoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'写真'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputMenu(false);
            setEditComponentId(CONTROL.TIME);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <ScheduleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'時間'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const inputGroupMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openInputGroupMenu}
        onClose={() => setOpenInputGroupMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ListSubheader>テキストフィールド</ListSubheader>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputGroupMenu(false);
            setEditComponentId(CONTROL.INPUT_GROUP.TEXTFIELD.FIXED);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <AbcIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'固定長'}</ListItemText>
        </MenuItem>
        <ListSubheader>{'テキストフィールド(数値)'}</ListSubheader>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputGroupMenu(false);
            setEditComponentId(CONTROL.INPUT_GROUP.TEXTFIELD_NUM.FIXED);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <InputNumIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'固定長'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputGroupMenu(false);
            setEditComponentId(CONTROL.INPUT_GROUP.TEXTFIELD_NUM.MONTHLY);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <EditCalendarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'月別'}</ListItemText>
        </MenuItem>
        <ListSubheader>時間</ListSubheader>
        <MenuItem
          onClick={() => {
            setIsOpenRndDialog(true);
            setOpenInputGroupMenu(false);
            setEditComponentId(CONTROL.INPUT_GROUP.TIME.FIXED);
            setIsNew(true);
          }}
        >
          <ListItemIcon>
            <ScheduleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'固定長'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const scalMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openScalMenu}
        onClose={() => setOpenScalMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setScalingFactor(0.5)}>
          <ListItemIcon>
            {scalingFactor === 0.5 && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'0.5'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setScalingFactor(0.75)}>
          <ListItemIcon>
            {scalingFactor === 0.75 && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'0.75'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setScalingFactor(1)}>
          <ListItemIcon>
            {scalingFactor === 1 && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'1'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const modeMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openModeMenu}
        onClose={() => setOpenModeMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setIsMobilePreview(false)}>
          <ListItemIcon>
            {!isMobilePreview && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'PC'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsMobilePreview(true)}>
          <ListItemIcon>
            {isMobilePreview && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'スマホ'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  const gridMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openGridMenu}
        onClose={() => setOpenGridMenu(false)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setIsShowStripe(true)}>
          <ListItemIcon>
            {isShowStripe && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'表示'}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setIsShowStripe(false)}>
          <ListItemIcon>
            {!isShowStripe && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{'非表示'}</ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <React.Fragment>
      {designMenu()}
      {contentMenu()}
      {changeMenu()}
      {inputMenu()}
      {inputGroupMenu()}
      {scalMenu()}
      {modeMenu()}
      {gridMenu()}
    </React.Fragment>
  );
}

export default DesignMenu;

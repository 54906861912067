import './App.css';
import Header from './PCView/Control/header/header';
import SideMenu from './PCView/Control/sideMenu/sideMenu';
import DailyReportDesign from './PCView/Component/dailyReportDesign/dailyReportDesign';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Util/login/login';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isOpenToast, loading, toastState } from './Util/recoil/atom';
import { AxiosClientProvider } from './Util/web_api';
import { Backdrop, CircularProgress, Grow, Snackbar } from '@mui/material';
import RegistUser from './Util/login/registUser';
import RegistDisclimination from './Util/login/registDisclimination';
import RegistCompany from './Util/login/registCompany';
import Home from './PCView/Component/home/home';
import Settings from './PCView/Component/settings/settings';
import Account from './PCView/Component/account/account';
import CreateDailyReport from './PCView/Component/createDailReport/createDeilyReport';
import EmployeeSettings from './PCView/Component/employeeSettings/employeeSettings';
import DepartmentSettings from './PCView/Component/departmentSettings/departmentSettings';
import ApplicationSettings from './PCView/Component/applicationSettings/applicationSettings';
import SiteSettings from './PCView/Component/siteSettings/siteSettings';
import WorkflowSettings from './PCView/Component/workflowSettings/workflowSettings';
import DailyReportApproval from './PCView/Component/dailyReportApproval/dailyReportApproval';
import DailyReportReview from './PCView/Component/dailyReportReview/dailyReportReview';
import StampHistory from './PCView/Component/stampHistory/stampHistory';

function GrowTransition(props) {
  return <Grow {...props} />;
}

function App(props) {
  const isLoading = useRecoilValue(loading);
  const [_toastState, _setToastState] = useRecoilState(toastState);

  const onCloseSnackBar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    _setToastState({ isOpen: false, context: '' });
  };

  return (
    <BrowserRouter>
      <AxiosClientProvider>
        {isLoading && (
          <Backdrop sx={{ color: '#fff', zIndex: 1500 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={_toastState.isOpen}
          onClose={onCloseSnackBar}
          message={_toastState.context}
          autoHideDuration={3000}
          TransitionComponent={GrowTransition}
        />
        <div className="App">
          <Header />
          <div className="pcMain" style={{ display: 'flex' }}>
            <SideMenu />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/Regist" element={<RegistDisclimination />} />
              <Route path="/Regist/User" element={<RegistUser />} />
              <Route path="/Regist/Company" element={<RegistCompany />} />
              <Route path="/Account" element={<Account />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Settings" element={<Settings />} />
              <Route path="/StampHistory" element={<StampHistory />} />
              <Route
                path="/CreateDailyReport"
                element={<CreateDailyReport />}
              />
              <Route
                path="/DailyReportApproval"
                element={<DailyReportApproval />}
              />
              <Route
                path="/DailyReportReview"
                element={<DailyReportReview />}
              />
              <Route path="/EmployeeSettings" element={<EmployeeSettings />} />
              <Route
                path="/DepartmentSettings"
                element={<DepartmentSettings />}
              />
              <Route
                path="/ApplicationSettings"
                element={<ApplicationSettings />}
              />
              <Route path="/SiteSettings" element={<SiteSettings />} />
              <Route path="/WorkflowSettings" element={<WorkflowSettings />} />
              <Route
                path="/DailyReportDesign"
                element={<DailyReportDesign />}
              />
            </Routes>
          </div>
        </div>
      </AxiosClientProvider>
    </BrowserRouter>
  );
}

export default App;

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

function TextFieldNum(props) {
  if (props.isEdit) {
    return (
      <TextField
        type="number"
        value={props.value}
        onChange={(e) =>
          props.setValue(props.component.componentId, Number(e.target.value))
        }
        style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
        label={props.component.isShowLabel ? props.component.text : ''}
        sx={{
          '& .MuiInputBase-root': {
            width: '100%',
            height: '100%', // 変更したい高さ
          },
          '& .MuiInputBase-input': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
          },
        }}
      />
    );
  } else {
    return (
      <TextField
        type="number"
        style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
        label={props.component.isShowLabel ? props.component.text : ''}
        sx={{
          '& .MuiInputBase-root': {
            width: '100%',
            height: '100%', // 変更したい高さ
          },
          '& .MuiInputBase-input': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
          },
        }}
      />
    );
  }
}

export { TextFieldNum };

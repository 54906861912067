import React, { useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Paper,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material';
import './applicationSettings.css';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DisplayApplicationTypeName,
  DisplayApproveKindName,
} from '../../../Util/function';
import CreateApplicationDialog from './createApplicationDialog';

const app = [
  { id: 'a', name: '有給申請', type: 0, isUse: true },
  { id: 'b', name: '残業申請', type: 1, isUse: true },
  { id: 'd', name: '出張申請', type: 2, isUse: true },
  { id: 'e', name: '交通費申請', type: 3, isUse: true },
];

function ApplicationSettings() {
  const [applications, setApplications] = useState(app);
  const [isInit, setIsInit] = useState(true);
  const [isOpenApplicationDialog, setIsOpenApplicationDialog] = useState(false);
  const [editApplication, setEditApplication] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  const handleEditApplication = (workflow) => {
    setEditApplication(workflow);
    setIsOpenApplicationDialog(true);
    setIsInit(false);
  };

  const displayWorkflowTable = () => {
    return (
      <TableContainer
        sx={{ maxWidth: '800px', marginTop: '10px' }}
        component={Paper}
      >
        <Table size="small" aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#263238' }}>
            <TableRow>
              <TableCell
                padding="nomal"
                sx={{ width: '30px', color: '#eceff1' }}
              >
                <Checkbox
                  sx={{
                    color: '#eceff1',
                    '&.Mui-checked': {
                      color: '#eceff1',
                    },
                  }}
                />
              </TableCell>
              <TableCell
                padding="nomal"
                sx={{ width: '250px', color: '#eceff1' }}
              >
                申請名
              </TableCell>
              <TableCell align="left" sx={{ width: '150px', color: '#eceff1' }}>
                申請種別
              </TableCell>
              <TableCell align="left" sx={{ color: '#eceff1', width: '60px' }}>
                使用する
              </TableCell>
              <TableCell
                align="left"
                sx={{ color: '#eceff1', width: '100px' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          {applications.map((application, i) => {
            return (
              <TableBody key={application.id}>
                <TableRow
                  sx={{
                    backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                  }}
                >
                  <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                    <Checkbox
                      sx={{
                        color: '#263238',
                        '&.Mui-checked': {
                          color: '#263238',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>{application.name}</TableCell>
                  <TableCell align="left">
                    {DisplayApplicationTypeName(application.type)}
                  </TableCell>
                  <TableCell align="center">
                    {application.isUse ? <CheckCircleIcon /> : ''}
                  </TableCell>
                  <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="編集">
                        <IconButton
                          onClick={() => handleEditApplication(application)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除">
                        <IconButton sx={{ color: '#f44336' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    );
  };
  return (
    <div className="pcApplicationSettings">
      <CreateApplicationDialog
        isOpenDialog={isOpenApplicationDialog}
        setIsOpenDialog={setIsOpenApplicationDialog}
        setUpdateFlag={setUpdateFlag}
      />
      <Typography>申請設定</Typography>
      <div style={{ width: '800px', marginLeft: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsOpenApplicationDialog(true);
              setIsInit(true);
            }}
          >
            新規作成
          </Button>
          <Button
            sx={{ marginLeft: '10px' }}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            まとめて削除
          </Button>
        </div>
        {displayWorkflowTable()}
      </div>
    </div>
  );
}

export default ApplicationSettings;

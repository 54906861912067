import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';

function DailyReportSelector(props) {
  const {
    dailyReports,
    choicedDailyReportId,
    setChoicedDailyReportId,
    style,
    setIsApplyChange,
  } = props;

  const showDailyReport = () => {
    if (dailyReports === null || dailyReports === undefined) return;
    if (dailyReports.length !== 0) {
      return dailyReports.map((dailyReport) => (
        <MenuItem value={dailyReport.dailyReportId}>
          {dailyReport.projectName}
        </MenuItem>
      ));
    } else {
      return <ListSubheader>日報用紙が作成されていません</ListSubheader>;
    }
  };

  return (
    <FormControl variant="outlined" sx={style}>
      <InputLabel id="demo-simple-select-standard-label">日報用紙</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={choicedDailyReportId}
        onChange={(e) => {
          setChoicedDailyReportId(e.target.value);
          setIsApplyChange && setIsApplyChange(true);
        }}
        label="日報用紙"
        variant="outlined"
      >
        {showDailyReport()}
      </Select>
    </FormControl>
  );
}

export default DailyReportSelector;

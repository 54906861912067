import './dailyReportDesign.css';
import React, { useEffect, useState } from 'react';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import { STEP } from '../../../Util/define';
import { RegistDailyReport } from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import { TimeToUTC } from '../../../Util/function';

function FormatSelect(props) {
  const [name, setName] = useState('');
  const [size, setSize] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isNameError, setIsNameError] = useState(false);
  const [isSizeError, setIsSizeError] = useState(false);
  const [isDirectionError, setIsDirectionError] = useState(false);

  //recoil
  const _loginInfo = useRecoilValue(loginInfo);

  useEffect(() => {
    if (props.isEdit) {
      setName(props.dailyReport.projectName);
      setSize(props.dailyReport.size);
      setDirection(props.dailyReport.direction);
    } else {
      setName('');
      setSize(0);
      setDirection(0);
    }
  }, []);

  const onClickNext = () => {
    let isEmpty = false;
    if (name === '') {
      setIsNameError(true);
      isEmpty = true;
    }
    if (size === '') {
      setIsSizeError(true);
      isEmpty = true;
    }
    if (direction === '') {
      setIsDirectionError(true);
      isEmpty = true;
    }

    if (props.isEdit) {
      props.setDesignStep(STEP.THREE);
    } else {
      if (!isEmpty) {
        const now = new Date();
        const Poster = {
          CompanyId: _loginInfo.companyId,
          ProjectName: name,
          CreaterId: _loginInfo.loginUserName,
          CreateDate: TimeToUTC(now),
          Size: size,
          Direction: direction,
        };

        console.log(Poster);

        RegistDailyReport(
          Poster,
          (res) => {
            props.setDailyReportId(res.data.dailyReportId);
            props.setDesignStep(STEP.THREE);
          },
          (e) => {
            console.warn(e);
          }
        );
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        <TextField
          error={isNameError}
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          label="工事名"
          style={{ width: '520px' }}
          helperText={isNameError ? '名称を入力してください' : ''}
        />
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <TextField
            error={isSizeError}
            variant="outlined"
            label="サイズ"
            select
            value={size}
            onChange={(e) => setSize(e.target.value)}
            style={{ width: '300px' }}
            disabled={props.isEdit}
          >
            <MenuItem key={0} value={0}>
              A3
            </MenuItem>
            <MenuItem key={1} value={1}>
              A4
            </MenuItem>
          </TextField>
          <TextField
            error={isDirectionError}
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
            variant="outlined"
            label="用紙の向き"
            style={{ width: '200px', marginLeft: '20px' }}
            select
            disabled={props.isEdit}
          >
            <MenuItem key={0} value={0}>
              縦
            </MenuItem>
            <MenuItem key={1} value={1}>
              横
            </MenuItem>
          </TextField>
        </div>
        <Typography style={{ color: 'red' }}>
          {props.isEdit
            ? '※編集時は「サイズ」と「用紙の向き」を変更することができません。'
            : '※「サイズ」と「用紙の向き」は設計完了後変更することができません。'}
        </Typography>
      </div>

      <Button variant="contained" onClick={() => onClickNext()}>
        設計へ進む
      </Button>
      <Button onClick={() => props.setDesignStep(STEP.ONE)}>戻る</Button>
    </div>
  );
}

export default FormatSelect;

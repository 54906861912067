import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ID } from '../define';
import { Password } from '@mui/icons-material';
import { RegistUser } from '../web_api';
import logo from './../../image/logo.PNG';

const RegistDisclimination = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100vw', height: '100vh', backgroundColor: '#c8c8c8' }}
    >
      <Paper
        elevation={3}
        style={{ padding: '20px', maxWidth: '400px', width: '100%' }}
      >
        <img src={logo} />
        <form noValidate autoComplete="off">
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate('/' + PAGE_ID.REGIST_COMPANY)}
            >
              会社を登録する
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate('/' + PAGE_ID.REGIST_USER)}
            >
              ユーザーを登録する
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" align="center">
              すでにアカウントをお持ちの場合は
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
                variant="body2"
              >
                ログイン
              </Link>
              してください。
            </Typography>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default RegistDisclimination;

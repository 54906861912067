import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PAGE_ID } from '../define';
import { Password } from '@mui/icons-material';
import { RegistUser as RG } from '../web_api';
import logo from './../../image/logo.PNG';
import { useSetRecoilState } from 'recoil';
import { toastState } from '../recoil/atom';

const RegistUser = () => {
  const [companyId, setCompanyId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();

  const handleRegister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // フォームのバリデーション
    const errors = {};
    if (!companyId.trim()) {
      errors.companyId = '会社IDを入力してください';
    }
    if (!username.trim()) {
      errors.username = 'ユーザーネームを入力してください';
    }
    if (!password.trim()) {
      errors.password = 'パスワードを入力してください';
    }
    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'パスワード（確認用）を入力してください';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'パスワードが一致しません';
    }
    if (!lastName.trim()) {
      errors.lastName = '姓を入力してください';
    }
    if (!firstName.trim()) {
      errors.firstName = '名を入力してください';
    }
    if (!email.trim()) {
      errors.email = 'メールアドレスを入力してください';
    } else if (!emailRegex.test(email.trim())) {
      errors.email = '正しいメールアドレス形式で入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      CompanyId: companyId,
      UserName: username,
      Mailaddress: email,
      LastName: lastName,
      FirstName: firstName,
      Password: password,
    };
    RG(
      Poster,
      (res) => {
        _setToastState({
          isOpen: true,
          context: 'ユーザーの登録が完了しました',
        });
        navigate('/');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ width: '100vw', height: '100vh', backgroundColor: '#c8c8c8' }}
    >
      <Paper
        elevation={3}
        style={{ padding: '20px', maxWidth: '450px', width: '100%' }}
      >
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <img src={logo} />
        </div>
        <Typography variant="h5" align="center" gutterBottom>
          ユーザー登録フォーム
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            label="会社ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
            error={!!errors.companyId}
            helperText={errors.companyId}
            required
          />
          <TextField
            label="姓"
            variant="outlined"
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
            required
            style={{ width: '215px' }}
          />
          <TextField
            label="名"
            variant="outlined"
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
            required
            style={{ width: '215px', marginLeft: '20px' }}
          />
          <TextField
            label="メールアドレス"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            required
          />
          <TextField
            label="ユーザーネーム"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!errors.username}
            helperText={errors.username}
            required
          />
          <TextField
            label="パスワード"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            required
          />
          <TextField
            label="パスワード（確認用）"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            required
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRegister}
            >
              会員登録する
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" align="center">
              すでにアカウントをお持ちの場合は
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
                variant="body2"
              >
                ログイン
              </Link>
              してください。
            </Typography>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default RegistUser;

import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import './employeeSettings.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  FindDepartmentsByCompanyId,
  FindUsersByCompanyId,
} from '../../../Util/web_api';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditDialog from './editDialog';
import AddUserDialog from './addUserDialog';

const employee = [
  { id: 'aaa', name: '末吉勇希' },
  { id: 'bbb', name: '林田周' },
];

function EmployeeSettings() {
  const [employees, setEmployees] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState([]);
  const [isOpenAddUserDialog, setIsOpenAddUserDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [departments, setDepartments] = useState([]);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const findUser = () => {
    FindUsersByCompanyId(
      _loginInfo.companyId,
      (res) => {
        console.log(res.data);
        const userData = res.data.map(({ authority, ...rest }) => rest);
        const authoritiesData = res.data.map(({ authority }) => authority);
        setEmployees(userData);
        setAuthorities(authoritiesData);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const findDepartments = () => {
    FindDepartmentsByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setDepartments(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    findUser();
    findDepartments();
  }, []);

  useEffect(() => {
    if (updateFlag) {
      findUser();
      setUpdateFlag(false);
    }
  }, [updateFlag]);

  const handleAllCheck = (checked) => {
    if (checked) {
      setCheckedEmployees(employees.map((employee) => employee.userName));
    } else {
      setCheckedEmployees([]);
    }
  };
  const employeesUserName = () => {
    return employees.map((emp) => emp.userName);
  };
  const handleCheck = (checked, id) => {
    if (checked) {
      setCheckedEmployees((prev) => [...prev, id]);
    } else {
      const items = checkedEmployees.filter((emp) => emp !== id);
      setCheckedEmployees(items);
    }
  };
  const employeeIsChecked = (id) => {
    return checkedEmployees.some((emp) => emp === id);
  };

  const searchAuthority = (username) => {
    if (authorities.length === 0) return;
    console.log(authorities);
    return authorities.find((a) => a.userName === username);
  };

  const displayDepartmentName = (userName) => {
    const temp = departments.find(
      (department) =>
        department.affilations !== null &&
        department.affilations.some(
          (affilation) => affilation.userName === userName
        )
    );

    if (temp !== undefined) {
      return temp.departmentName;
    } else {
      return '未所属';
    }
  };

  const displayAuthority = (username) => {
    const authority = searchAuthority(username);
    if (authority === undefined) {
      return;
    } else {
      return (
        <React.Fragment>
          <TableCell align="left">{displayDepartmentName(username)}</TableCell>
          <TableCell align="center">
            {authority.isGeneralUser ? <CheckCircleIcon /> : ''}
          </TableCell>
          <TableCell align="center">
            {authority.isDailyReportApprover ? <CheckCircleIcon /> : ''}
          </TableCell>
          <TableCell align="center">
            {authority.isApplicationApprover ? <CheckCircleIcon /> : ''}
          </TableCell>
          <TableCell align="center">
            {authority.isSiteManager ? <CheckCircleIcon /> : ''}
          </TableCell>
          <TableCell align="center">
            {authority.isSystemAdministrator ? <CheckCircleIcon /> : ''}
          </TableCell>
        </React.Fragment>
      );
    }
  };

  const getDisplayName = (username) => {
    const employee = employees.find((emp) => emp.userName === username);
    return employee.lastName + ' ' + employee.firstName;
  };

  return (
    <div className="pcEmployeeSettings">
      <AddUserDialog
        isOpenDialog={isOpenAddUserDialog}
        setIsOpenDialog={setIsOpenAddUserDialog}
        setUpdateFlag={setUpdateFlag}
      />
      <EditDialog
        isOpenDialog={isOpenEditDialog}
        setIsOpenDialog={setIsOpenEditDialog}
        checkedEmployees={checkedEmployees}
        setCheckedEmployees={setCheckedEmployees}
        authority={
          checkedEmployees.length === 1
            ? searchAuthority(checkedEmployees[0])
            : null
        }
        displayName={
          checkedEmployees.length === 1
            ? getDisplayName(checkedEmployees[0])
            : null
        }
        setUpdateFlag={setUpdateFlag}
        departments={departments}
      />
      <ControlDialog
        isOpen={isOpenDeleteDialog}
        doYes={() => {
          setIsOpenDeleteDialog(false);
        }}
        doNo={() => {
          if (checkedEmployees.length === 1) {
            setCheckedEmployees([]);
            setIsOpenDeleteDialog(false);
          } else {
            setIsOpenDeleteDialog(false);
          }
        }}
        yesText={'はい'}
        noText={'キャンセル'}
        title={'社員設定'}
        content={
          checkedEmployees.length === 1
            ? '社員「' +
              getDisplayName(checkedEmployees[0]) +
              '」を削除しますか？'
            : checkedEmployees.length === 0
            ? ''
            : '社員を削除しますか？'
        }
      />
      <Typography>社員設定</Typography>
      <div style={{ maxWidth: '1000px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            style={{ marginRight: '10px' }}
            disabled={checkedEmployees.length !== 0}
            onClick={() => setIsOpenAddUserDialog(true)}
          >
            ユーザーを作成
          </Button>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            style={{ marginRight: '10px' }}
            disabled={checkedEmployees.length === 0}
            onClick={() => setIsOpenEditDialog(true)}
          >
            まとめて編集
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            disabled={checkedEmployees.length === 0}
            onClick={() => setIsOpenDeleteDialog(true)}
          >
            まとめて削除
          </Button>
        </div>
        <TableContainer sx={{ marginTop: '10px' }} component={Paper}>
          <Table
            size="small"
            aria-label="customized table"
            sx={{ borderSpacing: '0px' }}
          >
            <TableHead sx={{ backgroundColor: '#263238' }}>
              <TableRow>
                <TableCell
                  padding="nomal"
                  sx={{
                    width: '30px',
                    color: '#eceff1',
                    borderBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  <Checkbox
                    sx={{
                      color: '#eceff1',
                      '&.Mui-checked': {
                        color: '#eceff1',
                      },
                    }}
                    onChange={() =>
                      handleAllCheck(
                        !(
                          JSON.stringify(employeesUserName()) ===
                          JSON.stringify(checkedEmployees)
                        )
                      )
                    }
                    checked={
                      JSON.stringify(employeesUserName()) ===
                      JSON.stringify(checkedEmployees)
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    width: '200px',
                    color: '#eceff1',
                    borderBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  社員名
                </TableCell>
                <TableCell
                  sx={{
                    width: '200px',
                    color: '#eceff1',
                    borderBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  部門
                </TableCell>
                <TableCell
                  align="left"
                  colSpan={5}
                  sx={{
                    color: '#eceff1',
                    borderBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  <div>
                    <div style={{ marginLeft: '5px' }}>権限</div>
                    <Divider sx={{ bgcolor: '#eceff1' }} />
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: '#eceff1',
                    width: '100px',
                    borderBottom: '0px',
                    paddingBottom: '0px',
                  }}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: '30px', color: '#eceff1', paddingTop: '0px' }}
                ></TableCell>
                <TableCell
                  sx={{ width: '200px', color: '#eceff1', paddingTop: '0px' }}
                ></TableCell>
                <TableCell
                  sx={{ width: '200px', color: '#eceff1', paddingTop: '0px' }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: '#eceff1',
                    paddingTop: '0px',
                  }}
                >
                  一般利用
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: '#eceff1',
                    paddingTop: '0px',
                  }}
                >
                  日報承認者
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: '#eceff1',
                    paddingTop: '0px',
                  }}
                >
                  申請承認者
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: '#eceff1',
                    paddingTop: '0px',
                  }}
                >
                  現場管理者
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: '#eceff1',
                    paddingTop: '0px',
                  }}
                >
                  システム管理者
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: '#eceff1', width: '100px', paddingTop: '0px' }}
                ></TableCell>
              </TableRow>
            </TableHead>
            {employees.map((employee, i) => {
              const isChecked = employeeIsChecked(employee.userName);
              return (
                <TableBody key={employee.id}>
                  <TableRow
                    sx={{
                      backgroundColor: i % 2 === 0 ? '#eceff1' : '#cfd8dc',
                    }}
                  >
                    <TableCell sx={{ width: '30px', color: '#eceff1' }}>
                      <Checkbox
                        sx={{
                          color: '#263238',
                          '&.Mui-checked': {
                            color: '#263238',
                          },
                        }}
                        onChange={() =>
                          handleCheck(!isChecked, employee.userName)
                        }
                        checked={isChecked}
                      />
                    </TableCell>
                    <TableCell>
                      {employee.lastName + ' ' + employee.firstName}
                    </TableCell>
                    {displayAuthority(employee.userName)}
                    <TableCell align="left">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="編集">
                          <IconButton
                            disabled={checkedEmployees.length !== 0}
                            onClick={() => {
                              setCheckedEmployees([employee.userName]);
                              setIsOpenEditDialog(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="削除">
                          <IconButton
                            onClick={() => {
                              setCheckedEmployees([employee.userName]);
                              setIsOpenDeleteDialog(true);
                            }}
                            sx={{ color: '#f44336' }}
                            disabled={checkedEmployees.length !== 0}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default EmployeeSettings;

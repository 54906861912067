import React, { useEffect, useState } from 'react';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { RegistDepartment, RegistUser } from '../../../Util/web_api';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import DailyReportSelector from '../../../Util/commonComponent/dailyReportSelector';
import WorkflowSelector from '../../../Util/commonComponent/workflowSelector';
import { APPLICATION_TYPE } from '../../../Util/define';
import { DisplayApplicationTypeName } from '../../../Util/function';

function CreateApplicationDialog(props) {
  const { isOpenDialog, setIsOpenDialog, setUpdateFlag } = props;
  const [isUse, setIsUse] = useState(true);
  const [applicationName, setApplicationName] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [errors, setErrors] = useState({});
  const [applicationType, setApplicationType] = useState(
    APPLICATION_TYPE.LEAVE
  );

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const handleRegister = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // フォームのバリデーション
    const errors = {};
    if (!applicationName.trim()) {
      errors.departmentName = '部門名を入力してください';
    }
    if (!applicationId.trim()) {
      errors.departmentId = '部門IDを入力してください';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // バリデーションで問題がなければ会員登録の処理を行う
    const Poster = {
      DepartmentId: applicationId,
      DepartmentName: applicationName,
      ParentId: null,
      CompanyId: _loginInfo.companyId,
      Hierarchy: 0,
      IsUse: isUse,
    };
    RegistDepartment(
      Poster,
      (res) => {
        setUpdateFlag(true);
        setIsOpenDialog(false);
        setIsUse(true);
        setApplicationName('');
        setApplicationId('');
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const content = () => {
    return (
      <form noValidate autoComplete="off" style={{ marginTop: '10px' }}>
        <div>
          <FormControlLabel
            control={<Switch />}
            label="使用する"
            checked={isUse}
            onChange={() => setIsUse(!isUse)}
          />
        </div>

        <TextField
          label="申請名"
          variant="outlined"
          margin="normal"
          value={applicationName}
          onChange={(e) => setApplicationName(e.target.value)}
          error={!!errors.departmentName}
          helperText={errors.departmentName}
          required
          style={{ width: '215px' }}
        />
        <TextField
          label="申請ID"
          variant="outlined"
          margin="normal"
          value={applicationId}
          onChange={(e) => setApplicationId(e.target.value)}
          error={!!errors.departmentId}
          helperText={errors.departmentId}
          required
          style={{ width: '215px', marginLeft: '20px' }}
        />
        <FormControl sx={{ marginTop: '10px' }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            承認種別
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={applicationType}
            onChange={(e) => setApplicationType(Number(e.target.value))}
          >
            <FormControlLabel
              value={APPLICATION_TYPE.LEAVE}
              control={<Radio />}
              label={DisplayApplicationTypeName(APPLICATION_TYPE.LEAVE)}
            />
            <FormControlLabel
              value={APPLICATION_TYPE.OVERTIME}
              control={<Radio />}
              label={DisplayApplicationTypeName(APPLICATION_TYPE.OVERTIME)}
            />
            <FormControlLabel
              value={APPLICATION_TYPE.BUSINESS_TRIP}
              control={<Radio />}
              label={DisplayApplicationTypeName(APPLICATION_TYPE.BUSINESS_TRIP)}
            />
            <FormControlLabel
              value={APPLICATION_TYPE.EXPENSE}
              control={<Radio />}
              label={DisplayApplicationTypeName(APPLICATION_TYPE.EXPENSE)}
            />
          </RadioGroup>
        </FormControl>
      </form>
    );
  };

  return (
    <ControlDialog
      isOpen={isOpenDialog}
      doYes={() => {
        handleRegister();
      }}
      doNo={() => {
        setIsOpenDialog(false);
        setIsUse(true);
        setApplicationName('');
        setApplicationId('');
      }}
      yesText={'追加'}
      noText={'キャンセル'}
      title={'申請設定'}
      content={content()}
    />
  );
}

export default CreateApplicationDialog;

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  TableBody,
  Button,
} from '@mui/material';
import './dailyReportApproval.css';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  FindApprovalStatuses,
  FindUsersByCompanyId,
} from '../../../Util/web_api';
import UserSelector from '../../../Util/commonComponent/userSelector';
import ChoiceUser from './choiceUser';
import { STEP } from '../../../Util/define';
import { Approval } from '@mui/icons-material';
import Approve from './approve';

function DailyReportApproval() {
  const [step, setStep] = useState(STEP.ONE);
  const [users, setUsers] = useState([]);
  const [choicedUserId, setChoicedUserId] = useState('');
  const [submitDailyReports, setSubmitDailyReports] = useState([]);
  const [approvalTargetUserName, setApprovalTargetUserName] = useState('');

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const FindUser = () => {
    FindUsersByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setUsers(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindApprovalStatus = () => {
    FindApprovalStatuses(
      0,
      _loginInfo.loginUserName,
      true,
      (res) => {
        setSubmitDailyReports(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    FindUser();
    FindApprovalStatus();
  }, []);

  const filterSubmitDailyReport = () => {
    const temp = submitDailyReports.filter(
      (dr) => dr.submitDailyReport.submitterId == approvalTargetUserName
    );
    console.log(temp);

    return temp;
  };

  const viewComponent = () => {
    switch (step) {
      case STEP.ONE:
        return (
          <ChoiceUser
            users={users}
            submitDailyReports={submitDailyReports}
            choicedUserId={choicedUserId}
            setChoicedUserId={setChoicedUserId}
            setStep={setStep}
            setApprovalTargetUserName={setApprovalTargetUserName}
          />
        );
      case STEP.TWO:
        return (
          <Approve
            submitDailyReports={filterSubmitDailyReport()}
            setStep={setStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="pcDailyReportApproval">
      <Typography>日報承認</Typography>
      {viewComponent()}
    </div>
  );
}

export default DailyReportApproval;

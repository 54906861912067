import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { InputGroup } from '../../../../../../Util/dailyReportComponent/PC/inputGroup/monthly/monthlyTextFieldNum';
import { FixedTime } from '../../../../../../Util/dailyReportComponent/PC/inputGroup/fixed/fixedTime';

function RndFixedTime(props) {
  const [info, setInfo] = useState({
    componentId: props.component.componentId,
    x: props.component.x,
    y: props.component.y,
    width: props.component.width,
  });

  //Effect********************************************************************************************************************************************************

  useEffect(() => {
    props.setComponentInfos((componentInfo) => {
      return componentInfo.map((ele) =>
        ele.componentId === props.component.componentId
          ? {
              width: info.width,
              height: info.height,
              componentId: props.component.componentId,
              componentType: props.component.componentType,
              text: props.component.text,
              fontSize: props.component.fontSize,
              x: info.x,
              y: info.y,
              iconName: props.component.iconName,
              imagePhoto: props.component.imagePhoto,
              choices: props.component.choices,
              sequence: props.component.sequence,
              defaultValue: props.component.defaultValue,
              totalValue: props.component.totalValue,
              isShowLabel: props.component.isShowLabel,
              xCount: props.component.xCount,
              yCount: props.component.yCount,
              isMultiline: props.component.isMultiline,
            }
          : ele
      );
    });
  }, [info]);

  //*************************************************************************************************************************************************************

  const dragEnd = (x, y) => {
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    setInfo({
      componentId: info.componentId,
      x: Math.floor(x / (30 * props.scalingFactor)),
      y: Math.floor(y / (30 * props.scalingFactor)),
      width: info.width,
    });
  };

  const resizeEnd = (width, height) => {
    if (width <= 0) width = 100;
    setInfo({
      componentId: info.componentId,
      x: info.x,
      y: info.y,
      width: Math.floor(width / (30 * props.scalingFactor)),
    });
  };

  return (
    <Rnd
      id="parent"
      default={{
        x: info.x * (30 * props.scalingFactor),
        y: info.y * (30 * props.scalingFactor),
        width: info.width * (30 * props.scalingFactor),
      }}
      position={{
        x: info.x * (30 * props.scalingFactor),
        y: info.y * (30 * props.scalingFactor),
      }}
      size={{
        width: props.component.width * (30 * props.scalingFactor),
      }}
      onDragStop={(e, data) => {
        e.ctrlKey ? void 0 : dragEnd(data.lastX, data.lastY);
      }}
      onResizeStop={(e, d, ref) => {
        e.ctrlKey ? void 0 : resizeEnd(ref.offsetWidth, ref.offsetHeight);
      }}
      enableResizing={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onClick={(e) => {
        if (e.ctrlKey) {
          props.setIsOpenRndDialog(true);
          props.setEditComponentId(info.componentId);
          props.setEditComponent(props.component);
          props.setIsNew(false);
        }
      }}
    >
      <Box position="relative" style={{ width: '100%', height: '100%' }}>
        <FixedTime
          component={props.component}
          scalingFactor={props.scalingFactor}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{ cursor: 'move', backgroundColor: 'transparent' }}
        />
      </Box>
    </Rnd>
  );
}

export { RndFixedTime };

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Grid,
  Link,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemButton,
  Paper,
  Button,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Card,
  Chip,
} from '@mui/material';
import './departmentSettings.css';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilValue } from 'recoil';
import { loginInfo } from '../../../Util/recoil/atom';
import {
  FindDepartmentByDepartmentId,
  FindDepartmentsByCompanyId,
  FindUsersByCompanyId,
  UpdateAffilation,
  UpdateDepartment,
} from '../../../Util/web_api';
import CreateDepartmentDialog from './createDepartmentDialog';
import CreateChildDepartmentDialog from './createChildDepartmentDialog';
import ObjectEditDialog from '../../../Util/commonComponent/objectEditDialog';

function DepartmentSettings() {
  const [departments, setDepartments] = useState([]);
  const [errors, setErrors] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isOpenCreateDepartmentDialog, setIsOpenCreateDepartmentDialog] =
    useState(false);
  const [
    isOpenCreateChildDepartmentDialog,
    setIsOpenCreateChildDepartmentDialog,
  ] = useState(false);
  const [choicedDepartmentId, setChoicedDepartmentId] = useState('');
  const [choicedDepartment, setChoicedDepartment] = useState([]);
  const [editChoicedDepartment, setEditChoicedDepartment] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [affiliatedEmployees, setAffiliatedEmployees] = useState([]);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isApplyChange, setIsApplyChange] = useState(false);

  // recoil
  const _loginInfo = useRecoilValue(loginInfo);

  const FindDepartments = () => {
    FindDepartmentsByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setDepartments(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindDepartment = () => {
    FindDepartmentByDepartmentId(
      choicedDepartmentId,
      (res) => {
        setChoicedDepartment(res.data);
        setEditChoicedDepartment(res.data);
        setAffiliatedEmployees(res.data.affilations);
        setIsApplyChange(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const FindEmployees = () => {
    FindUsersByCompanyId(
      _loginInfo.companyId,
      (res) => {
        setEmployees(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  useEffect(() => {
    FindDepartments();
    FindEmployees();
  }, []);

  useEffect(() => {
    if (updateFlag) {
      FindDepartments();
      FindEmployees();
      setUpdateFlag(false);
    }
  }, [updateFlag]);

  useEffect(() => {
    if (choicedDepartmentId !== '') {
      FindDepartment();
    }
  }, [choicedDepartmentId]);

  const updateEditChoicedDepartment = (key, value) => {
    setEditChoicedDepartment((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const update = () => {
    const poster = {
      DepartmentId: choicedDepartment.departmentId,
      UpdateDepartmentId: editChoicedDepartment.departmentId,
      UpdateDepartmentName: editChoicedDepartment.departmentName,
      UpdateIsUse: editChoicedDepartment.isUse,
    };
    const departmentPoster = {
      DepartmentId: choicedDepartment.departmentId,
      UserName: affiliatedEmployees.map((emp) => emp.userName),
    };

    UpdateDepartment(
      poster,
      (res) => {
        UpdateAffilation(
          departmentPoster,
          (res) => {
            setUpdateFlag(true);
            FindDepartment();
            setIsApplyChange(false);
          },
          (e) => {
            console.warn(e);
          }
        );
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const displayName = (userName) => {
    const temp = employees.find((emp) => emp.userName === userName);
    if (temp === null || temp === undefined) {
      return '不明なユーザー';
    } else {
      return temp.lastName + ' ' + temp.firstName;
    }
  };

  return (
    <div className="pcDepartmentSettings">
      <CreateDepartmentDialog
        isOpenDialog={isOpenCreateDepartmentDialog}
        setIsOpenDialog={setIsOpenCreateDepartmentDialog}
        setUpdateFlag={setUpdateFlag}
      />
      <CreateChildDepartmentDialog
        isOpenDialog={isOpenCreateChildDepartmentDialog}
        setIsOpenDialog={setIsOpenCreateChildDepartmentDialog}
        setUpdateFlag={setUpdateFlag}
        hierarchy={choicedDepartment.hierarchy + 1}
        parentId={choicedDepartment.departmentId}
      />
      <ObjectEditDialog
        allObject={employees}
        object={affiliatedEmployees}
        setObject={setAffiliatedEmployees}
        isOpen={isOpenEditDialog}
        setIsOpen={setIsOpenEditDialog}
        setIsApplyChange={setIsApplyChange}
      />
      <Typography>部門設定</Typography>
      <div
        style={{
          width: '1000px',
          marginTop: '10px',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setIsOpenCreateDepartmentDialog(true)}
          >
            部門を作成する
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '600px' }}>
          <Paper
            sx={{
              width: '200px',
              borderRadius: '4px',
              marginLeft: '4px',
            }}
            elevation={3} // 影のレベルを指定
          >
            <ListSubheader
              sx={{
                backgroundColor: '#263238',
                color: '#eceff1',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                display: 'flex',
              }}
            >
              部門
            </ListSubheader>
            <List
              sx={{
                overflowY: 'auto',
                maxHeight: '532px', // 適切な高さを指定
              }}
            >
              {departments.map((department, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    selected={choicedDepartmentId === department.departmentId}
                    onClick={() =>
                      setChoicedDepartmentId(department.departmentId)
                    }
                  >
                    <ListItemText
                      primary={department.departmentName}
                      sx={{ marginLeft: department.hierarchy * 15 + 'px' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
          <Paper
            sx={{
              width: '200px',
              borderRadius: '4px',
              marginLeft: '20px',
              flexGrow: 1,
              padding: '20px',
            }}
            elevation={3} // 影のレベルを指定
          >
            {choicedDepartment.length !== 0 ? (
              <React.Fragment>
                <div style={{ display: 'flex' }}>
                  <Typography>{choicedDepartment.departmentName}</Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ marginRight: '10px' }}
                    onClick={() => {
                      setIsOpenCreateChildDepartmentDialog(true);
                    }}
                  >
                    子部門を作成する
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    部門を削除する
                  </Button>
                </div>
                <form
                  noValidate
                  autoComplete="off"
                  style={{ marginTop: '10px' }}
                >
                  <div>
                    <FormControlLabel
                      control={<Switch />}
                      label="使用する"
                      checked={editChoicedDepartment.isUse}
                      onChange={(e) =>
                        updateEditChoicedDepartment('isUse', e.target.checked)
                      }
                    />
                  </div>

                  <TextField
                    label="部門名"
                    variant="outlined"
                    margin="normal"
                    value={editChoicedDepartment.departmentName}
                    onChange={(e) => {
                      updateEditChoicedDepartment(
                        'departmentName',
                        e.target.value
                      );
                      setIsApplyChange(true);
                    }}
                    error={!!errors.departmentName}
                    helperText={errors.departmentName}
                    required
                    style={{ width: '215px' }}
                  />
                  <TextField
                    label="部門ID"
                    variant="outlined"
                    margin="normal"
                    value={editChoicedDepartment.departmentId}
                    onChange={(e) => {
                      updateEditChoicedDepartment(
                        'departmentId',
                        e.target.value
                      );
                      setIsApplyChange(true);
                    }}
                    error={!!errors.departmentId}
                    helperText={errors.departmentId}
                    required
                    style={{ width: '215px', marginLeft: '20px' }}
                  />
                </form>
                <Card
                  variant="outlined"
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                  }}
                >
                  <Typography>所属社員</Typography>
                  <div style={{ margin: '10px' }}>
                    {affiliatedEmployees.length !== 0 ? (
                      affiliatedEmployees.map((employee) => {
                        return (
                          <Chip
                            style={{ marginRight: '3px', marginBottom: '5px' }}
                            label={displayName(employee.userName)}
                          />
                        );
                      })
                    ) : (
                      <Typography style={{ marginLeft: '5px' }}>
                        社員が設定されていません。
                      </Typography>
                    )}
                    <div style={{ display: 'flex' }}>
                      <div style={{ flexGrow: 1 }} />
                      <Button
                        variant="outlined"
                        onClick={() => setIsOpenEditDialog(true)}
                      >
                        所属社員の設定
                      </Button>
                    </div>
                  </div>
                </Card>
                <div style={{ display: 'flex' }}>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    disabled={!isApplyChange}
                    onClick={update}
                  >
                    変更を適応する
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <Typography>部門ツリーから部門を選択してください</Typography>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default DepartmentSettings;

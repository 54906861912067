import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';

function TypographyControl(props) {
  return (
    <Typography
      fontSize={props.component.fontSize * props.scalingFactor + 'px'}
    >
      {props.component.text}
    </Typography>
  );
}

export { TypographyControl };

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

function Photo(props) {
  const [image, setImage] = useState('');
  const fileInputRef = useRef(null);

  // 画像を選択させます
  const handleChoiceImagePreview = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage('');
    }
  };

  const handleChoiceImageEdit = (e, id) => {
    const files = e.target.files;
    if (files.length > 0) {
      var file = files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        props.setValue(id, e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      props.setValue(id, '');
    }
  };

  if (props.isEdit) {
    return (
      <Card
        variant="outlined"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(e) =>
            handleChoiceImageEdit(e, props.component.componentId)
          }
        />
        {props.value !== '' && (
          <img src={props.value} width="100%" height="100%" />
        )}
        <Button
          startIcon={
            props.value === '' ? <AddAPhotoIcon /> : <CameraswitchIcon />
          }
          onClick={() => fileInputRef.current.click()}
          sx={{ position: 'absolute' }}
        >
          {props.component.text}
        </Button>
      </Card>
    );
  } else {
    return (
      <Card
        variant="outlined"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(e) => handleChoiceImagePreview(e)}
        />
        {image !== '' && <img src={image} width="100%" height="100%" />}
        <Button
          startIcon={image === '' ? <AddAPhotoIcon /> : <CameraswitchIcon />}
          onClick={() => fileInputRef.current.click()}
          sx={{ position: 'absolute' }}
        >
          {props.component.text}
        </Button>
      </Card>
    );
  }
}

export { Photo };

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

function CheckBox(props) {
  if (props.isEdit) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            value={props.value}
            onChange={(e) =>
              props.setValue(props.component.componentId, e.target.checked)
            }
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: props.component.fontSize * props.scalingFactor + 'px',
              },
            }}
          />
        }
        label={props.component.text}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 文字サイズを変更
          },
        }}
      />
    );
  } else {
    return (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={props.component.defaultValue}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: props.component.fontSize * props.scalingFactor + 'px',
              },
            }}
          />
        }
        label={props.component.text}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: props.component.fontSize * props.scalingFactor + 'px', // 文字サイズを変更
          },
        }}
      />
    );
  }
}

export { CheckBox };

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';

function MonthlyTextFieldNum(props) {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const date = dayjs();
    const year = date.year();
    const month = date.month();
    const lastDay = date.daysInMonth();
    const newDates = [];

    for (let day = 1; day <= lastDay; day++) {
      newDates.push(new Date(year, month, day));
    }

    setDates(newDates);
  }, []);

  if (props.isEdit) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {dates.length !== 0 &&
          dates.map((date) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ minWidth: '40px', maxWidth: '40px' }}>
                {date.getMonth() + 1 + '/' + date.getDate()}
              </Typography>
              <TextField
                type="number"
                size="small"
                disabled={date.getDate() !== props.date.getDate()}
                value={
                  date.getDate() === props.date.getDate() ? props.value : 0
                }
                onChange={(e) =>
                  props.setValue(
                    props.component.componentId,
                    Number(e.target.value)
                  )
                }
                style={{
                  backgroundColor:
                    date.getDate() !== props.date.getDate()
                      ? '#eeeeee'
                      : 'white',
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    width: '100%',
                    height: 30 * props.scalingFactor + 'px', // 変更したい高さ
                  },
                  '& .MuiInputBase-input': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
                  },
                }}
              />
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {dates.length !== 0 &&
          dates.map((date) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ minWidth: '40px', maxWidth: '40px' }}>
                {date.getMonth() + 1 + '/' + date.getDate()}
              </Typography>
              <TextField
                type="number"
                size="small"
                style={{ backgroundColor: 'white' }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 30 * props.scalingFactor + 'px', // 変更したい高さ
                  },
                  '& .MuiInputBase-input': {
                    fontSize:
                      props.component.fontSize * props.scalingFactor + 'px', // 変更したいフォントサイズ
                  },
                }}
              />
            </div>
          ))}
      </div>
    );
  }
}

export { MonthlyTextFieldNum };

import { Rnd } from 'react-rnd';
import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja';
import { TimeField } from '@mui/x-date-pickers';

function FixedTime(props) {
  console.log(props.value);
  const viewEditComponent = (x, y) => {
    const items = [];
    for (let i = 0; i < y; i++) {
      items.push(
        <div key={'y' + i} style={{ display: 'flex' }}>
          {Array.from({ length: x }, (_, j) => (
            <TimeField
              format="HH:mm"
              style={{
                backgroundColor: 'white',
                flex: 1,
              }}
              value={props.value[i][j]}
              onChange={(newTime) => {
                const newArray = [...props.value]; // 配列全体をコピー
                newArray[i][j] = newTime; // 指定個所を更新
                props.setValue(props.component.componentId, newArray);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: 30 * props.scalingFactor + 'px',
                },
                '& .MuiInputBase-input': {
                  fontSize: 16 * props.scalingFactor + 'px', // 変更したいフォントサイズ
                },
              }}
            />
          ))}
        </div>
      );
    }

    return items;
  };
  const viewComponent = (x, y) => {
    const items = [];
    for (let i = 0; i < y; i++) {
      items.push(
        <div key={'y' + i} style={{ display: 'flex' }}>
          {Array.from({ length: x }, (_, j) => (
            <TimeField
              format="HH:mm"
              style={{
                backgroundColor: 'white',
                flex: 1,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: 30 * props.scalingFactor + 'px',
                },
                '& .MuiInputBase-input': {
                  fontSize: 16 * props.scalingFactor + 'px', // 変更したいフォントサイズ
                },
              }}
            />
          ))}
        </div>
      );
    }

    return items;
  };

  if (props.isEdit) {
    return (
      <div style={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
          {viewEditComponent(props.component.xCount, props.component.yCount)}
        </LocalizationProvider>
      </div>
    );
  } else {
    return (
      <div style={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
          {viewComponent(props.component.xCount, props.component.yCount)}
        </LocalizationProvider>
      </div>
    );
  }
}

export { FixedTime };

import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';

function UserSelector(props) {
  const { users, choicedUserId, setChoicedUserId, style, setIsApplyChange } =
    props;

  const showUser = () => {
    if (users === null || users === undefined) return;
    if (users.length !== 0) {
      return users.map((user, i) => (
        <MenuItem key={i} value={user.userName}>
          {user.lastName + ' ' + user.firstName}
        </MenuItem>
      ));
    } else {
      return <ListSubheader>ユーザーが存在しません</ListSubheader>;
    }
  };

  return (
    <FormControl variant="outlined" sx={style}>
      <InputLabel id="demo-simple-select-standard-label" shrink>
        ユーザー
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={choicedUserId}
        onChange={(e) => {
          setChoicedUserId(e.target.value);
          setIsApplyChange && setIsApplyChange(true);
        }}
        label="ユーザー"
        variant="outlined"
        displayEmpty
      >
        <MenuItem value={''}>
          <em>指定なし</em>
        </MenuItem>
        {showUser()}
      </Select>
    </FormControl>
  );
}

export default UserSelector;
